import React ,{ useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";

//styles & images
import styles from '../AskQuestion/Ask.module.scss';
import illu from '../../assets/images/ask1.svg';
import starImg from '../../assets/images/star.svg';
import starImgActive from '../../assets/images/starActive.svg';

//components
import Button from '../../components/lib/Button/Button';
import Textarea from '../../components/lib/form/Textarea/Textarea';
import { postSatisfactionAction } from '../../actions/answerActions';
import { useGlobalContext } from '../../context/GlobalContext';

//Global state
// import { useGlobalContext } from '../../context/GlobalContext';

export default function SatisfactionPage() {
  const id = useParams()?.id;
  const dispatch = useGlobalContext()[1];
  const { register, errors, getValues } = useForm();
  const [ form , setForm ] = useState({
    message: '',
    rate: 0,
  });

  function handleChange(key , value) {
    setForm({...form , [key]: value} , id) ;
  };

  const stars = ['','','','',''];

  function askQuestion() {
    const message = getValues('message');
    postSatisfactionAction( dispatch,{...form, message , id });
  };

  return (
    <div className={`page-container ${styles.container}`}>
      <div className="content">
        <div className={styles.row}>
          <div className={styles.col}>
            <h2>Votre avis nous intéresse</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam accumsan felis eu lectus commodo, in tincidunt diam commodo
            </p>
            
            <p className={styles.label}>
              Votre note
            </p>
            <div className={styles.stars}>
              {stars.map((s, i) => <button
                key={i}
                onClick={() => handleChange('rate', i + 1)}
                >
                <img src={i + 1 <= form.rate ? starImgActive : starImg } alt="star" />
              </button>)}
            </div>

            <Textarea
              label={"Votre message"}
              name={"message"}
              defaultValue={''}
              register={register}
              withErrors={true}
              error={errors.question && errors.question.message}
            />
            
            <Button
              primary
              onClick={() => askQuestion()}
              >
              Donner mon avis
            </Button>

          </div>
          <div className={`${styles.col} ${styles.illu}`}>
            <img src={illu} alt="illu" />
          </div>
        </div>

        <footer>
          {/* <Link to={'/ask'}>En savoir plus sur la confidentialité et le traitement de mes données</Link> */}
        </footer>

      </div>
    </div>
  )
}
