import React, { useEffect , useCallback , useState } from 'react';

import { useGlobalContext } from '../../../../context/GlobalContext';
import { getAnswersTypeListAction } from '../../../../actions/answerActions';

import styles from './AnswerTypeModale.module.scss';
import Input from '../../../../components/lib/form/Input/Input';

export default function AnswerTypeModale({ submitAnwserType , closeModale }) {

  const [ context , dispatch ] = useGlobalContext();
  const [ filteredList , setFilteredList ] = useState();

  const initData = useCallback(() => {
    getAnswersTypeListAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    if (context.answers.answersType) {
      setFilteredList(context.answers.answersType)
    }
  }, [context.answers?.answersType]);

  function selectAnswerType(answer) {
    let data = {}
    data.answer = answer.answer;
    data.files = answer.files;
    submitAnwserType(data);
    closeModale();
  }

  function handleSearch(val) {
    let list = context.answers.answersType;
    if (val) {
      list = list.filter(item => item?.titleAnswerType?.toLowerCase().includes(val.toLowerCase()));
    }
    setFilteredList(list);
  }

  return (
    <div className={styles.container}>
      <h2>AJOUTER UNE REPONSE TYPE</h2>
      {context.answers.answersType &&
        <div className={styles.input}>
          <Input
            onChange={(val) => handleSearch(val)}
            placehloder="rechercher une question"
            />
        </div>
      }
      <div className={styles['answers-list']}>
        {filteredList?.filter((a) => {
          if (new Date() < new Date(a.expirationDateAnswerType)) {
            return a;
          }
          return null;
        })?.map((item) => 
          <button
            key={item._id}
            onClick={() => selectAnswerType(item)}
            className={styles.answer} >
            {item?.titleAnswerType && <p>{item?.titleAnswerType}</p>}
          </button>  
        )}
      </div>
    </div>
  )
}
