import React , { useRef , useState , useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IoCloseCircleSharp } from 'react-icons/io5'
import { RiCheckboxBlankCircleFill } from 'react-icons/ri'
import { MdWarning } from 'react-icons/md'

import { useGlobalContext } from '../../../context/GlobalContext'
import { putNotifAction , deleteNotifAction } from '../../../actions/authActions'
import { sortByDateKey } from '../../../utils/utils';

import styles from './Notifs.module.scss'

export default function Notifs({ isOpen , notifs }) {

  const [ notifsData , setNotifsData ] = useState(notifs);

  useEffect(() => {
    setNotifsData(notifs);
  }, [notifs])

  const dispatch = useGlobalContext()[1]
  const notifsRef = useRef();

  function deleteNotif(id) {
    deleteNotifAction(dispatch , id );
    setNotifsData(list => list.filter(n => n._id !== id ))
  }

  return (
    <div
      className={styles.container}
      style={{ height: isOpen && notifsRef.current ? notifsRef.current.clientHeight : 0 }}
      >
      <div
        ref={notifsRef}
        className={styles.list}
        >
        {notifsData?.length > 0 && sortByDateKey(notifsData , "updatedAt").map((n, i) => 
          <div
            key={'notif' + i }
            className={styles.notif}
            >
            {n.type === "question" &&
              <>
                <div className={styles.info}>
                  <p>{n.description}</p>
                  {n.new && 
                    <button>
                      <RiCheckboxBlankCircleFill size={18} color={'#FFA56D'}/>
                    </button>
                  }
                  {!n.new &&
                    <button
                      onClick={() => deleteNotif(n._id )}
                      >
                      <IoCloseCircleSharp size={18} color={'#b5b5b5'}/>
                    </button>
                  }
                </div>
                <Link
                  to={`/question/${n.objId}`}
                  onClick={() => putNotifAction(dispatch , n._id , { new : false })}
                  className="bold">
                  {n.title}
                </Link>
                {n?.delay >= 7 && <p className="text-error"><MdWarning size={18} /> {n.delay} jours </p>}
                {n?.delay < 7 && <p className="text-success">{n.delay} jours </p>}
              </>
            }
          </div>  
        )}
        {!notifsData && <p>Aucune notification</p>}
        {notifsData?.length === 0 && <p>Aucune notification</p>}
      </div>
    </div>
  )
}
