import React , { useCallback , useEffect, useRef, useState } from 'react';

//Style
import styles from './ValidationsPage.module.scss';

//golbal context
import { GET_ANSWER, GET_QUESTION, SET_ANSWERS_FILTERS, SET_ANSWERS_SCROLL_Y } from '../../actions/types';
import { useGlobalContext } from '../../context/GlobalContext';
import { postAnswersFilteredAction, putAnswerAction } from '../../actions/answerActions';
import { getNotifsAction } from '../../actions/authActions';
import ValidRow from '../../components/partials/ValidRow/ValidRow';

export default function ValidationsPage() {

  const [ context, dispatch ] = useGlobalContext();
  const { filteredAnswers, filters, scrollY, count } = context.answers;

  const adminId = context.auth.userAuth?._id;

  const containerRef = useRef(null);
  const scrollRef = useRef(null);
  const [ isLoading, setIsLoading ] = useState(true);

  const initData = useCallback(() => {
    getNotifsAction(dispatch , "Medocta" );
    if (scrollY) {
      containerRef.current.scrollTo({
        top: scrollY,
        left: 0,
      });
    }
    dispatch({
      type: GET_QUESTION,
      payload: null
    });
    dispatch({
      type:GET_ANSWER,
      payload: null
    });
  }, [dispatch , scrollY]);

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    postAnswersFilteredAction(dispatch, filters );
    setIsLoading(true);
    // eslint-disable-next-line
  },[filters.orderBy , filters ]);

  useEffect(() => {
    setIsLoading(false);
  }, [filteredAnswers]);

  function handleScroll(e) {
    dispatch({
      type: SET_ANSWERS_SCROLL_Y,
      payload: e.target.scrollTop
    });
    if (!isLoading && count > filteredAnswers.length && scrollRef?.current.offsetHeight + e.target.scrollTop + 2 > scrollRef?.current.scrollHeight ) {
      dispatch({
        type: SET_ANSWERS_FILTERS,
        payload: {
          ...filters,
          page: filters.page + 1,
        }
      });
    }
  }

  function handleChangeAnswer( id , key , value ) {
    putAnswerAction(dispatch , id , {[key]: value} )
  }

  return (
    <div className={`page-container ${styles.container}`}>
      <div
        ref={containerRef}
        onScroll={(e) => handleScroll(e)}
        className={styles.list}>
        <div className="content" ref={scrollRef}>
          {filteredAnswers?.map((answer) =>
            <ValidRow
              key={answer._id}
              adminId={adminId}
              handleChangeAnswer={handleChangeAnswer}
              answer={answer}/>
          )}
        </div>
      </div>
    </div>
  )
}
