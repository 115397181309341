import React , { useEffect , useState , useCallback } from 'react';
import { useForm  , Controller } from "react-hook-form";
import { AiOutlineFileDone  , AiFillDelete } from 'react-icons/ai';
import { API_URL } from '../../../../actions/index';

import { useGlobalContext } from '../../../../context/GlobalContext';

import { postFileAction , deleteFileAction } from '../../../../actions/answerActions';

import Input from '../../../../components/lib/form/Input/Input';
import Button from '../../../../components/lib/Button/Button';
import InputFile from '../../../../components/lib/form/InputFile/InputFile';

import styles from './DocumentModale.module.scss';

export default function DocumentModale({ files , setModaleIsActive , HandleChangeAnswer  , HandleChangeQuestion }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { handleSubmit, register, errors , setValue , control , watch } = useForm();
  const title = watch('title')

  const { file , error } = context.answers;

  const [ uploadedFile , setUploadedFile ] = useState();
  const [ fileError , setFileError ] = useState();
  const [ fileId , setFileId ] = useState();

  const clearFile = useCallback(() => {
    dispatch({
      type : 'POST_FILE',
      payload : null,
    });  
  },[dispatch])

  useEffect(() => {
    clearFile();
    return () => {
      clearFile();
    }
    // eslint-disable-next-line 
  },[]);


  useEffect(() => {
    register('file');
  }, [register]);

  useEffect(() => {
    if (errors.file && errors.file.message) {
      setFileError(errors.file.message);
    }
  },[errors.file]);

  useEffect(()=> {
    if (file && file._id) {
      setValue('file' , file._id );
      setFileId(file._id);
      if (uploadedFile) document.getElementById('file').value = "";
      setUploadedFile();
      setFileError();
    }
    // eslint-disable-next-line
  },[file]);

  useEffect(()=> {
    if (error && error.data && error.data.error ) {
      if (error.data.error === "Extension not allowed.") {
        setFileError("Ce type de fichier n'est pas autorisé");
        setUploadedFile();
        setFileId();
        dispatch({
          type : 'ERROR_STUDIES',
          payload : null,
        });    
      }
    }
    // eslint-disable-next-line
  },[error]);

  function handleChangeFile(fileData ) {

    dispatch({
      type : 'ERROR_STUDIES',
      payload : null,
    });

    setUploadedFile(fileData);
    setFileError();
    setFileId();

    const formData = new FormData();
    formData.append("file", fileData );
    formData.append("title", title || fileData.name );
    formData.append("description", `document Medocta ${fileData.name}`);
    formData.append("alt", `${fileData.name.split(" ").join("-")}-file`);
    postFileAction(dispatch , formData )

  }


  function deleteFile(id) {
    deleteFileAction(dispatch , id );
    setFileId();
  }

  function closeModale() {
    setModaleIsActive();
    if (fileId) deleteFile(fileId);
  }

  function submitForm() {
    let updatedList = [...files];
    updatedList.push(file._id);
    if (HandleChangeAnswer) {
      HandleChangeAnswer( 'files' , updatedList);
    }
    if (HandleChangeQuestion) {
      HandleChangeQuestion('proof', updatedList)
    }   
    setModaleIsActive();
  }

  return (
    <div className={`modale-content ${styles.container}`}>
      <h2>Ajouter un document</h2>
      <form onSubmit={handleSubmit(submitForm)}>

        <Input
          label={"Nom du document"}
          placehloder={"title"}
          name={"title"}
          register={register({
            required: "Nom du document",
          })}
          withErrors={true}
          error={errors.title && errors.title.message}
        />

        <label>Fichier</label>
        {file && file._id  &&
          <div className={styles.file}>
            <a href={`${API_URL}/${file.path}`} rel="noreferrer" target="_blank">
              <div className={styles.link}>
                <AiOutlineFileDone size={20} color={'#FFFFFF'} />
                <p>{file.name}</p>
              </div>
            </a>
            <button
            type="button"
            onClick={() => deleteFile(fileId)}
            className={`${styles.delete}`}>
            <AiFillDelete size={20} color={'red'} />
            <p>supprimer</p>
          </button>
          </div>
        }
        
        {!fileId &&
          <Controller
            name="file"
            rules={{ required: "Le ficher est requis" }}
            control={control}
            defaultValue={null}
            render={(props) =>
              <InputFile
                name="file"
                isLoading={uploadedFile}
                withErrors={true}
                defaultValue={null}
                onChange={(files) => handleChangeFile(files[0])}
                error={fileError}
                />}
          />
        }
  

        <div className={styles['btn-container']}>
          <Button
            type={"submit"}
            primary
            >
            Valider
          </Button>
          <Button
            type={"button"}
            primary
            outline
            onClick={() => closeModale()}
            >
            Annuler
          </Button>
        </div>
      </form>
    </div>
  )
}
