import { postData , getData , putData  , deleteData, OCTA_ID } from './index';

import {
  ERROR_QUESTION,
  POST_QUESTION,
  GET_QUESTION,
  GET_QUESTION_LISTS,
  PUT_QUESTION,
  GET_ANSWER,
  DELETE_QUESTION,
  GET_DASHBOARD,
  GET_DRUGS,
  FILTERED_QUESTIONS,
  GET_CONTACTS,
  GET_CENTERS,
  POST_CONTACT,
  PUT_CONTACT,
  SET_TOAST,
} from "./types"

export async function getDashboardAction(dispatch) {
  let url = "/question/dashboard";
  var dashboard;

  await getData(ERROR_QUESTION, url, dispatch , true ).then((response)=>{
    dashboard = response.dashboard
  });

  dispatch({
    type : GET_DASHBOARD,
    payload : dashboard
  });

};

export async function getQuestionListsAction(dispatch) {
  let url = "/question/list";
  var lists;

  await getData(ERROR_QUESTION, url, dispatch , true ).then((response)=>{
    lists = response.lists
  });

  dispatch({
    type : GET_QUESTION_LISTS,
    payload : lists
  });

};

export async function getContactListAction(dispatch) {
  let url = "/contact";
  var contacts;

  await getData(ERROR_QUESTION, url, dispatch , true ).then((response)=>{
    contacts = response.contacts?.map(author => ({ label: `${author.lastName && author.firstName ? `${author.lastName} ${author.firstName}`: author.name}`, value: author._id }));
  });

  contacts.unshift({ value: 'new' , label: 'Nouveau contact'});

  dispatch({
    type : GET_CONTACTS,
    payload : contacts
  });

};

export async function postContactAction(dispatch , obj) {
  let url = "/contact";
  var contact;

  await postData(POST_CONTACT , ERROR_QUESTION, url, dispatch , obj, true  ).then((response)=>{
    contact = response.contact;
  });

  dispatch({
    type : POST_CONTACT,
    payload : contact
  });

};

export async function putContactAction(dispatch , id , data ) {
  let url = `/contact/${id}`;
  var contact ;

  await putData(PUT_CONTACT,  ERROR_QUESTION ,  url , dispatch, data , true ).then((response)=>{
    contact = response.contact;
  });

  dispatch({
    type : PUT_CONTACT,
    payload : contact
  });

  dispatch({
    type :  SET_TOAST,
    payload : {
      type: "success",
      message: "Le contact a été modifié"
    }
  });

};

export async function getCentersListAction(dispatch) {
  let url = "/center";
  var centers = [];

  await getData(ERROR_QUESTION, url, dispatch , true ).then((response)=>{
    response.centers.forEach((c) => {  
      centers.push({
        value: {
          center: c._id,
          services: c.services
        },
        label: `${c.name} - ${c?.location?.city}`,
      });
    });
  });

  dispatch({
    type : GET_CENTERS,
    payload : centers
  });

};

// export async function getQuestionsListAction(dispatch) {
//   let url = "/question";
//   var questions;

//   await getData(ERROR_QUESTION, url, dispatch , true ).then((response)=>{
//     questions = response.questions
//   });

//   dispatch({
//     type : GET_QUESTIONS,
//     payload : questions
//   });

// };

export async function postQuestionsFilteredAction(dispatch , obj ) {
  let url = "/question/filtered";
  var questions;

  await postData(FILTERED_QUESTIONS , ERROR_QUESTION, url, dispatch , obj, true  ).then((response)=>{
    questions = response
  });

  dispatch({
    type : FILTERED_QUESTIONS,
    payload : {...questions, page: obj.page }
  });

};

export async function getDrugsListAction(dispatch) {
  let url = `/drug?companyId=${OCTA_ID}`;
  var drugs;

  await getData(ERROR_QUESTION, url, dispatch , true ).then((response)=>{
    drugs = response.drugs
  });

  dispatch({
    type : GET_DRUGS,
    payload : drugs
  });

};


export async function getQuestionAction(dispatch , id ) {
  let url = `/question/${id}`;
  var questionData;

  await getData(ERROR_QUESTION, url, dispatch , true ).then((response)=>{
    questionData = response
  });

  dispatch({
    type : GET_QUESTION,
    payload : questionData.question
  });

  if (questionData.answer) {
    dispatch({
      type : GET_ANSWER,
      payload : questionData.answer
    });
  }

};

export async function postQuestionAction(dispatch , obj){
  let url = "/question";
  var postQuestion;

  await postData(POST_QUESTION , ERROR_QUESTION, url, dispatch , obj, true ).then((response)=>{
    postQuestion = response
  });
  
  dispatch({
    type : POST_QUESTION ,
    payload : postQuestion
  });
  
};

export async function putQuestionAction(dispatch , id , data ) {
  let url = `/question/${id}`;
  var putQuestion ;

  await putData(PUT_QUESTION,  ERROR_QUESTION ,  url , dispatch, data , true ).then((response)=>{
    putQuestion = response.question
  });

  dispatch({
    type : PUT_QUESTION,
    payload : putQuestion
  });

  dispatch({
    type :  'SET_TOAST',
    payload : {
      type: "success",
      message: "La question a été modifiée"
    }
  });

};

export async function deleteQuestionAction(dispatch , id ) {
  let url = `/question/${id}`;
  var deleteQuestion ;

  await deleteData(ERROR_QUESTION ,  url, dispatch, true ).then((response)=>{
    deleteQuestion = response
  });

  if(deleteQuestion) {
    dispatch({
      type : DELETE_QUESTION,
      payload : id
    });
  }

  dispatch({
    type :  'SET_TOAST',
    payload : {
      type: "success",
      message: "La question a été supprimée"
    }
  });

};
