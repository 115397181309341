import React , { useEffect , useCallback , useState } from 'react';
import { useParams  , Link  , useHistory } from 'react-router-dom';
import { RiArrowLeftSLine } from "react-icons/ri";
import { MdDelete } from 'react-icons/md';
import { useForm } from "react-hook-form";

//UTILS
//styles
import styles from './EditAnswserType.module.scss';

//global state
import { useGlobalContext } from '../../../context/GlobalContext';

//components
import Button from '../../../components/lib/Button/Button';
import Modale from '../../../components/lib/Modale/Modale';
import DocumentModale from '../../Questions/QuestionPage/DocumentModale/DocumentModale';
import HtmlEditor from '../../../components/lib/form/HtmlEditor/HtmlEditor';
import DatePicker from '../../../components/lib/DatePicker/DatePicker';
import { deleteAnswerAction, deleteFileAction, getAnswerAction, getFileAction, postAnswerAction, putAnswerAction } from '../../../actions/answerActions';
import { GET_ANSWER } from '../../../actions/types';
import { TOOL_ID } from '../../../actions';
import Input from '../../../components/lib/form/Input/Input';

export default function EditAnswserType() {

  // const filesSentence = "<p>Ces publications sont strictement réservées à leur destinataire et sont protégées par les lois en vigueur sur le copyright. Toute reproduction et diffusion (papier ou e-mail) sont rigoureusement interdites.</p>"

  const history = useHistory();
  const { answerId } = useParams();
  const [ context, dispatch ] = useGlobalContext();

  const { handleSubmit  } = useForm();

  const { answer } = context.answers;
  const { userAuth } = context.auth;
  const role = userAuth?.tools.find((t) => t.tool === TOOL_ID ).role

  const [ isReadOnly , setIsReadOnly ] = useState(false);
  const [ modaleIsOpen , setModaleIsOpen ] = useState(false);
  const [ displayTooltip , setDisplayTooltip ] = useState(false);
  const [ answerHtml , setAnswerHtml ] = useState();
  const [ answerTitle , setAnswerTitle] = useState("");
  const [ answerDefault , setAnswerDefault ] = useState();

  const initData = useCallback(() => {
    if (answerId) {
      getAnswerAction(dispatch , answerId);
    }
    dispatch({
      type:GET_ANSWER,
      payload: null
    });
  }, [dispatch , answerId]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    if (role === 'Admin') {
      setIsReadOnly(false);
    }
  },[role])

  useEffect(() => {
    if (answer?.answer) {
      setAnswerDefault(answer?.answer);
      setAnswerHtml(answer?.answer);
    }
    if (answer?.titleAnswerType) {
      setAnswerTitle(answer?.titleAnswerType);
    }
    // eslint-disable-next-line
  },[answer?.answer, answer?.titleAnswerType]);

  // useEffect(() => {
  //   let text = answerHtml || answer?.answer || ""
  //   if (answer?.files.length > 0 && !text.includes(filesSentence) ) text += filesSentence
  //   if (answer?.files.length === 0 && text.includes(filesSentence) ) text.replace(filesSentence , '')
  //   setAnswerHtml(text)
  //   setAnswerDefault(text)
  //   // eslint-disable-next-line
  // },[answer?.files])

  function submitAnwser() {
    if (isReadOnly) return;
    if (answer?._id) {
      putAnswerAction(dispatch , answer._id , { answer: answerHtml, titleAnswerType: answerTitle })
    } else {
      const answerData = { 
        answer: answerHtml,
        isAnswerType: true,
        titleAnswerType: answerTitle,
      };
      postAnswerAction(dispatch , answerData );
    }
  }

  function HandleChangeAnswer(key , value ) {
    if (isReadOnly) return;
    if (answer?._id) {
      const data = {[key]: value, titleAnswerType: answerTitle }
      if (!answer?.date) {
        data.date = new Date();
      }
      if (key === 'expirationDateAnswerType') {
        data.expirationDateAnswerType = new Date(value);
      }
      if (answerTitle) {

      }
      putAnswerAction(dispatch , answer._id , data );
    } else {
      const answerData = { 
        [key]: value,
        isAnswerType: true,
        date: new Date(),
        titleAnswerType: answerTitle,
      };
      if (key === 'expirationDateAnswerType') {
        answerData.expirationDateAnswerType= new Date(value);
      }
      postAnswerAction(dispatch , answerData );
    }
  }

  function handleChangeMode(value) {
    if (isReadOnly) return;
    let arr = answer ? answer?.mode : [];
    if(arr.find(m => m === value)) {
      arr = arr.filter(m => m !== value);
    } else {
      arr.push(value);
    };
    HandleChangeAnswer("mode" , arr )
  }

  function deleteFile(fileId) {
    if (isReadOnly) return;
    HandleChangeAnswer( 'files' , answer.files.filter(f => f !== fileId));
    deleteFileAction(fileId);
  }

  function sendValidation() {
    let validation = {
      message: '',
      bool: true
    };
    if (!answer?.answer) {
      validation.message = 'Compléter la réponse';
    } else if (answer?.mode.length === 0) {
      validation.message = 'Compléter le mode de la question';
    } else {
      validation.bool = false
    }
    return validation;
  }

  function deleteAnswer() {
    if (isReadOnly) return;
    deleteAnswerAction(dispatch , answer._id );
    history.push('/answers-types');
  }

  return (
    <>
      <div className={`page-container ${styles.container}`}>
        <div className={`content ${styles.content}`}>
          <aside>
            <Link to={'/answers-types'} className={styles.back}>
              <RiArrowLeftSLine size={26} />
              <p>RETOUR À LA LISTE DES RÉPONSES</p>
            </Link>
            <div className={styles['date']}>
              <DatePicker
                label={"Date de validité"}
                id={`data-picker-answser-type`}
                date={answer?.expirationDateAnswerType ? new Date(answer.expirationDateAnswerType) : ''}
                placeholder="Date de validité de la réponse type"
                handleChange={(val) => HandleChangeAnswer("expirationDateAnswerType", val)}
                />
            </div>
            {!isReadOnly &&
            <button
              className={styles.delete}
              onClick={() => deleteAnswer()}
              >
              <MdDelete size={20}/> <p>supprimer la réponse type</p>
            </button>
          }
          </aside>

          <main className={`box ${isReadOnly ? "disable-pointer" : ""}`}>
            <div className={styles['btn-oral-container']}>
              <button
                className={answer?.mode.find(m => m === "written") ? styles['is-active'] :""}
                onClick={() => handleChangeMode("written")}
               >
                Ecrit
              </button>
              <button
                className={answer?.mode.find(m => m === "oral") ? styles['is-active'] :""}
                onClick={() => handleChangeMode("oral")}
                >Oral
              </button>
            </div>

            <form onSubmit={handleSubmit(submitAnwser)} className={styles.answer} >
              {!isReadOnly ?
                <>
                  <Input
                    label={"Titre de la réponse type"}
                    name={"title"}
                    value={answerTitle}
                    onChange={(val) => setAnswerTitle(val)}
                    withErrors={true}
                  />
                  <HtmlEditor
                    defaultValue={answerDefault}
                    onChange={(val) => setAnswerHtml(val)}
                  />
                  <div className={styles.files}>
                    {answer?.files && answer.files.map((f ,i ) => (
                      <div key={'file' + i} className={styles.file}>
                        <button
                          className={styles.link}
                          type={"button"}
                          onClick={() => getFileAction(dispatch , f._id )}
                          >
                          {f.name}
                        </button>
                        <button
                          className={styles.delete}
                          type={"button"}
                          onClick={() => deleteFile(f)}
                          >
                          <MdDelete size={20} />
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className={styles['btn-answer-container']}>
                    <div className={styles['left']}>
                      <Button
                        primary
                        onClick={() => setModaleIsOpen("document")}
                        type="button"
                        >
                        Ajouter un fichier
                      </Button>
                    </div>

                    <div
                      onMouseOver={() => setDisplayTooltip(true)}
                      onMouseOut={() => setDisplayTooltip(false)}
                      className={styles['right']}>
                      <p className={`${styles['tooltip']} ${displayTooltip ? styles['is-active']:''}`}>
                        {sendValidation().message}
                      </p>
                      <Button
                        primary
                        type="submit"
                        >
                        Enregistrer
                      </Button>
                    </div>
                  </div>
                </>
                :
                <>
                  {answer?.answer &&
                    <div dangerouslySetInnerHTML={{__html: answer?.answer}}></div>
                  }
                </>
              }
            </form>
            
          </main>
        </div>
      </div>
      <Modale
        isOpen={modaleIsOpen}
        hideClose={modaleIsOpen === "submit" ? true : false }
        modaleToggle={() => setModaleIsOpen()}
        >
        {modaleIsOpen === "document" && <DocumentModale files={answer?.files ? answer.files : []} HandleChangeAnswer={HandleChangeAnswer} setModaleIsActive={() => setModaleIsOpen()} />}
      </Modale>
    </>
  );
}
