import React , { useState , useRef } from 'react';
import { Link } from 'react-router-dom';
import { BiMinus , BiPlus } from 'react-icons/bi';
import { MdWarning } from 'react-icons/md';
import { format } from 'date-fns';

import { status } from '../../../utils/utils';

export default function QuestionRow({ question , styles , isAssign , isMember }) {

  const [ questionIsOpen , setQuestionIsOpen ] = useState(false);
  const contentRef = useRef(null);
  return (
    <div className="box">
      <div className={`${styles.row}`}>
        <div className={`${styles.col}`}>
          {question?.code && <p>{question?.code}</p> }
        </div>
        <div className={`${styles.col} ${styles.title}`}>
          {!isMember ?
            <Link to={isAssign ? `/question-assign/${question._id}` : `/question/${question._id}`} className="bold">
              <p>{question.title || question.code}</p>
            </Link>
            :
            <p className="bold">{question.title}</p>
          }
        </div>
        <div className={`${styles.col} ${styles.author}`}>
          {question?.author?.lastName && <p>{question?.author?.firstName && question?.author?.firstName[0]?.toUpperCase()}{question?.author?.firstName && '.'}{question?.author?.lastName?.toUpperCase()}</p>}
          {question?.author?.name && <p>{question?.author?.name?.toUpperCase()}</p>}
        </div>
        <div className={`${styles.col} ${styles.date}`}>
          <p>{question.date && format(new Date(question.date), 'dd/MM/yy')}</p>
        </div>
        <div className={`${styles.col} ${styles.date}`}>
          {question?.answer?.date && <p>{format(new Date(question?.answer.date), 'dd/MM/yy')}</p>}
        </div>
        <div className={`${styles.col}`}>
          {!isMember && question?.assignTo?._id ? <p className="text-success">{question?.assignTo.profile.lastName.charAt(0).toUpperCase()}.{question?.assignTo.profile.firstName.charAt(0).toUpperCase()}</p> : <p className="text-error">NON</p> }
        </div>
        <div className={`${styles.col}`}>
          <p style={{ color: status[question.status].color }}>{status[question.status].label}</p>
        </div>
        <div className={`${styles.col} ${styles.delay}`}>
          {question?.delay >= 7 && <p className="text-error">{question.delay} jours <MdWarning size={18} /></p>}
          {question?.delay < 7 && <p className="text-success">{question.delay} jours </p>}
        </div>
        <div className={`${styles.toggle}`}>
          {!isMember &&  
          <button
            onClick={() => setQuestionIsOpen(!questionIsOpen)}
            >
            {questionIsOpen ? <BiMinus size={32} /> :<BiPlus size={32} /> }
          </button>
          }
        </div>
      </div>
      <div className={styles.panel} style={{ height: questionIsOpen && contentRef.current ? contentRef.current.clientHeight : 0 }}>
        <div ref={contentRef} className={styles.question}>

          <div className={styles.col}>
            <div className={styles.text}>
              <p><span className="bold">Question </span>
                {question?.author?.lastName && <span>{question?.author?.firstName} {question?.author?.lastName?.toUpperCase()}</span>}
                {question?.author?.name && <span>{question?.author?.name?.toUpperCase()}</span>}
              </p>
              <p>{question?.question}</p>
            </div>
          </div>

          <div className={styles.col}>
            <div className={styles.text}>
              {question.assignTo && 
                <p>
                  <span className="bold">Réponse de </span>
                  {question.answer?.author?._id !== question.assignTo._id ?
                  <span>{question.answer?.author?.profile?.firstName} {question.answer?.author?.profile?.lastName} <br/>(assignée à {question.assignTo?.profile?.firstName} {question.assignTo?.profile?.lastName})</span>
                  :
                  <span>{question.assignTo?.profile?.firstName} {question.assignTo?.profile?.lastName}</span>
                  }
                  {question.answer?.date && <span><br/>le {format(new Date(question.answer.date), 'dd/MM/yyyy')}</span>}
                </p>
              }
              {question?.answer?.answer &&
                <div dangerouslySetInnerHTML={{__html: question?.answer?.answer}}></div>
              }
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
