import React , { useEffect , useState , useCallback } from 'react';
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";

import { FaEye , FaEyeSlash } from "react-icons/fa";
import { useGlobalContext } from '../../context/GlobalContext';
import { loginAction , forgotPasswordAction , getUserAuthAction } from '../../actions/authActions';
import { TOOL_ID } from '../../actions';

import styles from './Auth.module.scss';

export default function Login() {

  const { handleSubmit, register, errors } = useForm();

  const history = useHistory();

  const [ context, dispatch ] = useGlobalContext();
  const { userAuth , error , isAuth } = context.auth;

  const [ displayLogin , setDisplayLogin ] = useState(true);
  const [ errorForgot , setErrorForgot ] = useState();
  const [ showPassword , setShowPassword ] = useState(false);

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    if (error?.status === 422) {
      setErrorForgot("Votre demande n'a pas pu être traitée telle qu'elle a été saisie. Veuillez réessayer.")
    }
  }, [error]);

  useEffect(() => {
    if(isAuth) {
      getUser();
    }
  // eslint-disable-next-line 
  }, [isAuth]);

  useEffect(() => {
    if (userAuth) {
      if (userAuth?.tools.find((t) => t.tool === TOOL_ID  && t.role === "Admin")) {
        history.push("/dashboard");
      } else if (userAuth?.tools.find((t) => t.tool === TOOL_ID  && t.role === "Owner")) {
        history.push("/question-assign");
      } else if (userAuth?.tools.find((t) => t.tool === TOOL_ID  && t.role === "Member")) {
        history.push("/question-member");
      } else {
        history.push("/login")
      }
    }
  },[userAuth , history]);


  const backFromReset = () => {
    setDisplayLogin(true)
    const obj = {}
    obj.message = null
    dispatch({
      type: 'FORGOT_PASSWORD',
      payload: obj,
    });
  }


  const submitLogin = (values)  => {
    loginAction( dispatch , values);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  };

  const submitReset = (values)  => {
    forgotPasswordAction( dispatch , values);
    dispatch({
      type: 'ERROR_AUTH',
      payload: null,
    });
  };


  function renderLoginForm() {
    return (
      <form className={styles['form-login']} onSubmit={handleSubmit(submitLogin)}>

        <label htmlFor="email">Email</label>
        <input
          name="email"
          className={`${styles.primary}`}
          ref={register({
            required: "Compléter votre email",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Adresse mail invalide"
            }
          })}
        />

        <p className="text-error">{errors.email && errors.email.message}</p>

        <label htmlFor="password">Mot de passe</label>
        <input
          className={`${styles.primary}`}
          autoComplete="current-password"
          name="password"
          type={showPassword? "text" : "password"}
          ref={register({
            required: "Compléter votre mot de passe",
          })}
        />
        <p className="text-error">{errors.password && errors.password.message}</p>
        <div
          className={styles.eye}
          onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <FaEye size={20} color={"#192243"} /> : <FaEyeSlash size={20} color={"#192243"} /> }
        </div>
        
        <div className={styles['btn-container']}>
          <button
            className={`${styles.btn} ${styles.primary}`}
            type="submit">Connexion
          </button>
          <button onClick={() => setDisplayLogin(false)} className={styles['btn-reset']} >
            Mot de passe oublié ?
          </button>
        </div>


        <p className="text-error text-center">{context.auth.errorLogin && context.auth.errorLogin === 401 && <span> Adresse e-mail ou mot de passe invalide.</span>}</p>
    
      </form>
    )
  }

  function renderResetForm() {
    if (context.auth && context.auth.message && context.auth.message.forgot) {
      return(
        <div className={`${styles['form-reset']} ${styles['message']}`}>

          <p className="text-center">Veuillez vérifier votre adresse e-mail pour connaître<br/>le lien permettant de réinitialiser votre mot de passe.</p>

          <button
            onClick={() =>  backFromReset()}
            className={`${styles.btn} ${styles.primary}`}>
            Retour au login
          </button>
        </div>
      )

    } else {
      return (
        <form className={styles['form-reset']} onSubmit={handleSubmit(submitReset)}>
        
          <label htmlFor="email">Email</label>
          <input
            className={`${styles.primary}`}
            name="email"
            ref={register({
              required: "Compléter votre email",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Adresse mail invalide"
              }
            })}
          />

          {errorForgot? 
            <p className="text-error text-center" style={{ margin: 5}}>{errorForgot}</p>
            :
            <p className="text-error text-center">{errors.email && errors.email.message}</p>
          }
          
          <div className={styles['btn-container']}>
            <button
              className={`${styles.btn} ${styles.primary}`}
              type="submit">
              Réinitialiser le mot de passe
            </button>
            <button onClick={() => setDisplayLogin(true)}
              className={styles['btn-reset']}>
              Retour au login
            </button>
          </div>
      
        </form>
      )
    }
  }
  
  return (
    <div className={styles.login}>

      <div className={styles['col-img']}></div>

      <div className={styles['col-form']}>

        <p className={`${styles.title} bold`}>
          <span className={`${styles.name} bold`} >Medocta</span><br/>
          <span className="regular">BY </span><span>octapharma</span>
        </p>

        {displayLogin ?
          renderLoginForm()
          :
          renderResetForm()
        }

        <div className={styles.links}>
          {/* <Link to="/legal" className={styles.link}>Mentions légales</Link> */}
          <Link to="/data-policy" className={styles.link}>Politique de confidentialités</Link>
          <Link to="/transparency" className={styles.link}>Transparence des liens d’intérêts</Link>
          {/* <Link to="/cgu" className={styles.link}>Conditions générales d'utilisation</Link> */}
        </div>

      </div>

    </div>
  )
}
