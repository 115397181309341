import React , { useEffect , useCallback } from 'react'
import { useForm ,  Controller } from "react-hook-form";

//Components
import Input from '../../../../components/lib/form/Input/Input';
import Textarea from '../../../../components/lib/form/Textarea/Textarea';
import Button from '../../../../components/lib/Button/Button';
import CustomSelect from '../../../../components/lib/form/Select/CustomSelect';

//Global state
import { useGlobalContext } from '../../../../context/GlobalContext';
import { putQuestionAction , getDrugsListAction } from '../../../../actions/questionsActions';

//Style
import styles from './EditModale.module.scss';

export default function EditModale({ question , closeModale }) {

  const { handleSubmit, register, errors , control , setValue } = useForm();

  const [ context, dispatch ] = useGlobalContext();
  const { lists } = context.questions

  const soucresOptions = ['Absys','Téléphone', 'Mail' , 'Questionnaire'].map(s => ({ value: s , label: s }));
  const drugs = lists?.drugs?.map(d => ({ value: d._id , label: d.name }));

  const getDrugs = useCallback(() => {
    getDrugsListAction(dispatch);
  },[dispatch]);
  
  function submitQuestion(value) {
    putQuestionAction(dispatch , question._id , value);
    closeModale();
  }

  useEffect(() => {
    getDrugs();
    // eslint-disable-next-line 
  },[]);


  return (
    <form onSubmit={handleSubmit(submitQuestion)} className={styles.container}>
      <h2>EDITER LA QUESTION</h2>
      <Input
        label={"Titre"}
        name={"title"}
        defaultValue={question?.title}
        register={register({
          required: "le titre est obligatoire",
        })}
        withErrors={true}
        error={errors.title && errors.title.message}
      />

      {drugs &&
        <Controller
          name="drug"
          control={control}
          defaultValue={question?.drug ? question?.drug: []}
          render={() =>
            <CustomSelect
              label="Produit"
              defaultValue={question?.drug ? question?.drug.map((d) => ({ label: d.name, value: d._id })): []}
              options={drugs ? drugs : []}
              isSearchable
              isMulti={true}
              withErrors={true}
              handleChange={(array) => setValue( 'drug' , array?.map(a => a.value) )}
              error={errors.drug && errors.drug.message}
              />
          }
        /> 
      }    

      <Controller
        name="source"
        control={control}
        defaultValue={question?.source ? question.source : null }
        render={() =>
          <CustomSelect
            label="Source"
            defaultValue={soucresOptions.find(opt => opt.value === question.source)}
            options={soucresOptions}
            withErrors={true}
            handleChange={(opt) => setValue( 'source' , opt.value )}
            error={errors.source && errors.source.message}
            />
        }
      />

      <Textarea
        label={"Votre message"}
        name={"question"}
        defaultValue={question?.question}
        register={register}
        withErrors={true}
        error={errors.question && errors.question.message}
      />

      <Button
        type="submit"
        primary
        >
        Valider
      </Button>
    </form>
  )
}
