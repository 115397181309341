import React from 'react';
import styles from './Cases.module.scss';

export default function Cases({ cases }) {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          {cases.isConfidential}
        </div>
        <div className={styles.col}>
          Publication à ne pas réutiliser / confidentielles
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          {cases.isHorsAmm}
        </div>
        <div className={styles.col}>
          Hors AMM
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          {cases.pv}
        </div>
        <div className={styles.col}>
          PV
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          {cases.reclamation}
        </div>
        <div className={styles.col}>
          Réclamations
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          {cases.staff}
        </div>
        <div className={styles.col}>
          Staff
        </div>
      </div>
    </div>
  )
}
