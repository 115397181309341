import React , { useEffect } from 'react';
import { Link , useHistory } from 'react-router-dom';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';

//styles & images
import styles from './Ask.module.scss';
import illu from '../../assets/images/ask2.svg';

//components
import Button from '../../components/lib/Button/Button';

//Global state
import { useGlobalContext } from '../../context/GlobalContext';
import { postQuestionAction } from '../../actions/questionsActions';

export default function AskAbstractPage() {

  const history = useHistory();
  const [ context, dispatch ] = useGlobalContext();

  const { askForm } = context.questions

  useEffect(() => {
    if (!askForm?.author) {
      history.push("/ask/author");
    }
    // eslint-disable-next-line 
  },[]);

  useEffect(() => {
    if (context.questions.message === "Question saved") {
      history.push("/ask/valid");
    }
    // eslint-disable-next-line 
  },[context.questions.message])

  return (
    <div className={`page-container ${styles.container}`}>
      <div className="content">
        <div className={styles.row}>
          <div className={styles.col}>
            <h2>Résumé de votre question</h2>
            <p>{askForm?.author?.delegate && `Délégué : ${askForm.author.delegate}`}</p>

            <div className={styles.author}>
              <div className={styles['author-row']}>
                {askForm?.author?.firstName && <p><span className="text-grey">Auteur </span><br/>{askForm?.author?.firstName} {askForm?.author?.lastName}</p>}
                {askForm?.author?.service && <p><span className="text-grey">Service </span><br/>{askForm?.author?.service}</p>}
                {askForm?.author?.qualification && <p><span className="text-grey">Qualification </span><br/>{askForm?.author?.qualification}</p>}
                {askForm?.author?.hospital && <p><span className="text-grey">Établissement </span><br/>{askForm?.author?.hospital}</p>}
              </div>
              <div className={styles['author-row']}>
                {askForm?.author?.tel && <p><FaPhoneAlt size={20} /> {askForm?.author?.tel}</p>}
                {askForm?.author?.email && <p><IoMdMail size={20}/> {askForm?.author?.email}</p>}
              </div>
            </div>

            <h3>Votre question</h3>

            <p className="bold">{askForm?.title}</p>

            {askForm?.question && <p>{askForm?.question}</p> }
            
            <div className={styles['btn-container']}>
              <Link to={'/ask/author'}>
                <Button
                  primary
                  outline
                  className={`${styles.btn}`}
                  type="button"
                  >
                  <p>PRÉCÉDENT</p>
                </Button>
              </Link>
              <Button
                primary
                onClick={() => postQuestionAction(dispatch, { ...askForm, drug: askForm.drug.map((d) => d.value)})}
                className={`${styles.btn}`}
                >
                <p>ENVOYER MA QUESTION</p>
              </Button>
            </div>

          </div>
          <div className={`${styles.col} ${styles.illu}`}>
            <img src={illu} alt="illu" />
          </div>
        </div>

        <footer>
          {/* <Link to={'/ask'}>En savoir plus sur la confidentialité et le traitement de mes données</Link> */}
        </footer>

      </div>
    </div>
  )
}
