import React , { useState } from 'react';
import ReactExport from "react-export-excel";

import { useGlobalContext } from '../../../../context/GlobalContext';

import CustomSelect from '../../../../components/lib/form/Select/CustomSelect';
import Button from '../../../../components/lib/Button/Button';
import { exportAnswersAction } from '../../../../actions/answerActions';

import styles from './ExportAnswersModale.module.scss';

export default function ExportAnswersModale({ closeModale }) {

  const [ context,  dispatch ] = useGlobalContext();

  const { exportData } = context.answers;

  const options = [
    { value:"code" , label: "code" },
    { value:"date" , label: "date" },
    { value:"title" , label:"title"},
    { value:"question" , label:"question"},
    { value:"tags" , label:"tags"},
    { value:"assignTo" , label:"assignTo"},
    { value:"isConfidential" , label:"isConfidential"},
    { value:"isHorsAmm" , label:"isHorsAmm"},
    { value:"pv" , label:"pv"},
    { value:"reclamation" , label: "reclamation"},
    { value:"staff" , label:"staff"},
    { value:"status" , label:"statut"},
    { value:"question author" , label:"question author"},
    { value:"delegate" , label:"delegate"},
    { value:"source" , label:"source"},
    { value:"mode" , label:"mode"},
    { value:"answer" , label:"answer" },
    { value:"answer author" , label:"answer author" },
    { value:"answer date" , label:"answer date" }
  ];

  const [ selectedOptions , setSelectedOptions ] = useState(options);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const renderExcelToExport = () => {
    if (exportData?.dataToExport) {
      return exportData.dataToExport.map((sheet ,i) => {
        return (
          <ExcelSheet
            key={i}
            data={sheet.sheetData}
            name={sheet.sheetName}>
            {sheet.sheetHeader.map(( colValue ,i ) => (<ExcelColumn key={i} label={colValue} value={colValue} /> ))}
          </ExcelSheet>
        );
      });
    };
  };

  return (
    <div className={styles.container}>
      <h2>Exporter les réponses</h2>
      <CustomSelect
        label={"Colonnes sélectionnées"}
        isMulti
        defaultValue={selectedOptions}
        handleChange={(val) => setSelectedOptions(val)}
        options={options ? options  : []}
        />
      {exportData?.fileName ?
        <ExcelFile
          element={
            <Button
              onClick={() => closeModale()}
              success
              >
              Télécharger
            </Button>
          }
          filename={exportData.fileName}
          >
          {renderExcelToExport()}
        </ExcelFile>
        :
        <Button
          onClick={() => exportAnswersAction( dispatch , { columns : selectedOptions.map(opt => opt.value ) })}
          primary
          >
          Exporter
        </Button>
      }
    </div>
  )
}
