import React , { useEffect , useCallback , useState, useMemo , useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';
import { uniqueId } from 'lodash';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import pptxgen from "pptxgenjs";

//Style
import styles from './DashboardPage.module.scss';

//golbal context
import { useGlobalContext } from '../../context/GlobalContext';
import { getDashboardAction , getQuestionListsAction } from '../../actions/questionsActions';
import CheckBox from '../../components/lib/form/CheckBox/CheckBox';
import Resolution from './Resolution/Resolution';
import { getNotifsAction } from '../../actions/authActions';
import CustomSelect from '../../components/lib/form/Select/CustomSelect';

import PieChart from '../../components/partials/PieChart/PieChart';
import SelectPeriod from '../../components/partials/SelectPeriod/SelectPeriod';
import Cases from './Cases/Cases';
import Bars from './Bars/Bars';
import { FaDownload } from 'react-icons/fa';


const date = new Date();
var endDate = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
var startDate = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');

const ExportBtn = ({ handleClick }) => {
  const el = document.getElementById('portal');
  if (el) {
    return ReactDOM.createPortal(
      <button className={styles.export} onClick={() => handleClick()}>
        exporter <FaDownload size={18} />
      </button>,
      el
    );
  }
}

export default function Dashboard() {

  const [ context, dispatch ] = useGlobalContext();
  const containerRef = useRef();


  const tags = context.questions?.lists?.tags;
  const dashboard = context.questions?.dashboard;

  const [ tagsOptions , setTagsOptions ] = useState();

  const { setValue, watch, register, control } = useForm({
    defaultValues: {
      isCompare: false,
      tags: [],
      periods: [{ startDate, endDate }],
    }
  });

  const filters = watch();

  const datas = useMemo(() => {
    const dashboards = [];
    if(dashboard?.length > 0) {
      const datas = [...dashboard];
      const filteredData = datas.filter((d) => {
        if(filters.tags.length === 0) {
          return d;
        }
        if(d.tags?.find((t) => filters.tags.find((f) => f.value === t))) {
          return d;
        }
        return null;
      });
      if (filters?.isCompare) {
        filters?.periods?.forEach(({ startDate, endDate }) => {
           const start = new Date(startDate);
           const end = new Date(endDate);
           const data = filteredData.filter((s) => {
             const date = new Date(s.date);
             if (date >= start && date <= end) {
               return s;
             }
             return null;
           })
           const pieData = [];
           const productsData = [];
           const casesData = {
             isConfidential: 0,
             isHorsAmm: 0,
             pv: 0,
             reclamation: 0,
             staff: 0,
           }
           data?.map((d) => {
            if (d.isConfidential) {
              casesData.isConfidential += 1;
            }
            if (d.isHorsAmm) {
              casesData.isHorsAmm += 1;
            }
            if (d.pv === 'oui') {
              casesData.pv += 1;
            }
            if (d.reclamation) {
              casesData.reclamation += 1;
            }
            if (d.staff) {
              casesData.staff += 1;
            }
            if (d?.drug?.name) {
              const index = productsData.findIndex((p) => p.name === d?.drug?.name); 
              if (index !== -1) {
                productsData[index].value += 1;
              } else {
                productsData.push({
                  name: d?.drug.name,
                  value: 1,
                })
              }
            }
            d?.tags?.forEach((t) => {
              const index = pieData.findIndex((p) => p.name === t); 
              if (index !== -1) {
                pieData[index].value += 1;
              } else {
                pieData.push({
                  name: t,
                  value: 1,
                })
              }
            });
            return null;
          });
           dashboards.push({
             id: uniqueId(),
            start,
            end,
            data,
            pieData,
            productsData,
            casesData,
           });
        });
      } else {
        const cumul = {
          id: uniqueId(),
          data: [],
          pieData: [],
          productsData: [],
          casesData: {
            isConfidential: 0,
            isHorsAmm: 0,
            pv: 0,
            reclamation: 0,
            staff: 0,
          }
        }
        filters?.periods?.forEach(({ startDate, endDate }) => {
          const start = new Date(startDate);
          const end = new Date(endDate);
          filteredData.filter((s) => {
            const date = new Date(s.date);
            if (date >= start && date <= end && !cumul.data.find((d) => d._id  === s._id)) {
              return cumul.data.push(s)
            }
            return null;
          });
        });

        cumul.data?.map((d) => {
          if (d.isConfidential) {
            cumul.casesData.isConfidential += 1;
          }
          if (d.isHorsAmm) {
            cumul.casesData.isHorsAmm += 1;
          }
          if (d.pv === 'oui') {
            cumul.casesData.pv += 1;
          }
          if (d.reclamation) {
            cumul.casesData.reclamation += 1;
          }
          if (d.staff) {
            cumul.casesData.staff += 1;
          }
          if (d?.drug?.name) {
            const index = cumul.productsData.findIndex((p) => p.name === d?.drug?.name); 
            if (index !== -1) {
              cumul.productsData[index].value += 1;
            } else {
              cumul.productsData.push({
                name: d?.drug.name,
                value: 1,
              })
            }
          }
          return d?.tags?.forEach((t) => {
            const index = cumul.pieData.findIndex((p) => p.name === t); 
            if (index !== -1) {
              cumul.pieData[index].value += 1;
            } else {
              cumul.pieData.push({
                name: t,
                value: 1,
              })
            }
          });
        });
        dashboards.push(cumul);
      }
    }
    return [...dashboards];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filters, filters.isCompare , dashboard]);

  const initData = useCallback(() => {
    getDashboardAction(dispatch);
    getQuestionListsAction(dispatch);
    getNotifsAction(dispatch , "Medocta");
  }, [dispatch]);

  function handleScreenShot() {
    const target = document.querySelector("#dashboard");
    target.parentNode.style.overflow = 'visible';
    const width = datas.length > 1 ? ((containerRef.current.clientWidth - 10) / 2 ) * datas.length : containerRef.current.clientWidth;
    html2canvas(target, {
      imageTimeout: 15000,
      width,
      height: target.offsetHeight,
    })
      .then((canvas) => {
        let pres = new pptxgen();
        let slide = pres.addSlide();
        const slideSize = {
          w: (width / 96) / 2,
          h: (target.offsetHeight / 96) / 2,
        }
        slide.addImage({
          data: canvas.toDataURL(),
          w: slideSize.w,
          h: slideSize.h,
          sizing:{
            type:'contain',
          }
        });
        pres.writeFile({ fileName: "presentation.pptx" });
        target.parentNode.style.overflow = 'auto';
    });
  }

  useEffect(() => {
    initData();
    register("tags");
    register("isCompare");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tags?.length) {
      const tagslist = tags?.filter(opt => opt !== null )?.sort().map(opt => ({ value: opt , label: opt }));
      setTagsOptions(tagslist);
    }
  }, [tags]);

  return (
    <div className={`page-container ${styles.container}`}>
      <div className={styles.filters}>
        <div className={styles.filter}>
          <label>Période</label>
          <SelectPeriod
            control={control}
            periods={watch('periods')}
          />
        </div>
        <div className={styles.check}>
        <CheckBox
          label="Comparer les périodes" 
          defaultValue={false}
          onChange={(val) => setValue('isCompare' , val)}
          />
        </div>
        <div className={styles.filter}>
          <label>Tags</label>
          <CustomSelect
            isMulti
            handleChange={(val) => setValue('tags', val)}
            options={tagsOptions ? tagsOptions  : []}
            />
        </div>
        <ExportBtn 
          handleClick={() => handleScreenShot()}
        />
      </div>
      <div className={styles['dashboard-container']}>
        <div className={styles.dashboards} ref={containerRef} id="dashboard">
          {datas?.map((d) => (
            <div key={d.id} className={`${styles.dashboard} ${filters.isCompare ? styles.compare : ''}`}>
              <section>
                <div className={styles.block}>
                  {d.data.length > 0 && d.start?.getDate() && d.end?.getDate() && <h5>{format(d.start, 'dd/MM/yyyy')} - {format(d.end, 'dd/MM/yyyy')}</h5>}
                  <Resolution
                    resolution={d.data}   
                  />
                </div>
              </section>
              <section className={styles.row}>
                <div className={styles.block}>
                  {d.data.length > 0 && d.start?.getDate() && d.end?.getDate() && <h5>{format(d.start, 'dd/MM/yyyy')} - {format(d.end, 'dd/MM/yyyy')}</h5>}
                  <label>Répartition des questions</label>
                  {d?.pieData && <div className={styles.pie}>
                    <PieChart data={d.pieData}/>
                  </div>}
                </div>
                <div className={styles.block}>
                  {d.data.length > 0 && d.start?.getDate() && d.end?.getDate() && <h5>{format(d.start, 'dd/MM/yyyy')} - {format(d.end, 'dd/MM/yyyy')}</h5>}
                  <label>Nombre de questions par cas</label>
                  <Cases cases={d.casesData} />
                </div>
              </section>
              <section>
                <div className={styles.block}>
                {d.data.length > 0 && d.start?.getDate() && d.end?.getDate() && <h5>{format(d.start, 'dd/MM/yyyy')} - {format(d.end, 'dd/MM/yyyy')}</h5>}

                  <label>Nombre de questions par tag</label>
                  {d?.pieData &&  <Bars data={d.pieData} index={d.id} />}
                </div>
              </section>
              <section>
                <div className={styles.block}>
                {d.data.length > 0 && d.start?.getDate() && d.end?.getDate() && <h5>{format(d.start, 'dd/MM/yyyy')} - {format(d.end, 'dd/MM/yyyy')}</h5>}

                  <label>Nombre de questions par produit</label>
                  {d?.productsData &&  <Bars data={d.productsData}  index={d.id} color="#FFA56D"/>}
                </div>
              </section>
            </div>
          ))}
          </div>
      </div>

    </div>
  )
}
