export function setVhUnit() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

export const hexToRgba = (hex, alpha = 1) => {
  let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";

}

export const formatDate = (val) => {
  let date = new Intl.DateTimeFormat('fr', { year: 'numeric' , month: 'long' }).format(new Date(val));
  return date;
}

export const status = {
  new: {
    label: 'Non résolue',
    color: '#192243'
  },
  submitted: {
    label: 'soumise',
    color: '#FFA56D'
  },
  pending: {
    label: 'En cours',
    color: '#FFA56D'
  },
  resolved: {
    label: 'Résolue',
    color: '#4DCD98'
  }
}

export const statusOptions = [
  {
    value: "new",
    label: 'Non résolue',
  },
  {
    value: "pending",
    label: 'En cours',
  },
  {
    value: "resolved",
    label: 'Résolue',
  },
]

export const sortByDateKey = (array , key ) => {
  return array.sort((a , b ) => {
    if (new Date(a[key]) < new Date(b[key])) return -1;
    if (new Date(a[key]) > new Date(b[key])) return 1;
    return 0
  }).reverse();
}

export const sortByKey = (array , key ) => {
  return array.sort((a , b ) => {
    if (b[key] < a[key]) return -1;
    if (b[key] > a[key]) return 1;
    return 0
  }).reverse();
}