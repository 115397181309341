import React from 'react';
import styles from './Button.module.scss';

export default function Button({ onClick , type ,  children , isDisabled , isLoading = false, className , primary , success , danger , outline , radius }) {
  return (
    <button
      type={type ? type : "button"}
      onClick={onClick? () => onClick() : null }
      disabled={isDisabled ? true : false }
      className={`${styles.btn} ${primary ? styles.primary : ''} ${isLoading ? styles.isLoading : ''} ${isDisabled ? styles.disabled : ''} ${ success ? styles.success : ''} ${ danger ? styles.danger : ''} ${outline ? styles.outline : ''} ${radius ? styles.radius : ''} ${className ? className : ''}`}
      >
      <div className={styles.children}>
        {children}
      </div>
    </button>
  )
}
