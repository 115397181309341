import React from 'react';
import { useForm , Controller } from "react-hook-form";

//styles & images
import styles from './Account.module.scss';
import illu from '../../assets/images/account.svg';

//components
import Button from '../../components/lib/Button/Button';
import Input from '../../components/lib/form/Input/Input';
import CustomSelect from '../../components/lib/form/Select/CustomSelect';

//Global state
import { useGlobalContext } from '../../context/GlobalContext';
import { putUserAuthAction } from '../../actions/authActions';

export default function Account() {

  const [ context, dispatch ] = useGlobalContext();

  const { userAuth } = context.auth

  const { handleSubmit, register, errors , control , setValue } = useForm();

  const users = context.questions?.lists?.users?.map(user => ({ label: `${user.profile.firstName} ${user.profile.lastName}`, value: user._id })) ; 
  const presenceOptions = [{ label: "présent" , value: true } , { label: "absent" , value: false }]

  function submitUser(values) {
    putUserAuthAction(dispatch , values)
  }

  return (
    <div className={`page-container ${styles.container}`}>
      <div className="content">
        <div className={styles.row}>
          {userAuth &&
            <form onSubmit={handleSubmit(submitUser)} className={styles.col}>

              <h2>Mon compte</h2>

              <div className={styles['info-row']}>

                <div className={styles['info-col']}>
                  <Input
                    label={"Nom"}
                    name={"profile.lastName"}
                    placehloder={"Nom"}
                    defaultValue={userAuth?.lastName}
                    register={register({
                      required: "le nom est obligatoire",
                    })}
                    withErrors={true}
                    error={errors?.profile?.lastName?.message}
                  />
                </div>

                <div className={styles['info-col']}>
                  <Input
                    label={"Prénom"}
                    name={"profile.firstName"}
                    placehloder={"Prénom"}
                    defaultValue={userAuth?.firstName}
                    register={register({
                      required: "le prénom est obligatoire",
                    })}
                    withErrors={true}
                    error={errors?.profile?.firstName?.message}
                  />
                </div>

              </div>

              <div className={styles['info-row']}>

                <div className={styles['info-col']}>
                  <Input
                    label={"Mail"}
                    name={"email"}
                    placehloder={"mail@mail.fr"}
                    defaultValue={userAuth?.email}
                    register={register({
                      required: "le mail est obligatoire",
                    })}
                    withErrors={true}
                    error={errors?.email?.message}
                  />
                </div>

                <div className={styles['info-col']}>
      
                </div>

              </div>

              <div className={`${styles['info-row']} ${styles.status}`}>

                <div className={`${styles['info-col']}`}>
                  <Controller
                    name="isPresent"
                    control={control}
                    defaultValue={userAuth?.isPresent ? userAuth.isPresent : false }
                    render={() =>
                      <CustomSelect
                        label="Mon statut"
                        defaultValue={presenceOptions.find(opt => opt.value === userAuth.isPresent)}
                        options={presenceOptions ? presenceOptions : []}
                        withErrors={true}
                        handleChange={(opt) => setValue( 'isPresent' , opt.value )}
                        error={errors.isPresent && errors.isPresent.message}
                        />
                    }
                  />
                </div>

                <div className={styles['info-col']}>
                  {users &&
                    <Controller
                      name="backup"
                      control={control}
                      defaultValue={userAuth?.backup ? userAuth.backup : null}
                      render={() =>
                        <CustomSelect
                          label="Mon backup"
                          defaultValue={users.find(opt => opt.value === userAuth.backup) ? users.find(opt => opt.value === userAuth.backup) : null}
                          options={users ? users : []}
                          withErrors={true}
                          handleChange={(opt) => setValue( 'backup' , opt.value )}
                          error={errors.backup && errors.backup.message}
                          />
                      }
                    />
                  }
                </div>

              </div>
                    
              <div className={styles['btn-container']}>
                <Button
                  primary
                  type="submit"
                  className={`${styles.btn}`}
                  >
                  <p>VALIDER</p>
                </Button>
              </div>

            </form>
          }

          <div className={`${styles.col} ${styles.illu}`}>
            <img src={illu} alt="illu" />
          </div>

        </div>

      </div>
    </div>
  )
}
