import React , { useEffect , useCallback , useState } from 'react';
import { useParams  , Link  , useHistory } from 'react-router-dom';
import { RiArrowLeftSLine } from "react-icons/ri";
import { FaPhoneAlt , FaPen , FaSave } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { IoMdMail, IoMdPin } from 'react-icons/io';
import { format , addDays } from 'date-fns';
import { useForm , Controller } from "react-hook-form";

//UTILS
import { status } from '../../../utils/utils';
//styles
import styles from './QuestionPage.module.scss';

//global state
import { useGlobalContext } from '../../../context/GlobalContext';
import { deleteQuestionAction, getQuestionAction , putQuestionAction } from '../../../actions/questionsActions';
import { postAnswerAction , putAnswerAction , deleteFileAction, getFileAction } from '../../../actions/answerActions';

//components
import TagsSelect from '../../../components/partials/TagsSelect/TagsSelect';
import Button from '../../../components/lib/Button/Button';
import Modale from '../../../components/lib/Modale/Modale';
import CheckBox from '../../../components/lib/form/CheckBox/CheckBox';
import DatePicker from '../../../components/lib/DatePicker/DatePicker';
import EditModale from './EditModale/EditModale';
import RedirectModale from './RedirectModale/RedirectModale';
import EditContactModale from './EditContactModale/EditContactModale';
import DocumentModale from './DocumentModale/DocumentModale';
import SubmitModale from './SubmitModale/SubmitModale';
import AnswerTypeModale from './AnswerTypeModale/AnswerTypeModale';
import HtmlEditor from '../../../components/lib/form/HtmlEditor/HtmlEditor';
import { TOOL_ID } from '../../../actions';
import Textarea from '../../../components/lib/form/Textarea/Textarea';
import EditAuthorModale from './EditAuthorModale/EditAuthorModale';
import ChangeAuthorModale from './ChangeAuthorModale/ChangeAuthorModale';

export default function QuestionPage({ isAssign }) {

  // const cnilSentence = "<p>Les informations recueillies font l’objet d’un traitement informatique destiné à la gestion de l'information médicale chez OCTAPHARMA France. Le responsable de ces données est Octapharma France. Ces informations seront conservées pendant une durée limitée et basée sur l’Autorisation de Mise sur le Marché des médicaments. Conformément à la réglementation sur la protection des données personnelles, vous avez un droit d’accès, de rectification, d’opposition, d’effacement, de limitation du traitement et de portabilité des données vous concernant, pour exercer ces droits, vous devez adresser votre demande à FR2DPO@octapharma.com.</p>"
  const confidentialSentence = "<p>Ces publications sont strictement réservées à leur destinataire et sont protégées par les lois en vigueur sur le copyright. Toute reproduction et diffusion (papier ou e-mail) sont rigoureusement interdites.</p>"
  const horsAMMSentence = "<p>Nous souhaitons vous rappeler que les informations transmises dans ce courrier n’ont pas été validées dans l’Autorisation de Mise sur le Marché de XXXXX, il vous appartient d'en tenir compte dans le cas où vous souhaiteriez utiliser le produit XXX  dans le cadre de ces données.</p>"
  // const filesSentence = "<p>Ces publications sont strictement réservées à leur destinataire et sont protégées par les lois en vigueur sur le copyright. Toute reproduction et diffusion (papier ou e-mail) sont rigoureusement interdites.</p>"

  const history = useHistory();
  const { questionId } = useParams();
  const [ context, dispatch ] = useGlobalContext();

  const { handleSubmit , errors , control } = useForm();

  const { question, answerIsLoading } = context.questions;
  const { answer } = context.answers;
  const { userAuth } = context.auth;
  const isValid = answer?.validators?.map((v) => v.isValid).every(Boolean);

  const [ isReadOnly , setIsReadOnly ] = useState(true);
  const [ confirmDelete , setConfirmDelete ]= useState(false);
  const [ modaleIsOpen , setModaleIsOpen ] = useState(false);
  const [ displayTooltip , setDisplayTooltip ] = useState(false);
  const [ answerHtml , setAnswerHtml ] = useState();
  const [ justification, setJustification ] = useState('');
  const [ answerDefault , setAnswerDefault ] = useState();
  const [ checkBoxes , setCheckBoxes ] = useState({
    pv: null,
    reclamation: null,
    staff: null
  });

  const initData = useCallback(() => {
    getQuestionAction(dispatch , questionId);
    dispatch({
      type:'GET_ANSWER',
      payload: null
    });
  }, [dispatch , questionId]);

  const getQuestion = useCallback(() => {
    getQuestionAction(dispatch , questionId);
  }, [dispatch , questionId]);

  useEffect(() => {
    initData();
  }, [initData]);


  useEffect(() => {
    if (context.auth.toast?.message === "La réponse a été envoyée" || context.auth.toast?.message === "Le contact a été modifié") {
      getQuestion();
    }
  }, [context.auth.toast , getQuestion]);

  useEffect(() => {
    if (answer?.answer) {
      setAnswerDefault(answer.answer);
      setAnswerHtml(answer.answer);
    }
  },[answer?.answer]);

  useEffect(() => {
    if (answer?.justification) {
      setJustification(answer.justification);
    }
  },[answer?.justification]);

  useEffect(() => {
    let checkState = {...checkBoxes}
    if (question?.pv && typeof question.pv === "string") {
      checkState.pv = question.pv ;
    }
    if (question?.reclamation && typeof question.reclamation === "string") {
      checkState.reclamation = question.reclamation ;
    }
    if (question?.staff && typeof question.staff === "string") {
      checkState.staff = question.staff ;
    }
    setCheckBoxes(checkState);
 
    let isAdmin = userAuth?.tools.find((t) => t.tool === TOOL_ID ).role === "Admin" ? true : false

    if (userAuth && question) {
      if (question?.status !== "resolved") {
        if (isAdmin) {
          setIsReadOnly(false)
        } else if (question?.assignTo?._id === userAuth?._id ) {
          setIsReadOnly(false)
        }
      } else {
        setIsReadOnly(true)
      }
    }
    //eslint-disable-next-line
  },[question , userAuth]);

  function deleteQuestion() {
    if (isReadOnly) return;
    deleteQuestionAction(dispatch , questionId );
    history.push('/question');
  }

  function submitAnwser() {
    if (isReadOnly) return;
    if (answer?._id) {
      putAnswerAction(dispatch , answer._id , { answer: answerHtml , justification: justification })
    } else {
      const answerData = { 
        question: questionId,
        answer: answerHtml,
        justification: justification,
      };
      postAnswerAction(dispatch , answerData );
    }
  }

  function submitAnwserType(value) {
    if (isReadOnly) return;
    let answerData = value;
    setAnswerDefault();
    setAnswerHtml();
    if (answer?._id) {
      putAnswerAction(dispatch , answer._id , answerData );
    } else {
      answerData.question = questionId;
      postAnswerAction(dispatch , answerData );
    }
  }

  function HandleChangeAnswer(key , value ) {
    if (isReadOnly) return;
    if (answer?._id) {
      const data = {[key]: value}
      if (!answer?.date) {
        data.date = new Date();
      }
      putAnswerAction(dispatch , answer._id , data )
    } else {
      const answerData = { 
        question: questionId,
        [key]: value,
        date: new Date()
      };
      postAnswerAction(dispatch , answerData );
    }
  }

  function HandleChangeQuestion(key , value) {
    if (isReadOnly) return;
    let text = answerHtml || '';
    if (key === "isConfidential") {
      if (value) {
        text += confidentialSentence;
      } else if (text.includes(confidentialSentence)){
        text =text.replace(confidentialSentence, '');
      }
      setAnswerHtml(text)
      setAnswerDefault(text)
      HandleChangeAnswer('answer' , text )
    }
    if (key === "isHorsAmm") {
      // const regex = /<p class='class-amm'>((.|\n)*?)<\/p>/;
      const regex = /Nous souhaitons vous rappeler que les informations transmises dans ce courrier((.|\n)*?)dans le cadre de ces données./;
      if (value) {
        text += horsAMMSentence
      } else {
        text = text.replace(regex, '');
        text = text.replace('<p></p>', '');
      }
      setAnswerHtml(text)
      setAnswerDefault(text)
      HandleChangeAnswer('answer' , text )
    }  
    putQuestionAction(dispatch , questionId , {[key]: value})
  }

  function HandleChangeCheckBox(key , value , put = null ) {
    if (!value) {
      setCheckBoxes({...checkBoxes , [key]: null });
      putQuestionAction(dispatch , questionId , {[key]: null });
    } else if (typeof value === "string") {
      setCheckBoxes({...checkBoxes , [key]: value })
      if (put) {
        putQuestionAction(dispatch , questionId , {[key]: value });
      }
    } else {
      setCheckBoxes({...checkBoxes , [key]: "" })
    }
  }

  function handleChangeMode(value) {
    if (isReadOnly) return;
    let arr = answer ? answer?.mode : [];
    if(arr.find(m => m === value)) {
      arr = arr.filter(m => m !== value);
    } else {
      arr.push(value);
    };
    HandleChangeAnswer("mode" , arr )
  }

  function deleteFile(fileId) {
    if (isReadOnly) return;
    HandleChangeAnswer( 'files' ,answer.files.filter(f => f !== fileId).map((f) => f._id));
    deleteFileAction(dispatch, fileId);
  }

  async function deleteProof(fileId) {
    if (isReadOnly) return;
    console.log(fileId)
    HandleChangeQuestion( 'proof' , question.proof.filter(f => f._id !== fileId).map((f) => f._id));
    deleteFileAction(dispatch, fileId);
  }

  function sendValidation() {
    let validation = {
      message: '',
      bool: true,
      isLate: false,
    };
    if (question.status === "submitted") {
      validation.bool = false;
    }
    if (answer?.date) {
      const anwsersLateDate = addDays(new Date(question.date), question.delay + 7);
      if (new Date(answer?.date) > new Date(anwsersLateDate)) {
        validation.isLate = true;
      }
    }
    if (!answer?.answer) {
      validation.message = 'Compléter la réponse';
    } else if (answer?.mode.length === 0) {
      validation.message = 'Compléter le mode de la question';
    } else if (question?.source === "Absys" && !question.proof) {
      validation.message = 'Ajouter une preuve';
    } else if (question?.source === "Mail" && !question.proof) {
      validation.message = 'Ajouter une preuve';
    } else if (validation.isLate && !answer.justification) {
      validation.message = 'Compléter la justification';
    } else {
      validation.bool = false
    }
    return validation;
  }

  return (
    <>
    <div className={`page-container ${styles.container}`}>
      {question &&
        <div className={`content ${styles.content}`}>

          <aside>

            {isAssign ?
              <Link to={'/question-assign'} className={styles.back}>
                <RiArrowLeftSLine size={26} />
                <p>RETOUR À MES QUESTIONS</p>
              </Link>
              :
              <Link to={'/question'} className={styles.back}>
                <RiArrowLeftSLine size={26} />
                <p>RETOUR À LA LISTE DES QUESTIONS</p>
              </Link>
            }

            {!isReadOnly &&
              <Button
                primary
                onClick={() => setModaleIsOpen("redirect")}
                >
                Réattribuer la question
              </Button>
            }

            {!isReadOnly ?
              <>
                <div className={styles['date']}>
                  <DatePicker
                    label={"Date de la question"}
                    id={`data-picker-${questionId}`}
                    date={question.date ? new Date(question.date) : new Date(question.createdAt) }
                    handleChange={(val) => HandleChangeQuestion("date", val)}
                    />
                </div>
                <div className={styles['date']}>
                  {answer &&
                    <DatePicker
                      label={"Date de la réponse"}
                      id={`data-picker-answer-${questionId}`}
                      date={answer?.date ? new Date(answer.date) : new Date() }
                      handleChange={(val) => HandleChangeAnswer("date", val)}
                      />
                  }
                </div>
              </>
              :
                null
            }

            {question?.status && <p className="bold">Statut : <span style={{ color: status[question.status].color }}>{status[question.status].label}</span></p>}
            {/* <div>
              {!isReadOnly && question?.status &&
                <CustomSelect
                  defaultValue={statusOptions.find((opt) => opt.value === question?.status)}
                  options={statusOptions}
                  isSearchable
                  withErrors={true}
                  handleChange={(opt) => HandleChangeQuestion( 'status' , opt.value )}
                  error={errors.status && errors.status.message}
                  />
              }
            </div> */}

            <div className={`${styles['checkbox-container']}`}>

              <CheckBox
                isDisabled={isReadOnly}
                label="Publication à ne pas réutiliser / confidentielles" 
                defaultValue={question?.isConfidential && question.isConfidential}
                onChange={(val) => HandleChangeQuestion('isConfidential' , val)}
                />

              <CheckBox
                isDisabled={isReadOnly}
                label="Hors AMM" 
                defaultValue={question?.isHorsAmm && question.isHorsAmm}
                onChange={(val) => HandleChangeQuestion('isHorsAmm' , val)}
                />
            
              <CheckBox
                isDisabled={isReadOnly}
                label="PV"
                defaultValue={typeof checkBoxes.pv === "string" ? true : false } 
                onChange={(val) => HandleChangeCheckBox('pv' ,val ? "Ref PV" : null , "put" )} />

              {checkBoxes.pv &&
                <div className={`${styles['input-container']}`}>
                  <input
                    placeholder={"Référence"}
                    value={checkBoxes.pv ? checkBoxes.pv : ""}
                    onChange={(e) => HandleChangeCheckBox('pv' , e.target.value )}/>
                  <button onClick={() => putQuestionAction(dispatch , questionId , { pv: checkBoxes.pv })}>
                    <FaSave size={20} />
                  </button>
                </div>
              }

              <CheckBox
                isDisabled={isReadOnly}
                label="Réclamations"
                defaultValue={typeof checkBoxes.reclamation === "string" ? true : false } 
                onChange={(val) => HandleChangeCheckBox('reclamation' ,val ? "Ref Rec" : null , "put" )} />

              {checkBoxes.reclamation &&
                <div className={`${styles['input-container']} ${isReadOnly ? styles.disabled : ""}`}>
                  <input
                    placeholder={"Référence"}
                    value={checkBoxes.reclamation ? checkBoxes.reclamation : ""}
                    onChange={(e) => HandleChangeCheckBox('reclamation' , e.target.value )}/>
                  <button onClick={() => putQuestionAction(dispatch , questionId , { reclamation: checkBoxes.reclamation })}>
                    <FaSave size={20} />
                  </button>
                </div>
              }

              <CheckBox
                isDisabled={isReadOnly}
                label="Staff"
                defaultValue={typeof checkBoxes.staff === "string" ? true : false } 
                onChange={(val) => HandleChangeCheckBox('staff' , val ? new Date() : null , "put" )} />
              {typeof checkBoxes.staff === "string"  &&
                <div className={`${styles['staff-container']} ${isReadOnly ? styles.disabled : ""}`}>
                  <DatePicker
                    id={`staff-picker-${questionId}`}
                    date={question.staff ? new Date(question.staff) : new Date() }
                    handleChange={(val) => HandleChangeQuestion("staff", val)}
                    />
                </div>
              }


              <CheckBox
                isDisabled={isReadOnly}
                label="Infomed" 
                defaultValue={question?.isInfomed && question.isInfomed}
                onChange={(val) => HandleChangeQuestion('isInfomed' , val)}
              />

            </div>

            <div className={styles.contact}>
              <h4>Auteur</h4>
              {!isReadOnly &&
                <button
                  onClick={() => setModaleIsOpen("author")}
                  >
                  Editer
                </button>
              }
              {question?.author?.name && <p className="bold">{question?.author?.name}</p>}
              {question?.author?.lastName && <p className="bold">{question?.author?.lastName} {question?.author?.firstName}</p>}
              {question?.author?.center?.name && <p>{question?.author?.center?.name}</p>}
              {question?.author?.service && <p>{question?.author?.center?.services?.find((s) => s?._id === question?.author?.service)?.name}</p>}
              {question?.author?.center?.location?.city && <p><IoMdPin size={19} /> {question?.author?.center?.location?.city}</p>}
              {question?.author?.tel && <p><FaPhoneAlt size={16} /> {question?.author?.tel}</p>}
              {question?.author?.email && <p><IoMdMail size={18}/> {question?.author?.email}</p>}
              {!isReadOnly &&
                <button
                  className={styles['change-author']}
                  onClick={() => setModaleIsOpen("change-author")}
                  >
                  Changer d'auteur
                </button>
              }
            </div>

            <div className={styles.contact}>
              <h4>Contacts</h4>
              {!isReadOnly &&
                <button
                  onClick={() => setModaleIsOpen("contact")}
                  >
                  Editer
                </button>
              }
              {question?.contacts?.map((c , i) => {
                return (
                  <div key={`contact-${i}`}>
                    {c.name && <p className={`${styles.name} bold`}>{c.name}</p>}
                    {c.lastName && <p className={`${styles.name} bold`}>{c.lastName} {c.firstName}</p>}
                    {c.service && <p>{c.service}</p>}
                    {c.tel && <p><FaPhoneAlt size={16} /> {c.tel}</p>}
                    {c.email && <p><IoMdMail size={18}/> {c.email}</p>}
                  </div>
                )
              })}
            </div>

            {question?.delegate?.profile &&
              <div className={styles.delegate}>
                <p className="bold">Délégué</p>
                <p>{question.delegate?.profile?.firstName} {question.delegate?.profile?.lastName?.toUpperCase()} </p>
              </div>
            }

            <div className={styles.contact}>
              <h4>Commentaires de validation</h4>
              {answer?.validators?.filter((v) => v?.comment).map((v , i) => {
                return (
                  <div key={`validator-${i}`} className={styles.validator}>
                    {v.user.profile.firstName && <p className={`${styles.name} bold`}>{v.user.profile.firstName} {v.user.profile.lastName}</p>}
                    {v.comment && <p>{v.comment}</p>}
                  </div>
                )
              })}
            </div>

            {question?.delegate?.profile &&
              <div className={styles.delegate}>
                <p className="bold">Délégué</p>
                <p>{question.delegate?.profile?.firstName} {question.delegate?.profile?.lastName?.toUpperCase()} </p>
              </div>
            }

          {!isReadOnly &&
            <>
            {confirmDelete ?
              <button
                className={styles.delete}
                onClick={() => deleteQuestion()}
                >
                <MdDelete size={20}/> <p>Confirmer la suppression</p>
              </button>
              :
              <button
                className={styles.delete}
                onClick={() => setConfirmDelete(true)}
                >
                <MdDelete size={20}/> <p>Supprimer la question</p>
              </button>
            }
            </>
          }
          </aside>

          <main className={`box`}>
            <div className={`${styles['btn-oral-container']} ${isReadOnly ? "disable-pointer" : ""}`}>
              <button
                className={answer?.mode.find(m => m === "written") ? styles['is-active'] :""}
                onClick={() => handleChangeMode("written")}
               >
                Ecrit
              </button>
              <button
                className={answer?.mode.find(m => m === "oral") ? styles['is-active'] :""}
                onClick={() => handleChangeMode("oral")}
                >Oral
              </button>
            </div>

            <div className={styles.edit}>
              {!isReadOnly &&
                <button
                  onClick={() => setModaleIsOpen("edit")}
                  className={styles.btn}
                  >
                <FaPen size={24} />
                </button>
              }
              <h1 className={styles.title}>{question.title}</h1>
            </div>
 
            <div className={styles.info}>
              {question?.code && <p><span className="text-grey">Code </span>{question?.code}</p>}
              {question?.drug && <p><span className="text-grey">Produit </span>{question?.drug?.map((d) => d.name)?.join(' / ')}</p>}
              {question?.author?.qualification && <p><span className="text-grey">Qualification </span>{question?.author?.qualification}</p>}
              {/* {question?.author?.hospital && <p><span className="text-grey">Établissement </span>{question?.author?.hospital}</p>} */}
              {question?.source && <p><span className="text-grey">Source </span>{question?.source}</p>}
              {question?.date && <p><span className="text-grey">Postée le </span>{format(new Date(question.date), 'dd/MM/yyyy')}</p>}
            </div>

            <div className={styles.question}>
              <p className={`text-grey ${styles.label}`}>Message</p>
              <p>{question.question}</p>
            </div>

            <div className={styles.proofs}>
              {question?.proof && question?.proof?.map((p) => (
                <div key={p._id} className={styles.proof}>
                  <button
                    onClick={() => getFileAction(dispatch , p._id )}
                    type="button"
                    >
                    {p.title}
                  </button>
                  {!isReadOnly &&
                    <button
                      className={styles.delete}
                      onClick={() => deleteProof(p._id)}
                      type="button"
                      >
                      <MdDelete />
                    </button>
                  }
                </div>
               ))}
              {!isReadOnly &&
                <Button
                  primary
                  onClick={() => setModaleIsOpen("proof")}
                  type="button"
                  >
                  Ajouter une preuve
                </Button>
              }
            </div>
    
            <Controller
              name="tags"
              control={control}
              defaultValue={question?.tags ? question?.tags : null }
              render={() =>
                <TagsSelect
                  label="Tags"
                  isReadOnly={isReadOnly}
                  defaultValue={question?.tags ? question?.tags.map(item => ({ value: item , label: item })) : []}
                  withErrors={true}
                  onChange={(val) => putQuestionAction(dispatch  , questionId ,  { tags: val })}
                  error={errors.tags && errors.tags.message}
                  />
              }
            />

            <form onSubmit={handleSubmit(submitAnwser)} className={styles.answer} >
              {question.assignTo && 
                <p>
                  <span className="bold">Réponse de </span>
                  {answer?.author?._id !== question.assignTo._id ?
                  <span>{answer?.author?.profile?.firstName} {answer?.author?.profile?.lastName} (assignée à {question.assignTo?.profile?.firstName} {question.assignTo?.profile?.lastName})</span>
                  :
                  <span>{question.assignTo?.profile?.firstName} {question.assignTo?.profile?.lastName}</span>
                  }
                  {answer?.date && <span> le {format(new Date(answer.date), 'dd/MM/yyyy')}</span>}
                </p>
              }
              {!isReadOnly ?
                <>
                  <HtmlEditor
                    defaultValue={answerDefault || null}
                    onChange={(val) => setAnswerHtml(val)}
                  />
                  {sendValidation().isLate || answer?.justification ? <div>
                    <Textarea
                        label={"Justification de la réponse tardive"}
                        name={"justification"}
                        value={justification}
                        onChange={(val) =>  setJustification(val)}
                        withErrors={true}
                        error={errors.justification && errors.justification.message}
                      />
                    </div>
                    : null
                  }
                  <div className={styles.files}>
                    {answer?.files && answer.files.map((f ,i ) => (
                      <div key={'file' + i} className={styles.file}>
                        <button
                          className={styles.link}
                          type={"button"}
                          onClick={() => getFileAction(dispatch , f._id )}
                          >
                          {f.name}
                        </button>
                        <button
                          className={styles.delete}
                          type={"button"}
                          onClick={() => deleteFile(f)}
                          >
                          <MdDelete size={20} />
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className={styles['btn-answer-container']}>
                    <div className={styles['left']}>
                      <Button
                        primary
                        onClick={() => setModaleIsOpen("document")}
                        type="button"
                        >
                        Ajouter un fichier
                      </Button>
                      <Button
                        primary
                        className={styles['btn-type']}
                        onClick={() => setModaleIsOpen("answerType")}
                        type="button"
                        >
                        Ajouter une réponse type
                      </Button>
                    </div>

                    <div
                      onMouseOver={() => setDisplayTooltip(true)}
                      onMouseOut={() => setDisplayTooltip(false)}
                      className={styles['right']}>
                      <p className={`${styles['tooltip']} ${displayTooltip ? styles['is-active']:''}`}>
                        {sendValidation().message}
                      </p>
                      {/* <Comment validators={answer?.validators} /> */}
                      <Button
                        primary
                        isDisabled={sendValidation().isLate && !justification}
                        type="submit"
                        >
                        Enregistrer
                      </Button>
                      <Button
                        success
                        isDisabled={(question?.status === 'submitted' && !isValid) || sendValidation().bool || answerIsLoading}
                        isLoading={answerIsLoading}
                        className={`${styles.success}`}
                        onClick={() => setModaleIsOpen("submit")}
                        type="button"
                        >
                        {answerIsLoading && 'Envoi en cours ...'} 
                        {question?.status === 'new' && 'Soumettre la réponse'}
                        {question?.status === 'pending' && 'Soumettre la réponse'}
                        {question?.status === 'submitted' && !isValid && 'La réponse est en cours de validation'}
                        {question?.status === 'submitted' && isValid && 'Envoyer la réponse'}
                        {question?.status === 'resolved' && 'Réponse déjà envoyée'}
                      </Button>
                    </div>
                  </div>
                </>
                :
                <>
                  {answer?.answer &&
                    <div dangerouslySetInnerHTML={{__html: answer?.answer}}></div>
                  }
                  <div className={styles.files}>
                    {answer?.files && answer.files.map((f ,i ) => (
                      <div key={'file' + i} className={styles.file}>
                        <button
                          className={styles.link}
                          type={"button"}
                          onClick={() => getFileAction(dispatch , f._id )}
                          >
                          {f.name}
                        </button>
                      </div>
                    ))}
                  </div>  
                </>
              }
            </form>
            
          </main>
        </div>
      }
    </div>
    <Modale
      isOpen={modaleIsOpen}
      hideClose={modaleIsOpen === "submit" ? true : false }
      modaleToggle={() => setModaleIsOpen()}
      >
      {modaleIsOpen === "change-author" && <ChangeAuthorModale question={question} closeModale={() => setModaleIsOpen()}/> }
      {modaleIsOpen === "edit" && <EditModale question={question} closeModale={() => setModaleIsOpen()}/> }
      {modaleIsOpen === "redirect" && <RedirectModale question={question} closeModale={() => setModaleIsOpen()}/> }
      {modaleIsOpen === "author" && <EditAuthorModale question={question} closeModale={() => setModaleIsOpen()}/> }
      {modaleIsOpen === "contact" && <EditContactModale question={question} closeModale={() => setModaleIsOpen()}/> }
      {modaleIsOpen === "document" && <DocumentModale 
        files={answer?.files ? answer.files : []}
        HandleChangeAnswer={HandleChangeAnswer}
        setModaleIsActive={() => setModaleIsOpen()}
      />}
      {modaleIsOpen === "proof" && <DocumentModale files={question?.proof ? question.proof : []} HandleChangeQuestion={HandleChangeQuestion} setModaleIsActive={() => setModaleIsOpen()} />}
      {modaleIsOpen === "submit" && <SubmitModale answer={answer} question={question} closeModale={() => setModaleIsOpen()}/> }
      {modaleIsOpen === "answerType" && <AnswerTypeModale submitAnwserType={submitAnwserType} closeModale={() => setModaleIsOpen()} />}
    </Modale>
    </>
  )
}
