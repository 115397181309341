import React, { useEffect , useCallback } from "react";
import { useGlobalContext } from '../context/GlobalContext';
import { Switch , Route , useLocation , useHistory } from "react-router-dom";
import { useTransition, animated } from 'react-spring';
import { setVhUnit } from '../utils/utils';

// AUTH ACTION
import { getUserAuthAction } from "../actions/authActions";
import { getQuestionListsAction } from '../actions/questionsActions';

//PARTIALS
import Toaster from "../components/lib/Toaster/Toaster";
import Nav from "../components/partials/Nav/Nav";
// import Filters from '../partials/Filters/Filters';


//PAGES
import SwitchPage from "./Auth/SwitchPage";
import LoginPage from "./Auth/LoginPage";
import QuestionsListPage from "./Questions/QuestionsList/QuestionsListPage";
import AskInitialPage from "./AskQuestion/AskInitialPage";
import AskQuestionPage from "./AskQuestion/AskQuestionPage";
import AskAuthorPage from "./AskQuestion/AskAuthorPage";
import AskAbstractPage from "./AskQuestion/AskAbstractPage";
import AskValidPage from "./AskQuestion/AskValidPage";
import QuestionPage from "./Questions/QuestionPage/QuestionPage";
// import AnswersListPage from "./Answers/AnswersList/AnswersListPage";
import EditAnswserType from "./AnswersType/EditAnswserType/EditAnswserType";
import AnswersTypeListPage from "./AnswersType/AnswersTypeList/AnswersTypeListPage";
import ValidationsPage from "./Validation/ValidationsPage";
import AccountPage from "./Account/AccountPage";
import Dashboard from "./Dashboard/DashboardPage";
import Filters from "../components/partials/Filters/Filters";
import AnswerTypeFilters from "../components/partials/SearchAnswerType/Filters";
import AnswersFilters from "../components/partials/AnswersFilters/AnswersFilters";
import SatisfactionPage from "./Satifaction/SatisfactionPage";
import DataPolicy from "../pages/Legal/DataPolicy";
// import Legal from "../pages/Legal/Legal";
import Transparency from "../pages/Legal/Transparency";
import Modale from "../components/lib/Modale/Modale";
import CnilModale from "../components/partials/CnilModale/CnilModale";
import ChangesPage from "./ChangesPage/ChangesPage";
import ChangesFilters from "../components/partials/ChangesFilters/ChangesFilters";

export default function GlobalRouter() {

  const [ context, dispatch ] = useGlobalContext();

  const location = useLocation();
  const history = useHistory();

  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 1, transform: 'translate3d(-100%, 0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(100%, 0 ,0)'},
  });

  const askTransitions = useTransition(location, location => location.pathname, {
    from: { opacity: 1, transform: 'translate3d(100%, 0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 1, transform: 'translate3d(-100%, 0 ,0)'},
  });

  const transitionsStyle = location.pathname.split("/")[1] === 'ask' ? askTransitions : transitions

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
    getQuestionListsAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    getUser();
    setVhUnit();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(context.auth.error === 401) {
      logout();
    }
  // eslint-disable-next-line 
  }, [context.auth.error]);

  function logout() {
    history.push("/login");
    localStorage.removeItem('token');
    dispatch({
      type : 'IS_AUTH',
      payload : false
    });
    dispatch({
      type : 'USER_AUTH',
      payload : null
    });
  };

  return (
    <>
      <Nav
        logout={logout}
        />
      {location.pathname === "/validations" && <AnswersFilters />}
      {location.pathname === "/changes" && <ChangesFilters />}
      {location.pathname === "/question" || location.pathname === "/question-assign" || location.pathname === "/question-member" ? <Filters isAnswers={location.pathname === "/answers-types" ? true : false } /> : null }
      {location.pathname === "/answers-types" ? <AnswerTypeFilters isAnswers={true} /> : null }
      {transitionsStyle.map(({ item: location, props, key }) => (
        <animated.div key={key} style={props}>

          <Switch location={location}>

            <Route exact path="/" render={
              () => <SwitchPage />
            }/>

            <Route exact path="/login" render={
              () =>  <LoginPage />
            }/>

            <Route exact path="/dashboard" render={() => 
              <Dashboard />
            }/>

            <Route exact path="/question" render={() => 
              <QuestionsListPage />
            }/>

            <Route exact path="/question-assign" render={() => 
              <QuestionsListPage isAssign={true}/>
            }/>

            <Route exact path="/question-member" render={() => 
              <QuestionsListPage isMember={true} isAssign={false} />
            }/>

            <Route exact path="/changes" render={() => 
              <ChangesPage />
            }/>

            <Route exact path="/question-assign/:questionId" render={() => 
              <QuestionPage isAssign={true}/>
            }/> 

            <Route exact path="/question/:questionId" render={() => 
              <QuestionPage />
            }/>

            <Route exact path="/ask" render={() => 
              <AskInitialPage />
            }/>

            <Route exact path="/ask/question" render={() => 
              <AskQuestionPage />
            }/>

            <Route exact path="/ask/author" render={() => 
              <AskAuthorPage />
            }/>

            <Route exact path="/ask/abstract" render={() => 
              <AskAbstractPage/>
            }/>

            <Route exact path="/ask/valid" render={() => 
              <AskValidPage />
            }/>

            <Route exact path="/satisfaction/:id" render={() => 
              <SatisfactionPage/>
            }/>

            <Route exact path="/answers-types" render={() => 
              <AnswersTypeListPage />
            }/>

            <Route exact path="/answers-types/create" render={() => 
              <EditAnswserType />
            }/>

            <Route exact path="/answers-types/:answerId" render={() => 
              <EditAnswserType />
            }/>

            <Route exact path="/validations" render={() => 
              <ValidationsPage />
            }/>

            <Route exact path="/account" render={() =>
              <AccountPage />
            }/>


            <Route exact path="/data-policy" render={() =>
              <DataPolicy />
            }/>

            <Route exact path="/transparency" render={() =>
              <Transparency  />
            }/>

          </Switch>
        </animated.div>
      ))}
      <Toaster toast={context.auth.toast} dispatch={dispatch}/>
      {context.auth?.userAuth &&
        <Modale
          isOpen={!context.auth?.userAuth?.consentDate}
          hideClose={true}
        >
          <CnilModale />
        </Modale>
      }
    </>
  )
}