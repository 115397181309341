import React from 'react';
import { useHistory } from 'react-router-dom';

//styles & images
import styles from './Ask.module.scss';
import illu from '../../assets/images/ask1.svg';

//components
import Button from '../../components/lib/Button/Button';

//Global state
import { useGlobalContext } from '../../context/GlobalContext';

export default function AskInitialPage() {

  const history = useHistory();
  const dispatch = useGlobalContext()[1];

  function askQuestion() {
    dispatch({
      type: 'SET_ASK_FORM',
      payload: {}
    });
    dispatch({
      type: 'MESSAGE_QUESTION',
      payload: null
    });
    history.push("/ask/question");
  }

  return (
    <div className={`page-container ${styles.container}`}>
      <div className="content">
        <div className={styles.row}>
          <div className={styles.col}>
            <h2>Bienvenue sur <span className="text-primary">Medocta</span></h2>
            {/* <p className={`bold ${styles.intro}`}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
            </p> */}

            <p>
              Medocta est une plateforme permettant de reporter les différentes questions ou demandes d’information médicale sur l’utilisation d’un produit Octapharma dans le cadre de son AMM ou liée à une utilisation hors AMM, ainsi que toute demande d’information scientifique ou médicale sur les dernières données cliniques disponibles. Afin de pouvoir être le plus exhaustif possible dans les réponses fournies, des tags sont à votre disposition pour vous permettre de répertorier au mieux vos différentes questions auprès de vos interlocuteurs Octapharma, en fonction du médicament utilisé, du contexte pathologique et de la situation clinique associée.
            </p>
            
            <Button
              primary
              onClick={() => askQuestion()}
              >
              Poser votre question
            </Button>

          </div>
          <div className={`${styles.col} ${styles.illu}`}>
            <img src={illu} alt="illu" />
          </div>
        </div>

        <footer>
          {/* <Link to={'/ask'}>En savoir plus sur la confidentialité et le traitement de mes données</Link> */}
        </footer>

      </div>
    </div>
  )
}
