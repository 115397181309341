import React , { useCallback , useEffect, useRef, useState } from 'react';

//Style
import styles from './ChangesPage.module.scss';

//golbal context
import { GET_ANSWER, GET_QUESTION, SET_CHANGES_FILTERS, SET_CHANGES_SCROLL_Y } from '../../actions/types';
import { useGlobalContext } from '../../context/GlobalContext';
import { getNotifsAction } from '../../actions/authActions';
import { postChangesFilteredAction } from '../../actions/changesActions';
import SortButton from '../../components/lib/SortButton/SortButton';
import { format } from 'date-fns';
import { FaMinus, FaPlus } from 'react-icons/fa';

const initSortState = {
  action: true,
  name: true,
  user: true,
  createdAt: true,
}

const translation = {
  modification: "Modification",
  creation: "Création",
}


const ChangeRow = ({ change }) => {
  const changeRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent ] = useState([]);
  const height = changeRef.current?.getBoundingClientRect()?.height;

  function getPayloadContent() {
    const data = [];
    const payload = JSON.parse(change.payload);
    if (payload?.date) {
      data.push({
        label: "Date",
        value: format(new Date(payload.date), "dd/MM/yyyy"),
      });
    }
    if (payload?.answer) {
      data.push({
        label: "Réponse",
        text: payload.answer,
      });
    }
    if (payload?.validators) {
      console.log(payload.validators)
      data.push({
        label: "Validations",
        value: payload.validators.filter((d) => d.isValid).length,
      });
    }
    if (payload?.mode) {
      data.push({
        label: "Mode de question",
        value: payload?.mode.map((m) => {
          if(m === "written") return "Écrite"
          if(m === "oral") return "Orale"
          return m;
        }).join(', '),
      });
    };
    if (payload?.justification) {
      data.push({
        label: "Justification",
        value: payload?.justification || " ",
      });
    };
    setContent(data);
  }

  useEffect(() => {
    getPayloadContent()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change?.payload]);

  return (
    <div className={styles.change}>
      <div className={styles.row}>
        <div className={`${styles.col}`}>{change?.name}</div>
        <div className={`${styles.col}`}>{change?.createdAt && format(new Date(change?.createdAt), "dd/MM/yyyy" )}</div>
        <div className={`${styles.col}`}>{translation?.hasOwnProperty(change?.action) && translation[change?.action]}</div>
        <div className={`${styles.col}`}>{change?.user.profile?.firstName} {change?.user.profile?.lastName}</div>
        <button
          type="button"
          className={styles.toggle}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <FaMinus /> : <FaPlus />}
        </button>
      </div>
      <div className={styles.panel} style={{ height: isOpen ? height + 20 : 0 }}>
        <div ref={changeRef}>
          {content.map((c) => <div key={change?._id + c.label} className={styles.field}>
          
            {c.value &&
            <p>
              <span className={styles.label}>{c.label} : </span>
              <span>{c.value} </span>
            </p>
            }
            {c.text && <>
              <span className={styles.label}>{c.label} : </span>
              <div dangerouslySetInnerHTML={{ __html: c.text }} />
            </>}
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default function ChangesPage() {

  const [ context, dispatch ] = useGlobalContext();
  const { changesList, filters, scrollY, count } = context.changes;

  const containerRef = useRef(null);
  const scrollRef = useRef(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ sortByReverse , setSortByReverse ] = useState(initSortState);

  const initData = useCallback(() => {
    getNotifsAction(dispatch , "Medocta" );
    if (scrollY) {
      containerRef.current.scrollTo({
        top: scrollY,
        left: 0,
      });
    }
    dispatch({
      type: GET_QUESTION,
      payload: null
    });
    dispatch({
      type:GET_ANSWER,
      payload: null
    });
  }, [dispatch , scrollY]);

  function handleScroll(e) {
    dispatch({
      type: SET_CHANGES_SCROLL_Y,
      payload: e.target.scrollTop
    });
    if (!isLoading && count > changesList.length && scrollRef?.current.offsetHeight + e.target.scrollTop + 2 > scrollRef?.current.scrollHeight ) {
      dispatch({
        type: SET_CHANGES_FILTERS,
        payload: {
          ...filters,
          page: filters.page + 1,
        }
      });
    }
  }

  function sortList(type , isReverse ) {
    setSortByReverse({...initSortState , [type]: isReverse });
    dispatch({
      type: SET_CHANGES_FILTERS,
      payload: {
        ...filters,
        page: 1,
        orderBy: {
          key: type,
          order: isReverse ? 'desc' : 'asc'
        }
      }
    });
  };

  function handleChangeFilters(obj = {}) {
    dispatch({
      type: SET_CHANGES_FILTERS,
      payload: {
        ...filters,
        ...obj,
        page: 1,
      }
    });
  }

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    postChangesFilteredAction(dispatch, filters );
    setIsLoading(true);
    // eslint-disable-next-line
  },[filters.orderBy , filters ]);

  useEffect(() => {
    setIsLoading(false);
  }, [changesList]);

  return (
    <div className={`page-container ${styles.container}`}>
       <div className={`content`}>
         <div className={styles.period}>
          <input
            type='date'
            value={filters.startDate}
            onChange={(e) => handleChangeFilters({
              startDate: e.target.value,
            })}
          />
          <input
            type='date'
            value={filters.endDate}
            onChange={(e) => handleChangeFilters({
              endDate: e.target.value,
            })}
          />
          </div>
       </div>
      <div className="content">
        <div className={`${styles.row} ${styles.label}`}>
          <SortButton
            name={"Code"}
            action={() => sortList('name' , !sortByReverse.name )}
            state={sortByReverse.name}
            className={`${styles.col}`}
          />
          <SortButton
            name={"Date"}
            action={() => sortList('createdAt' , !sortByReverse.createdAt )}
            state={sortByReverse.createdAt}
            className={`${styles.col}`}
          />
          <SortButton
            name={"Action"}
            action={() => sortList('action' , !sortByReverse.action )}
            state={sortByReverse.action}
            className={`${styles.col}`}
          />
          <SortButton
            name={"Auteur"}
            action={() => sortList('user' , !sortByReverse.user )} state={sortByReverse.user} className={`${styles.col}`}/>
        </div>
      </div>
      <div
        ref={containerRef}
        onScroll={(e) => handleScroll(e)}
        className={styles.list}>
        <div className="content" ref={scrollRef}>
          {changesList?.map((change) => (
            <ChangeRow key={change?._id} change={change} />
          ))}
        </div>
      </div>
    </div>
  )
}
