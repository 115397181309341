import React, { useState } from 'react';
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import { MdRadioButtonChecked , MdRadioButtonUnchecked } from "react-icons/md";

export default function FilterList({ name , styles , list , activeList , isMulti = true, setActiveList , isSearch = true }) {

  const [ listIsOpen , setListIsOpen  ] = useState(false);
  const [search , setSearch] = useState("");

  function handleChangeActiveList(id) {
    let newList = [...activeList];
    if (activeList.find(d => d === id )) {
      newList = activeList.filter(item => item !== id);
    } else {
      newList.push(id)
    }
    if (!isMulti) {
      setActiveList(id);
    } else {
      setActiveList(newList);
    }
  }

  return (
    <>
      <p>{name} {isMulti && activeList.length > 0 && <span>({activeList.length})</span>} </p>
      <button
        onClick={() => setListIsOpen(!listIsOpen)}
        >
        {listIsOpen ? <BiMinus size={20} color={"#FFFFFF"}/> : <BsPlus size={20} color={"#FFFFFF"}/>}
      </button>
      {listIsOpen &&
        <div className={styles.list}>
        {isSearch &&
          <div className={styles.search}>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        }
        {list?.filter((item) => item.label?.toLowerCase().includes(search.toLowerCase())).map((item , i) => {
          let isActive = activeList.find(d => d === item.value );
          return (
            <div key={i}
              onClick={() => handleChangeActiveList(item.value)}
              className={`${styles.item} ${isActive ? `${styles.isActive}` : ""}`}>
              <div className={styles.option}>
                {isActive ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#FFFFFF"}/> }
                <p>{item.label}</p> 
              </div>
            </div>
          )
        })}
      </div>
      }
    </>
  )
}
