export const  //AUTH ACTIONS
              IS_AUTH =  'IS_AUTH',
              ERROR_AUTH = 'ERROR_AUTH',
              ERROR_LOGIN = 'ERROR_LOGIN',
              USER_AUTH = 'USER_AUTH',
              FORGOT_PASSWORD = 'FORGOT_PASSWORD',
              RESET_PASSWORD ='RESET_PASSWORD',
              GET_LIST= 'GET_LIST',
              LOGIN = 'LOGIN',
              PUT_USER_AUTH = 'PUT_USER_AUTH',
              MESSAGE_AUTH = 'MESSAGE_AUTH',
              SET_TOAST = 'SET_TOAST',
              GET_NOTIFS = 'GET_NOTIFS',
              PUT_NOTIF = 'PUT_NOTIF',
              DELETE_NOTIF = 'DELETE_NOTIF',
              //QUESTIONS ACTIONS
              ERROR_QUESTION = 'ERROR_QUESTION',
              MESSAGE_QUESTION = 'MESSAGE_QUESTION',
              SET_ASK_FORM = 'SET_ASK_FORM',
              POST_QUESTION = 'POST_QUESTION',
              GET_QUESTIONS = 'GET_QUESTIONS',
              GET_QUESTION = 'GET_QUESTION',
              GET_QUESTION_LISTS = 'GET_QUESTION_LISTS',
              PUT_QUESTION ='PUT_QUESTION',
              DELETE_QUESTION = 'DELETE_QUESTION',
              GET_DASHBOARD = 'GET_DASHBOARD',
              EXPORT_DASHBOARD = 'EXPORT_DASHBOARD',
              SET_FILTERS = 'SET_FILTERS',
              SET_SCROLL_Y = 'SET_SCROLL_Y',
              FILTERED_QUESTIONS = 'FILTERED_QUESTIONS',
              GET_CONTACTS = 'GET_CONTACTS',
              POST_CONTACT = 'POST_CONTACT',
              PUT_CONTACT = 'PUT_CONTACT',
              GET_CENTERS = 'GET_CENTERS',
              GET_DRUGS = 'GET_DRUGS',
              //ANSWER ACTIONS
              MESSAGE_ANSWER = 'MESSAGE_ANSWER',
              ERROR_ANSWER = 'ERROR_ANSWER',
              ANSWER_IS_LOADING = 'ANSWER_IS_LOADING',
              POST_ANSWER = 'POST_ANSWER',
              GET_ANSWERS = "GET_ANSWERS",
              GET_ANSWER = 'GET_ANSWER',
              PUT_ANSWER = 'PUT_ANSWER',
              DELETE_ANSWER = 'DELETE_ANSWER',
              GET_ANSWERS_TYPE ='GET_ANSWERS_TYPE',
              FILTERED_ANSWERS = 'FILTERED_ANSWERS',
              EXPORT_ANSWERS = 'EXPORT_ANSWERS',
              SET_ANSWERS_FILTERS = 'SET_ANSWERS_FILTERS',
              SET_ANSWERS_TYPES_SEARCH = 'SET_ANSWERS_TYPES_SEARCH',
              SET_ANSWERS_SCROLL_Y = 'SET_ANSWERS_SCROLL_Y',
              ANSWER_SATISFACTION = 'ANSWER_SATISFACTION',
              //FILES ACTIONS
              POST_FILE =  'POST_FILE',
              DELETE_FILE = 'DELETE_FILE',
              ERROR_UPLOAD = 'ERROR_UPLOAD',
              //CHANGES ACTIONS
              MESSAGE_CHANGE = 'MESSAGE_CHANGE',
              ERROR_CHANGE = 'ERROR_CHANGE',
              FILTERED_CHANGES = 'FILTERED_CHANGES',
              SET_CHANGES_FILTERS = 'SET_CHANGES_FILTERS',
              SET_CHANGES_SCROLL_Y= 'SET_CHANGES_SCROLL_Y'; 
