import React , { useEffect, useState } from 'react';
import { Link , useHistory } from 'react-router-dom';
import { useForm , Controller } from "react-hook-form";

//styles & images
import styles from './Ask.module.scss';
import illu from '../../assets/images/ask2.svg';

//components
import CustomSelect from '../../components/lib/form/Select/CustomSelect';
import Button from '../../components/lib/Button/Button';
import Input from '../../components/lib/form/Input/Input';

//Global state
import { useGlobalContext } from '../../context/GlobalContext';
import { getCentersListAction, getContactListAction, postContactAction } from '../../actions/questionsActions';

export default function AskAuthorPage() {

  const history = useHistory();
  const [ context, dispatch ] = useGlobalContext();
  const [ newContact , setNewContact ] = useState();
  const [ newContactErrors, setNewContactErrors ] = useState({});
  const [ selectedCenter, setSelectedCenter ] = useState();

  const { askForm , centersList , contactList , contact } = context.questions

  const soucresOptions = ['Absys','Téléphone','Mail','Questionnaire'].map(s => ({ value: s , label: s }));

  const { handleSubmit, errors , watch , setValue , control  } = useForm();

  useEffect(() => {
    getContactListAction(dispatch);
    getCentersListAction(dispatch);
    if (!askForm.title) {
      history.push("/ask/question");
    }
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (selectedCenter?.value?.center) {
      handleChangeNewAuthor('center', selectedCenter?.value?.center)
    }
    // eslint-disable-next-line
  }, [selectedCenter?.value?.center]);

  useEffect(() => {
    if (contact?._id) {
      handleChangeAuthor(contact._id);
    }
    // eslint-disable-next-line
  }, [contact]);

  function handleChangeAuthor(value) {
    if (value !== 'new') {
      setValue("author", value);
      setNewContact();
    } else {
      setValue("author", null );
      setNewContact({});
    }
  }

  function handleChangeNewAuthor(key, value) {
    setNewContact({...newContact , [key]: value });
  }

  function handleSubmitContact() {
    const contactErrors = {};
    if (!newContact.lastName) {
      contactErrors.lastName =  'Le nom est requis';
    }
    if (Object.keys(contactErrors).length > 0) {
      setNewContactErrors(contactErrors);
    } else {
      postContactAction(dispatch, newContact);
    }
  }; 

  function submitAuthor(value) {
    dispatch({
      type: 'SET_ASK_FORM',
      payload: Object.assign({...askForm} , value )
    });
    dispatch({
      type: 'MESSAGE_QUESTION',
      payload: null
    });
    history.push("/ask/abstract");
  }

  return (
    <div className={`page-container ${styles.container}`}>
      <div className="content">
        <div className={styles.row}>
          <form onSubmit={handleSubmit(submitAuthor)} className={styles.col}>
            <h2>Vos informations</h2>
            <p className={styles.intro}>
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.  */}
            </p>

            <div className={styles['info-row']}>
              {contactList?.find((c) =>  c.value === watch('author'))?.value ?
                <Controller
                  name="author"
                  control={control}
                  defaultValue={watch('author')}
                  render={() =>
                    <CustomSelect
                      label="Auteur de la question"
                      value={contactList?.find((c) =>  c.value === watch('author'))}
                      options={contactList ? contactList : []}
                      withErrors={true}
                      isSearchable={true}
                      handleChange={(opt) => handleChangeAuthor(opt.value)}
                      error={errors.author && errors.author.message}
                      />
                  }
                />
                :
                <Controller
                  name="author"
                  control={control}
                  defaultValue={null}
                  render={() =>
                    <CustomSelect
                      label="Auteur de la question"
                      defaultValue={null}
                      options={contactList ? contactList : []}
                      withErrors={true}
                      isSearchable={true}
                      handleChange={(opt) => handleChangeAuthor(opt.value)}
                      error={errors.author && errors.author.message}
                      />
                  }
                />
              }
            </div>

            {newContact &&
              <>
                <div className={styles['info-row']}>

                  <div className={styles['info-col']}>
                    <Input
                      label={"Nom"}
                      name={"lastName"}
                      placehloder={"Nom"}
                      defaultValue={null}
                      onChange={(val) => handleChangeNewAuthor('lastName', val )}
                      withErrors={true}
                      error={newContactErrors?.lastName}
                    />
                  </div>

                  <div className={styles['info-col']}>
                    <Input
                      label={"Prénom"}
                      name={"firstName"}
                      placehloder={"Prénom"}
                      defaultValue={null}
                      onChange={(val) => handleChangeNewAuthor('firstName', val )}
                      withErrors={false}
                      error={newContactErrors?.firstName}
                    />
                  </div>

                </div>

                  <div className={styles['info-row']}>

                    <div className={styles['info-col']}>
                      <Input
                        label={"Qualification"}
                        placehloder={"Médecin, pharmacien..."}
                        name={"qualification"}
                        defaultValue={null}
                        onChange={(val) => handleChangeNewAuthor('qualification', val )}
                        withErrors={true}
                        error={newContactErrors?.qualification}
                      />
                    </div>

                  </div>

                  <div className={styles['info-row']}>

                    <div className={styles['info-col']}>
                      <Input
                        label={"Téléphone"}
                        name={"tel"}
                        placehloder={"00 00 00 00 00"}
                        defaultValue={null}
                        onChange={(val) => handleChangeNewAuthor('tel', val )}
                        withErrors={true}
                        error={newContactErrors?.tel}
                      />
                    </div>

                    <div className={styles['info-col']}>
                      <Input
                        label={"Mail*"}
                        name={"email"}
                        placehloder={"adresse@gmail.com"}
                        onChange={(val) => handleChangeNewAuthor('email', val )}
                        withErrors={true}
                        error={newContactErrors?.email}
                      />
                    </div>

                  </div>

                  <div className={styles['info-row']}>

                    <div className={styles['info-col']}>
                      <CustomSelect
                        label="Centre"
                        defaultValue={null}
                        options={centersList ? centersList : []}
                        isSearchable
                        withErrors={true}
                        handleChange={(val) => setSelectedCenter(val)}
                        error={newContactErrors?.center}
                      />
                    </div>

                    {selectedCenter?.value.services &&
                      <div className={styles['info-col']}>
                        <CustomSelect
                          label="Service"
                          defaultValue={null}
                          options={selectedCenter?.value.services ? [{ value: '' , label: 'pas de service' }, ...selectedCenter?.value.services.map((s) => ({ label: s.name , value: s.name }))] : []}
                          isSearchable
                          withErrors={true}
                          handleChange={(opt) => handleChangeNewAuthor('service', opt.value)}
                          error={null}
                        />
                      </div>
                    }
                  </div>
                  
                  <div className={styles['add-contact']}>
                    <Button
                      primary
                      type="button"
                      onClick={() => handleSubmitContact()}
                      className={`${styles.btn}`}
                      >
                      <p>Ajouter le contact</p>
                    </Button>
                  </div>

                </>
              }

              <div className={styles['info-row']}>

              <div className={styles['info-col']}>
                {/* <Input
                  label={"Source de la question"}
                  name={"source"}
                  placehloder={"Source de la question"}
                  defaultValue={askForm?.source }
                  register={register}
                  withErrors={true}
                  error={errors?.source?.message}
                /> */}
                <Controller
                  name="source"
                  control={control}
                  defaultValue={askForm?.source ? askForm.source : null }
                  render={() =>
                    <CustomSelect
                      label="Source de la question"
                      defaultValue={soucresOptions.find(opt => opt.value === askForm.source)}
                      options={soucresOptions}
                      withErrors={true}
                      handleChange={(opt) => setValue( 'source' , opt.value )}
                      error={errors.source && errors.source.message}
                      />
                  }
                />
              </div>

            </div>

            <p className="text-grey">Un des champs * est obligatoire pour pouvoir être recontacté</p>
                  
            <div className={styles['btn-container']}>
              <Link to={'/ask/question'}>
                <Button
                  primary
                  outline
                  className={`${styles.btn}`}
                  type="button"
                  >
                  <p>PRÉCÉDENT</p>
                </Button>
              </Link>
              <Button
                primary
                type="submit"
                className={`${styles.btn} ${watch('author') && watch('source') ? "" : styles['disabled']}`}
                >
                <p>VALIDER</p>
              </Button>
            </div>

          </form>
          <div className={`${styles.col} ${styles.illu}`}>
            <img src={illu} alt="illu" />
          </div>
        </div>

        <footer>
          {/* <Link to={'/ask'}>En savoir plus sur la confidentialité et le traitement de mes données</Link> */}
        </footer>

      </div>
    </div>
  )
}
