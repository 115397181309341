import React, { useState , useEffect , useCallback } from 'react';
import { BsFilterLeft } from "react-icons/bs";
import { RiArrowLeftSLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";

import { useGlobalContext } from '../../../context/GlobalContext';
import { getDrugsListAction } from '../../../actions/questionsActions';
import { sortByKey } from '../../../utils/utils';
import FilterList from '../Filters/partials/FilterList';

import styles from './filters.module.scss';
import { SET_FILTERS } from '../../../actions/types';

export default function Filters({ isDisabled , isAnswers }) {

  const [ context, dispatch ] = useGlobalContext();

  const { lists  ,filters } = context.questions;
  const types = [{ value: "written" , label: "Ecrit" }, { value: "oral" , label: "Oral" }]

  const getDrugs = useCallback(() => {
    getDrugsListAction(dispatch);
  },[dispatch]);

  const [ filterIsOpen , setFiltersIsOpen ] = useState(false);
  const [ search , setSearch ] = useState();

  function setFilters(key , value ) {
    dispatch({
      type: SET_FILTERS,
      payload: {
        ...filters,
        page: 1,
        [key]: value
      }
    });
  }

  useEffect(() => {
    if (isDisabled) {
      setFiltersIsOpen(false);
    }
  }, [isDisabled]);

  useEffect(() => {
    getDrugs();
    // eslint-disable-next-line 
  },[]);

  function getClassNames() {
    let classNames = styles.filters
    if (isDisabled) {
      classNames += ` ${styles.disabled}`;
      return classNames
    }
    if (filterIsOpen) {
      classNames += ` ${styles.isOpen}`
    }
    if (isAnswers) {
      classNames += ` ${styles.answers}`
    }
    return classNames
  }

  return (
    <div className={getClassNames()}>
      <button className={styles["btn-filters"]} onClick={() => setFiltersIsOpen(!filterIsOpen)}>
      {filterIsOpen? <RiArrowLeftSLine size={28} color={"#FFFFFF"}/>  : <BsFilterLeft size={30} color={"#FFFFFF"}/> }
      </button>
      {filterIsOpen &&
        <>
          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ""}`}>
            <input
              value={search ? search :  "" }
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher"
              />
            <button
              className={styles["btn-search"]}
              onClick={() => setFilters('search' , search ? search : null )}>
              <BiSearch size={18} color={"#FFFFFF"}/>
            </button>
          </div>
          {!isAnswers &&
            <>
              <div className={`${styles.filter} ${filterIsOpen ? `${styles.filter} ${styles.isOpen} ${styles.border}` : ""}`}>
                {lists && <FilterList
                  name={"Tags"}
                  styles={styles}
                  list={lists?.tags ? lists?.tags.sort().filter(t => t? true : false ).map(t => ({ value: t , label : t })) : []}
                  activeList={filters && filters.tags ? filters.tags : []}
                  setActiveList={(val) => setFilters( 'tags' , val )}
                  isSearch={true}
                  />
                }
              </div>
              <div className={`${styles.filter} ${filterIsOpen ? styles.isOpen : ""} ${styles.border}`}>
                {lists && <FilterList
                  name={"Produits"}
                  styles={styles}
                  list={lists?.drugs ? sortByKey(lists?.drugs , 'name')?.map((d) => ({ value: d._id , label: d.name })) : []}
                  activeList={filters && filters.drugs ? filters.drugs : []}
                  setActiveList={(val) => setFilters( 'drugs' , val )}
                  />
                }
              </div>
              <div className={`${styles.filter} ${filterIsOpen ? styles.isOpen : ""} ${styles.border}`}>
                {lists && <FilterList
                  name={"Attribué à"}
                  styles={styles}
                  list={lists?.users ? lists.users?.map((d) => ({ value: d._id , label: `${d.profile.firstName} ${d.profile.lastName}` })) : []}
                  activeList={filters && filters.assignTo ? filters.assignTo : []}
                  setActiveList={(val) => setFilters( 'assignTo' , val )}
                  />
                }
              </div>
              <div className={`${styles.filter} ${filterIsOpen ? styles.isOpen : ""} ${styles.border}`}>
                {lists && <FilterList
                  name={"Types"}
                  styles={styles}
                  list={types}
                  activeList={filters && filters.types ? filters.types : []}
                  setActiveList={(val) => setFilters( 'types' , val )}
                  />
                }
              </div>
              <div className={`${styles.filter} ${filterIsOpen ? styles.isOpen : ""} `}>
                {lists && <FilterList
                  name={"Centres"}
                  styles={styles}
                  list={lists?.centers? lists?.centers?.map((c) => ({ value: c._id , label: `${c.name} - ${c.location.city}` })) : []}
                  activeList={filters?.centers ? filters.centers : []}
                  setActiveList={(val) => setFilters( 'centers' , val )}
                  />
                }
              </div>
            </>
          }
          
        </>
      }
    </div>
  )
}
