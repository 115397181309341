import React from 'react'
import { useForm , Controller } from "react-hook-form";

//Components
import CustomSelect from '../../../../components/lib/form/Select/CustomSelect';
import Button from '../../../../components/lib/Button/Button';

//Global state
import { useGlobalContext } from '../../../../context/GlobalContext';
import { getContactListAction, putQuestionAction } from '../../../../actions/questionsActions';

//Style
import styles from './ChangeAuthorModale.module.scss';
import { useEffect } from 'react';

export default function ChangeAuthorModale({ question , closeModale }) {

  const { handleSubmit, control, errors , setValue } = useForm();

  const [ context , dispatch ] = useGlobalContext();

  const { contactList } = context.questions;
  const contacts = contactList?.filter((u) => u.value !== 'new') ; 
  const selectedUser = question.author && contacts?.find(u => u.value === question.author._id );
  
  useEffect(() => {
    getContactListAction(dispatch);
    // eslint-disable-next-line
  },[]);

  function submitQuestion(value) {
    putQuestionAction(dispatch , question._id , value);
    closeModale();
  }

  return (
    <form onSubmit={handleSubmit(submitQuestion)} className={styles.container}>
      <h2>Changer l'auteur la question</h2>
      <Controller
        name="author"
        control={control}
        defaultValue={selectedUser?.value ? selectedUser.value : null }
        render={() =>
          <CustomSelect
            label="Auteur"
            defaultValue={selectedUser ? selectedUser : null }
            options={contacts ? contacts : []}
            isSearchable
            withErrors={true}
            handleChange={(opt) => setValue( 'author' , opt.value )}
            error={errors.author && errors.author.message}
            />
        }
      />

      <Button
        type="submit"
        primary
        >
        Valider
      </Button>
    </form>
  )
}
