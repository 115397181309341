import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm , Controller } from "react-hook-form";

//styles & images
import styles from './Ask.module.scss';
import illu from '../../assets/images/ask2.svg';

//components
import Button from '../../components/lib/Button/Button';
import Input from '../../components/lib/form/Input/Input';
import Textarea from '../../components/lib/form/Textarea/Textarea';
import TagsSelect from '../../components/partials/TagsSelect/TagsSelect';
import CustomSelect from '../../components/lib/form/Select/CustomSelect';

//Global state
import { useGlobalContext } from '../../context/GlobalContext';
import { getDrugsListAction } from '../../actions/questionsActions';


export default function AskQuestionPage() {

  const history = useHistory();
  const [ context, dispatch ] = useGlobalContext();

  const { askForm , lists } = context.questions

  const drugs = lists?.drugs?.sort((a,b) => a.name.localeCompare(b.name) ).map(d => ({ value: d._id , label: d.name }));
  const users = lists?.users?.map(user => ({ label: `${user.profile.firstName} ${user.profile.lastName} / ${user.division?.join(' - ')}`, value: user._id })); 

  const { handleSubmit, register, errors , control , watch , setValue } = useForm();

  const getDrugs = useCallback(() => {
    getDrugsListAction(dispatch);
  },[dispatch])

  function submitQuestion(value) {
    value.date = new Date();
    dispatch({
      type: 'SET_ASK_FORM',
      payload: Object.assign({...askForm} , value )
    });
    history.push("/ask/author");
  }

  useEffect(() => {
    getDrugs();
    // eslint-disable-next-line 
  },[]);

  return (
    <div className={`page-container ${styles.container}`}>
      <div className="content">
        <div className={styles.row}>
          <form onSubmit={handleSubmit(submitQuestion)} className={styles.col}>
            <h2>Votre question</h2>
            <p className={styles.intro}>
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.  */}
            </p>

            <Input
              label={"Titre"}
              name={"title"}
              defaultValue={askForm?.title}
              register={register({
                required: "le titre est obligatoire",
              })}
              withErrors={true}
              error={errors.title && errors.title.message}
            />

            <Textarea
              label={"Votre message"}
              name={"question"}
              defaultValue={askForm?.question}
              register={register}
              withErrors={true}
              error={errors.question && errors.question.message}
            />

            <Controller
              name="tags"
              control={control}
              defaultValue={askForm?.tags ? askForm.tags : null }
              render={() =>
                <TagsSelect
                  label="Tags"
                  defaultValue={askForm?.tags ? askForm.tags.map(t => ({ value: t , label: t })) : null }
                  withErrors={true}
                  onChange={(val) => setValue( 'tags' , val )}
                  error={errors.tags && errors.tags.message}
                  />
              }
            />

            <Controller
              name="assignTo"
              control={control}
              defaultValue={askForm?.assignTo ? askForm?.assignTo : null }
              render={() =>
                <CustomSelect
                  label="Assigner la question"
                  defaultValue={askForm?.assignTo && users?.find(u => u.value === askForm.assignTo )}
                  options={users ? users : []}
                  isSearchable
                  withErrors={true}
                  handleChange={(opt) => setValue( 'assignTo' , opt.value )}
                  error={errors.assignTo && errors.assignTo.message}
                  />
              }
            />
            <Controller
              name="drug"
              control={control}
              defaultValue={askForm?.drug ? askForm?.drug: []}
              render={() =>
                <CustomSelect
                  label="Produit"
                  defaultValue={askForm?.drug ? askForm?.drug : []}
                  options={drugs ? drugs : []}
                  isSearchable
                  isMulti={true}
                  withErrors={true}
                  handleChange={(array) => setValue( 'drug' , array )}
                  error={errors.drug && errors.drug.message}
                  />
              }
            />
            
            <Button
              primary
              type="submit"
              className={`${styles.btn} ${watch('title') ? "" : styles['disabled']}`}
              >
              <p>VALIDER</p>
            </Button>

          </form>
          <div className={`${styles.col} ${styles.illu}`}>
            <img src={illu} alt="illu" />
          </div>
        </div>

        <footer>
          {/* <Link to={'/ask'}>En savoir plus sur la confidentialité et le traitement de mes données</Link> */}
        </footer>

      </div>
    </div>
  )
}
