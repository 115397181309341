const dark = '#192243'

export const primaryStyle = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    marginTop: 4,
    marginBottom: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    minHeight: 44,
    fontSize: 14,
    top: '48%',
    padding: "0px 8px"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#192243",
    top: '48%'
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 44,
  }),
  indicatorSeparator:(provided, state) => ({
    ...provided,
    display: 'none'
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#FFA56D",
  }),
  control: (provided, state) => {
     return ({
    ...provided,
    backgroundColor: '#FFFFFF',
    minHeight: 25,
    borderRadius: 3,
    boxShadow: "0px 10px 28px rgb(224, 224, 224)",
    border: state.isFocused || state.isSelected ? "1px solid #FFFFFF" : "1px solid #FFFFFF",
    '&:hover': {
      border: state.isFocused || state.isSelected ? "1px solid #FFFFFF" : "1px solid #FFFFFF",
    }
  })},
  menu: (provided, state) => ({
    ...provided,
    marginTop: 1,
    borderRadius: 3,
  }),
  menuList: (provided, state) => ({
    ...provided,
    border: "1px solid #FFFFFF",
    maxHeight: 160,
    borderRadius: 3,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: state.isFocused || state.isDisabled || state.isSelected ? dark : dark,
    backgroundColor: state.isFocused ? '#FFA56D': "#FFFFFF"
  })
}

export const secondaryStyle = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    marginTop: 4,
    marginBottom: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 32,
    fontSize: 14,
    top: '48%',
    padding: "8px 4px"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#192243",
    top: '48%'
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 32,
  }),
  indicatorSeparator:(provided, state) => ({
    ...provided,
    display: 'none'
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#FFA56D",
  }),
  control: (provided, state) => {
     return ({
    ...provided,
    backgroundColor: '#FFFFFF',
    minHeight: 25,
    borderRadius: 3,
    boxShadow: "0px 10px 28px rgb(224, 224, 224)",
    border: state.isFocused || state.isSelected ? "1px solid #FFFFFF" : "1px solid #FFFFFF",
    '&:hover': {
      border: state.isFocused || state.isSelected ? "1px solid #FFFFFF" : "1px solid #FFFFFF",
    }
  })},
  menu: (provided, state) => ({
    ...provided,
    marginTop: 1,
    borderRadius: 3,
  }),
  menuList: (provided, state) => ({
    ...provided,
    border: "1px solid #FFFFFF",
    maxHeight: 160,
    borderRadius: 3,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: state.isFocused || state.isDisabled || state.isSelected ? dark : dark,
    backgroundColor: state.isFocused ? 'rgba(0, 0, 0 , 0.1)': "#FFFFFF"
  })
}