import * as _ from 'lodash';
import {
  ERROR_QUESTION,
  MESSAGE_QUESTION,
  SET_ASK_FORM,
  POST_QUESTION,
  GET_QUESTIONS,
  GET_QUESTION,
  GET_QUESTION_LISTS,
  PUT_QUESTION,
  DELETE_QUESTION,
  GET_DASHBOARD,
  SET_FILTERS,
  GET_CONTACTS,
  FILTERED_QUESTIONS,
  GET_CENTERS,
  GET_DRUGS,
  SET_SCROLL_Y,
  POST_CONTACT,
  PUT_CONTACT,
  ANSWER_IS_LOADING,
} from "../actions/types";

const initialState = {
  error: null,
  message: null,
  askForm: {},
  question: null,
  lists: {},
  contactList: null,
  centersList: null,
  contact: null,
  answerIsLoading: false,
  filters :{
    search: null,
    tags:[],
    centers:[],
    drugs:[],
    types:[],
    page:1,
    orderBy: { key: "createdAt", order: "desc" },
    number:15
  },
  scrollY: 0,
  dashboard: null,
  count: 0,
  filteredQuestions: null
};

export default function questionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SCROLL_Y:
      return {...state , scrollY: action.payload}
    case SET_FILTERS:
      return {...state , filters: action.payload}
    case FILTERED_QUESTIONS:
      let questions = [];
      if (state.filters.page ===  1) {
        questions = action.payload.questions
      } else {
        questions = [...state.filteredQuestions , ...action.payload.questions ]
      }
      return {...state , filteredQuestions: _.uniqBy(questions, '_id') , count: action.payload.count }
    case GET_DASHBOARD:
      return {...state , dashboard: action.payload }
    case POST_QUESTION:
      return {...state , message: action.payload.message }
    case GET_QUESTION_LISTS:
      return {...state , lists: {...state.lists, ...action.payload}}
    case PUT_QUESTION:
      let questionsPut = state.filteredQuestions || [];
      let index = questionsPut?.findIndex((q) => q._id === action.payload._id);
      if (index >= 0) {
        questionsPut[index] = action.payload;
      }
      return {...state , question: action.payload , filteredQuestions: questionsPut}
    case GET_QUESTION:
      return {...state , question: action.payload}
    case DELETE_QUESTION:
      return {...state , filteredQuestions: [...state.filteredQuestions.filter(item => item._id !== action.payload)]}
    case GET_QUESTIONS:
      return {...state , questionsList: action.payload}
    case SET_ASK_FORM:
      return {...state , askForm: action.payload}
    case GET_DRUGS:
      return {...state , lists: {...state.lists, drugs : action.payload}}
    case GET_CENTERS:
      return {...state , centersList: action.payload }
    case PUT_CONTACT:
      return {...state ,  contact: action.payload }
    case POST_CONTACT:
      return {...state , contact: action.payload , contactList: [...state.contactList, { value: action.payload._id , label: `${action.payload.firstName} ${action.payload.lastName}` }] }
    case GET_CONTACTS:
      return {...state , contactList: action.payload }
    case ANSWER_IS_LOADING:
      return {...state , answerIsLoading: action.payload}
    case ERROR_QUESTION:
        return {...state , error: action.payload}
    case MESSAGE_QUESTION:
      return {...state , message: action.payload}
    default:
      return {...state};
  }
}