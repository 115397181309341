import React, { useState , useEffect } from 'react';
import { BsFilterLeft } from "react-icons/bs";
import { RiArrowLeftSLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";

import { useGlobalContext } from '../../../context/GlobalContext';
import FilterList from './partials/FilterList';

import styles from './filters.module.scss';
import { SET_CHANGES_FILTERS } from '../../../actions/types';

export default function ChangesFilters({ isDisabled  }) {

  const [ context, dispatch ] = useGlobalContext();
  const { lists } = context.questions;

  const { filters } = context.changes;

  const [ filterIsOpen , setFiltersIsOpen ] = useState(false);
  const [ search , setSearch ] = useState();

  function setFilters(key , value ) {
    dispatch({
      type: SET_CHANGES_FILTERS,
      payload: {
        ...filters,
        page: 1,
        [key]: value
      }
    });
  }

  useEffect(() => {
    if (isDisabled) {
      setFiltersIsOpen(false);
    }
  }, [isDisabled]);


  function getClassNames() {
    let classNames = styles.filters
    if (isDisabled) {
      classNames += ` ${styles.disabled}`;
      return classNames
    }
    if (filterIsOpen) {
      classNames += ` ${styles.isOpen}`
    }
    return classNames
  }

  return (
    <div className={getClassNames()}>
      <button className={styles["btn-filters"]} onClick={() => setFiltersIsOpen(!filterIsOpen)}>
      {filterIsOpen? <RiArrowLeftSLine size={28} color={"#FFFFFF"}/>  : <BsFilterLeft size={30} color={"#FFFFFF"}/> }
      </button>
      {filterIsOpen &&
        <>
          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ""}`}>
            <input
              value={search ? search :  "" }
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher"
              />
            <button
              className={styles["btn-search"]}
              onClick={() => setFilters('search' , search ? search : null )}>
              <BiSearch size={18} color={"#FFFFFF"}/>
            </button>
          </div>
          <div className={`${styles.filter} ${filterIsOpen ? `${styles.filter} ${styles.isOpen} ${styles.border}` : ""}`}>
            {lists && <FilterList
              name={"Personne"}
              styles={styles}
              list={lists?.users ? lists?.users.filter((u) => u? true : false ).map((u) => ({ value: u._id , label : `${u.profile.firstName} ${u.profile.lastName}` })) : []}
              activeList={filters && filters.user ? filters.user : []}
              setActiveList={(val) => setFilters( 'user' , val )}
              isMulti={true}
              isSearch={true}
              />
            }
          </div>
        </>
      }
    </div>
  )
}
