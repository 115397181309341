import 'date-fns';
import React , { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker ,
} from '@material-ui/pickers';

import './datePicker.scss'
import { useEffect } from 'react';

export default function CustomDatePicker({
  label,
  id,
  handleChange,
  date,
  withErrors,
  error,
  placeholder = '',
}) {

  const [selectedDate, setSelectedDate] = useState(date ? date : null );

  const darkColor = '#FFA56D';

  const materialTheme = createMuiTheme({
    overrides: {
      MuiInputLabel: {
        shrink: false
      },
      MuiInputUnderline : {
        display: 'none'
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: darkColor,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: lightBlue.A200,
          // color: "white",
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: darkColor,
        },

      },
      MuiPickersModal: {
        dialogAction: {
          color: darkColor,
        },
      },
    },
  });

  const handleDateChange = (date) => {
    if (date && date !== selectedDate) {
      setSelectedDate(date);
      handleChange(date);
   }
  };

  useEffect(() => {
    if(date) {
      setSelectedDate(date);
    }
  }, [date]);

  return (
    <>
      <label>{label}</label>
      <div className="date-picker">
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id={id ? id : "date-picker-inline"}
              value={selectedDate}
              InputProps={{
                disableUnderline: true,
                // disableAnimation:true
              }}
              invalidDateMessage={"la date est invalide"}
              emptyLabel={placeholder}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </div>
      {withErrors && <p className={'error-message'}>{error && error}</p>}
    </>
  );
}