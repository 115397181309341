import React from 'react';
import { Link , useHistory } from 'react-router-dom';

//styles & images
import styles from './Ask.module.scss';
import illu from '../../assets/images/ask3.svg';

//components
import Button from '../../components/lib/Button/Button';

//Global state
import { useGlobalContext } from '../../context/GlobalContext';
import { PUT_CONTACT } from '../../actions/types';

export default function AskValidPage() {

  const history = useHistory();
  const dispatch = useGlobalContext()[1];

  function askQuestion() {
    dispatch({
      type: PUT_CONTACT,
      payload: null
    });
    dispatch({
      type: 'SET_ASK_FORM',
      payload: {}
    });
    history.push("/ask/question");
  }

  return (
    <div className={`page-container ${styles.container}`}>
      <div className="content">
        <div className={styles.row}>
          <div className={styles.col}>
            <h2>Votre question à bien été envoyée !</h2>
            <p>
              Elle sera traitée dans les meilleurs délais par notre équipe.
            </p>

            <h3 style={{ marginBottom : 20 }}>Merci pour votre confiance !</h3>
            
            <div className={`${styles['btn-container']} ${styles['start']}`}>
              <Link to={'/'}>
                <Button
                  primary
                  className={`${styles.btn}`}
                  type="button"
                  >
                  <p>Retour aux questions</p>
                </Button>
              </Link>
              <Button
                primary
                outline
                type="button"
                className={`${styles.btn}`}
                onClick={() => askQuestion()}
                >
                <p>Poser une nouvelle question</p>
              </Button>
              
            </div>

          </div>
          <div className={`${styles.col} ${styles.illu}`}>
            <img src={illu} alt="illu" />
          </div>
        </div>

        <footer>
          {/* <Link to={'/ask'}>En savoir plus sur la confidentialité et le traitement de mes données</Link> */}
        </footer>

      </div>
    </div>
  )
}
