import { postData , getData , putData , deleteData , TOOL_ID } from './index';

import {
  ERROR_AUTH,
  LOGIN,
  USER_AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PUT_USER_AUTH,
  ERROR_LOGIN,
  GET_NOTIFS,
  PUT_NOTIF,
  DELETE_NOTIF
} from "./types"

export async function loginAction(dispatch , obj){
  let url = "/auth/login";
  var login;

  await postData(LOGIN , ERROR_LOGIN, url, dispatch ,{ ...obj, tool: TOOL_ID }, false ).then((response)=>{
    login = response
  });

  localStorage.setItem("token" , login.token);

  dispatch({
    type : LOGIN ,
    payload : login.user
  });

};

export async function getUserAuthAction(dispatch) {
  let url = "/profile";
  var user;

  await getData(ERROR_AUTH, url, dispatch , true ).then((response)=>{
    user = response.user
  });

  dispatch({
    type : USER_AUTH,
    payload : user
  });

};

export async function putUserAuthAction(dispatch , data ) {
  let url = "/profile";
  var user;

  await putData(PUT_USER_AUTH, ERROR_AUTH ,  url , dispatch, data , true ).then((response)=>{
    user = response.user
  });

  dispatch({
    type : PUT_USER_AUTH,
    payload : {
      ...data,
      ...user
    }
  });
  if (!data.consentDate) {
    dispatch({
      type :  'SET_TOAST',
      payload : {
        type: "success",
        message: "Votre compte a été modifié"
      }
    });
  }

};

export async function forgotPasswordAction(dispatch , obj){
  let url = "/auth/forgot-password";
  var mailSend;

  await postData(FORGOT_PASSWORD , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    mailSend = response
  });

  dispatch({
    type : FORGOT_PASSWORD,
    payload : mailSend
  });
};

export async function resetPasswordAction(dispatch , obj){
  let url = "/auth/reset-password/" + obj.token;
  var reset;

  await postData(RESET_PASSWORD , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    reset = response
  });
  
  dispatch({
    type : RESET_PASSWORD,
    payload : reset
  });
  
};

export async function getNotifsAction(dispatch , product ) {
  let url = `/notification/${product}`;
  var notifs;

  await getData(ERROR_AUTH, url, dispatch , true ).then((response)=>{
    notifs = response.notifications
  });

  dispatch({
    type : GET_NOTIFS,
    payload : notifs
  });

};


export async function putNotifAction(dispatch , id , data ) {
  let url = `/notification/${id}`;
  var putNotif;

  await putData(PUT_NOTIF, ERROR_AUTH ,  url , dispatch, data , true ).then((response)=>{
    putNotif = response.notification
  });

  if (putNotif) {
    dispatch({
      type : PUT_NOTIF,
      payload : putNotif
    });
  }

};

export async function deleteNotifAction(dispatch , id ) {
  let url = `/notification/${id}`;
  var deleteNotif ;

  await deleteData(PUT_NOTIF ,  url, dispatch, true ).then((response)=>{
    deleteNotif = response
  });

  if(deleteNotif) {
    dispatch({
      type : DELETE_NOTIF,
      payload : id
    });
  }
}