import React , { useState } from 'react';
import { useForm } from "react-hook-form";
import { MdDelete } from 'react-icons/md';

//Components
import Button from '../../../../components/lib/Button/Button';

//Global state
import { useGlobalContext } from '../../../../context/GlobalContext';
import { putQuestionAction } from '../../../../actions/questionsActions';

//Style
import styles from './EditContactModale.module.scss';

export default function EditContactModale({ question , closeModale }) {

  const { handleSubmit } = useForm();

  const dispatch = useGlobalContext()[1];
  const [ contacts , setContacts ] = useState(question.contacts);

  function submitQuestion() {
    putQuestionAction(dispatch , question._id , { contacts : contacts });
    closeModale();
  }

  function handleChangeContact(i , key , value ) {
    let updatedContacts = [...contacts];
    updatedContacts[i][key] = value;
    setContacts(updatedContacts);
  }

  function removeContact(index) {
    let result = [...contacts.filter((c, i ) => i !== index)];
    setContacts(result);
  }

  return (
    <form onSubmit={handleSubmit(submitQuestion)} className={styles.container}>
      <h2>CONTACTS</h2>
      <div className={styles.list}>
      {contacts.map((c , i) => 
        <div key={i} className={styles.card}>
          <h4>{c.lastName ? `${c?.lastName} ${c?.firstName}` : "Nouveau contact"}</h4>
          <button
            type="button"
            onClick={() => removeContact(i)}
            >
           supprimer le contact <MdDelete size={20}/>
          </button>
          <input
            placeholder={'Nom'}
            name={'lastName'}
            onChange={(e) => handleChangeContact(i , 'lastName' , e.target.value)}
            defaultValue={c.lastName}/>
          <input
            placeholder={'Prénom'}
            name={'firstName'}
            onChange={(e) => handleChangeContact(i , 'firstName' , e.target.value)}
            defaultValue={c.firstName}/>
          <input
            placeholder={'Service'}
            name={'service'}
            onChange={(e) => handleChangeContact(i , 'service' , e.target.value)}
            defaultValue={c.service}/>
          <input
            name={'tel'}
            onChange={(e) => handleChangeContact(i , 'tel' , e.target.value)}
            placeholder={'Téléphone'}
            defaultValue={c.tel}/>
          <input
            placeholder={'Email'}
            name={'email'}
            onChange={(e) => handleChangeContact(i , 'email' , e.target.value)}
            defaultValue={c.email}/>
        </div>  
      )}
      </div>

      <Button
        type="button"
        primary
        onClick={() => setContacts([...contacts , { 
          lastName: "",
          tel: "",
          email: ""
        }])}
        >
        Ajouter un contact
      </Button>

      <Button
        type="submit"
        primary
        >
        Valider
      </Button>
    </form>
  )
}
