import React , { useState , useEffect , useRef } from 'react'
import { EditorState , ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import  styles from './HtmlEditor.module.scss';

const HtmlEditor = ({ onChange , defaultValue }) => {


  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  const [ isloading , setIsLoading ] = useState(false); 
  const editorRef = useRef();
 
  useEffect(() => {
    if(defaultValue) {
      setIsLoading(true);
      setEditorState(generateEditorStateFromValue(defaultValue))
    }
    return () => {
      setEditorState();
    }
  },[defaultValue]);


  function generateEditorStateFromValue(value) {
		const contentBlock = htmlToDraft(value)
		const contentState = ContentState.createFromBlockArray(
			contentBlock.contentBlocks
    )
    setTimeout(() => setIsLoading(false) , 10);
		return EditorState.createWithContent(contentState)
	}

  const handleEditorChange = (state) => {
    setEditorState(state);
    let currentContentAsHTML = convertToHTML(state.getCurrentContent());
    onChange(currentContentAsHTML);
  }

  return (
    <>
    {!isloading &&
      <Editor
        defaultEditorState={editorState}
        ref={editorRef}
        toolbar={{
          options: ['inline' , 'list'],
          inline: {
            options: ['bold', 'italic', 'underline', ],
            bold: { className: 'bordered-option-classname' },
            italic: { className: 'bordered-option-classname' },
            underline: { className: 'bordered-option-classname' },
          },
          list: {
            options: ['ordered'],
            ordered: { className: 'bordered-option-classname' },
          },
        }}
        editorClassName={styles.editor}
        onEditorStateChange={handleEditorChange}
        />
      }
    </>
  )
}

export default HtmlEditor;
