import React from 'react';
import { useForm } from 'react-hook-form';
import logo from './logo.svg';
import { putUserAuthAction } from '../../../actions/authActions';
import { useGlobalContext } from '../../../context/GlobalContext';
import Button from '../../lib/Button/Button';
import CheckBox from '../../lib/form/CheckBox/CheckBox';
import styles from './CnilModale.module.scss';

export default function CnilModale() {
  const dispatch = useGlobalContext()[1]
  const {
    watch,
    reset,
  } = useForm({ 
    defaultValues: {
      mailAccepted: false
    }
  })
  async function onSubmit() {
    const data = {...watch(), consentDate: new Date().toISOString()};
    putUserAuthAction(dispatch, data);
  }
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" />
      </div>
      <h4>Votre vie privée est importante pour nous</h4>
      <p>
        Les informations recueillies vous concernant font l’objet d’un traitement destiné
        à vous proposer des services développés par Octapharma et dédiés aux professionnels de santé.
        <br />
        Conformément à la réglementation sur la protection des données personnelles, vous avez un droit d’accès, de rectification,
        d’opposition, d’effacement et de limitation du traitement des données vous concernant,
        pour exercer ces droits, vous devez adresser votre demande à
        {' '}
        <a href="mailto:FR2DPO@octapharma.com">FR2DPO@octapharma.com</a>.
      </p>
      <CheckBox
        label="Si vous ne souhaitez pas recevoir de mails relatifs à la présentation de nouveaux services, nous vous remercions de bien vouloir cocher cette case." 
        defaultValue={false}
        onChange={(val) => reset({ mailAccepted: val })}
        />
      <div  className={styles.submit}>
        <Button
          primary
          onClick={() => onSubmit()}
        >
          Valider
        </Button>
      </div>
    </div>
  )
}
