import React from 'react'
import { useForm , Controller } from "react-hook-form";

//Components
import CustomSelect from '../../../../components/lib/form/Select/CustomSelect';
import Button from '../../../../components/lib/Button/Button';

//Global state
import { useGlobalContext } from '../../../../context/GlobalContext';
import { putQuestionAction } from '../../../../actions/questionsActions';

//Style
import styles from './RedirectModale.module.scss';

export default function RedirectModale({ question , closeModale }) {

  const { handleSubmit, control, errors , setValue } = useForm();

  const [ context , dispatch ] = useGlobalContext();

  const users = context.questions?.lists?.users?.map(user => ({ label: `${user.profile.firstName} ${user.profile.lastName} / ${user.division?.join(' - ')}`, value: user._id })) ; 
  const selectedUser = question.assignTo && users?.find(u => u.value === question.assignTo._id );

  function submitQuestion(value) {
    putQuestionAction(dispatch , question._id , value);
    closeModale();
  }

  return (
    <form onSubmit={handleSubmit(submitQuestion)} className={styles.container}>
      <h2>Réattribuer la question</h2>
      <Controller
        name="assignTo"
        control={control}
        defaultValue={selectedUser?.value ? selectedUser.value : null }
        render={() =>
          <CustomSelect
            label="Redirection"
            defaultValue={selectedUser ? selectedUser : null }
            options={users ? users : []}
            isSearchable
            withErrors={true}
            handleChange={(opt) => setValue( 'assignTo' , opt.value )}
            error={errors.assignTo && errors.assignTo.message}
            />
        }
      />

      <Button
        type="submit"
        primary
        >
        Valider
      </Button>
    </form>
  )
}
