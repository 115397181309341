import React from 'react';
import { IoCloseCircle } from 'react-icons/io5'
import { Controller, useFieldArray } from 'react-hook-form';
import styles from './SelectPeriod.module.scss';

export default function SelectPeriod({ control, periods }) {
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "periods"
  });
  return (
    <div className={styles.container}>
       <ul>
        {fields?.map((item, index) => {
          return (
            <li key={item.id}>
              <Controller
                render={(field) => <input type='date' {...field} defaultValue={item.startDate} />}
                name={`periods.${index}.startDate`}
                defaultValue={item.startDate}
                control={control}
              />

              <Controller
                render={(field) => <input type='date' {...field} defaultValue={item.endDate} />}
                name={`periods.${index}.endDate`}
                defaultValue={item.endDate}
                control={control}
              />
              {index !== 0 &&
              <button type="button" onClick={() => remove(index)}>
                <IoCloseCircle />
              </button>
        }
            </li>
          );
        })}
      </ul>
      <button
        type="button"
        className={styles.add}
        onClick={() => {
          if (periods[periods.length -1]) {
            append({ startDate: periods[periods.length -1].startDate, endDate: periods[periods.length -1].endDate });
          }
        }}
      >
        Ajouter une autre période
      </button>
    </div>
  )
}
