import React from 'react';
import logo from '../../../../assets/images/logo-white.svg';
import { MdClose } from 'react-icons/md';

import { useGlobalContext } from '../../../../context/GlobalContext';
import Button from '../../../../components/lib/Button/Button';

import styles from './SubmitModale.module.scss';
import { sendAnswerAction, submitAnswerAction } from '../../../../actions/answerActions';

export default function SubmitModale({ question , answer , closeModale }) {

  const dispatch = useGlobalContext()[1];

  function sendAnswer() {
    if (question.status === 'pending') {
      submitAnswerAction(dispatch, answer._id);
    }
    const isValid = answer.validators.map((v) => v.isValid).every(Boolean);

    if (isValid && question.status === "submitted") {
      sendAnswerAction(dispatch, answer._id);
    }
    closeModale();
  };

  return (
    <div className={styles.container}>
      <header>
        <div className={styles.logo}>
          <img src={logo} alt='logo' />
          <h1>MEDOCTA</h1>
        </div>
        <button
          className={styles.close}
          onClick={() => closeModale()}
          >
          <MdClose size={28}/>
        </button>
      </header>

      {question &&
        <div className={styles.content}>
          <div className={styles.info}>
            {question?.author?.code && <p><span className="text-grey">Code </span>{question?.author?.code}</p>}
            {question?.author?.qualification && <p><span className="text-grey">Qualification </span>{question?.author?.qualification}</p>}
            {question?.author?.hospital && <p><span className="text-grey">Établissement </span>{question?.author?.hospital}</p>}
          </div>
          <div className={styles.text}>
            <p className="text-primary bold">Rappel de votre question</p>
            <h4 className="bold">{question.title}</h4>
            <p>{question.question}</p>
            <div className={styles.list}>
              {question?.tags && question.tags.map((tag , i ) => <p key={'tag' + i } className={styles.tag}>{tag}</p>)}
            </div>
          </div>
          <div className={styles.text}>
            <p className="text-primary bold">Réponse</p>
            {question?.assignTo && <p><span className="bold">Réponse de </span>{question.assignTo?.profile?.firstName} {question.assignTo?.profile?.lastName}</p>}
            {answer?.answer &&
              <div dangerouslySetInnerHTML={{__html: answer?.answer}}></div>
            }
            <div className={styles.list}>
              {answer?.files && answer.files.map((file , i ) => <p key={'file' + i } className={styles.tag}>{file.name}</p>)}
            </div>
          </div>
          <div className={styles['btn-container']}>
            <Button
              className={styles.success}
              onClick={() => sendAnswer()}
              primary
              >
              Valider le mail
            </Button>
            <button
              className={styles.back}
              onClick={() => closeModale()}
              >
              Retour
            </button>
          </div>
        </div>
      }
    </div>
  )
}
