import { saveAs } from 'file-saver';
import { postData , getData , putData , getFile , deleteData } from './index';

import {
  ERROR_ANSWER,
  POST_ANSWER,
  GET_ANSWERS,
  GET_ANSWERS_TYPE,
  GET_ANSWER,
  PUT_ANSWER,
  POST_FILE,
  DELETE_FILE,
  ERROR_UPLOAD,
  EXPORT_ANSWERS,
  DELETE_ANSWER,
  FILTERED_ANSWERS,
  ANSWER_SATISFACTION,
  ANSWER_IS_LOADING
} from "./types"


export async function getAnswersTypeListAction(dispatch) {
  let url = "/answer/type";
  var answers;

  await getData(ERROR_ANSWER, url, dispatch , true ).then((response)=>{
    answers = response.answers
  });

  dispatch({
    type : GET_ANSWERS_TYPE,
    payload : answers,
  });

};

export async function getAnswersListAction(dispatch) {
  let url = "/answer";
  var answers;

  await getData(ERROR_ANSWER, url, dispatch , true ).then((response)=>{
    answers = response.answers
  });

  dispatch({
    type : GET_ANSWERS,
    payload : answers
  });

};

export async function postAnswersFilteredAction(dispatch , obj){
  let url = "/answer/filtered";
  var filtredAnswer;
  let data = {...obj}
  console.log(obj)

  if (obj?.month && obj?.month !== 'all') {
    const startDate = new Date(obj.month);
    let endDate = new Date(new Date(startDate).setMonth(startDate.getMonth() + 1));
    data.startDate = startDate.toISOString();
    data.endDate = endDate.toISOString();
  } else {
    data.startDate =  null;
    data.endDate = null;
  }

  delete data.month;

  await postData(FILTERED_ANSWERS , ERROR_ANSWER, url, dispatch , data, true ).then((response)=>{
    filtredAnswer = response
  });
  
  dispatch({
    type : FILTERED_ANSWERS ,
    payload : filtredAnswer
  });
  
};

export async function getAnswerAction(dispatch , id ) {
  let url = `/answer/${id}`;
  var answer;

  await getData(ERROR_ANSWER, url, dispatch , true ).then((response)=>{
    answer = response.answer
  });

  dispatch({
    type : GET_ANSWER,
    payload : answer
  });

};

export async function postAnswerAction(dispatch , obj){
  let url = "/answer";
  var postAnswer;

  await postData(POST_ANSWER , ERROR_ANSWER, url, dispatch , obj, true ).then((response)=>{
    postAnswer = response
  });
  
  dispatch({
    type : POST_ANSWER ,
    payload : postAnswer
  });

  dispatch({
    type :  'SET_TOAST',
    payload : {
      type: "success",
      message: "La réponse a été enregistrée"
    }
  });
  
};

export async function putAnswerAction(dispatch , id , data ) {
  let url = `/answer/${id}`;
  var putAnswer ;

  await putData(PUT_ANSWER,  ERROR_ANSWER ,  url , dispatch, data , true ).then((response)=>{
    putAnswer = response.answer
  });

  dispatch({
    type :  'SET_TOAST',
    payload : {
      type: "success",
      message: "La réponse a été modifiée"
    }
  });

  dispatch({
    type : PUT_ANSWER,
    payload : putAnswer
  });

};

export async function postFileAction(dispatch , formData ){
  let url = "/file",
      file;

  await postData(POST_FILE , ERROR_UPLOAD , url, dispatch , formData , true ).then((response)=>{
    file = response
  });

  dispatch({
    type : POST_FILE ,
    payload : file.files[0]
  });

};

export async function getFileAction(dispatch , id  , downloadName = false ) {
  let url = `/file/download/${id}`;

  await getFile(ERROR_UPLOAD , url, dispatch , true  ).then((response)=>{
    if (!downloadName) {
      if (window?.navigator?.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response);  
      }
      else {
        var fileURL = URL.createObjectURL(response);
        window.open(fileURL);
      }
    } else {
      let blob = new Blob([response.data], {type: 'application/octet-stream'})
      saveAs(blob, downloadName  )
    }
  });

};

export async function deleteFileAction(dispatch , id ){
  let url = "/file/" + id,
      file;

  await deleteData(ERROR_UPLOAD, url, dispatch  ).then((response)=>{
    file = response
  });

  dispatch({
    type : DELETE_FILE ,
    payload : {
      file: null,
      message: `${file.message} ${id}`
    }
  });

};

export async function exportAnswersAction(dispatch , obj ){
  let url = "/answer/export",
      exportData;

  await postData(EXPORT_ANSWERS , ERROR_ANSWER, url, dispatch , obj, true ).then((response)=>{
    exportData = response
  });
  
  dispatch({
    type : EXPORT_ANSWERS ,
    payload : exportData
  });

};


export async function submitAnswerAction(dispatch , id ) {
  let url = `/answer/${id}/submit`;
  var answerSend;
  dispatch({
    type: ANSWER_IS_LOADING,
    payload: true,
  });

  await getData(ERROR_ANSWER, url, dispatch , true ).then((response)=>{
    answerSend = response
  });


  if (answerSend) {
    dispatch({
      type: ANSWER_IS_LOADING,
      payload: false,
    });  
    dispatch({
      type :  'SET_TOAST',
      payload : {
        type: "success",
        message: "La réponse a été envoyée"
      }
    });
  }
};

export async function sendAnswerAction(dispatch , id ) {
  let url = `/answer/${id}/send`;
  var answerSend;
  dispatch({
    type: ANSWER_IS_LOADING,
    payload: true,
  });

  await getData(ERROR_ANSWER, url, dispatch , true ).then((response)=>{
    answerSend = response
  });


  if (answerSend) {
    dispatch({
      type: ANSWER_IS_LOADING,
      payload: false,
    });  
    dispatch({
      type :  'SET_TOAST',
      payload : {
        type: "success",
        message: "La réponse a été envoyée"
      }
    });
  }
};

export async function deleteAnswerAction(dispatch , id ) {
  let url = `/answer/${id}`;
  var deleteAnswer ;

  await deleteData(ERROR_ANSWER ,  url, dispatch, true ).then((response)=>{
    deleteAnswer = response
  });

  if(deleteAnswer) {
    dispatch({
      type : DELETE_ANSWER,
      payload : id
    });
  }

  dispatch({
    type :  'SET_TOAST',
    payload : {
      type: "success",
      message: "La réponse a été supprimée"
    }
  });

};

export async function postSatisfactionAction(dispatch , obj ){
  let url = "/answer/satisfaction",
      res;

  await postData(ANSWER_SATISFACTION, ERROR_ANSWER, url, dispatch , obj, false ).then((response)=>{
    res = response
  });
  
  if (res) {
    dispatch({
      type :  'SET_TOAST',
      payload : {
        type: "success",
        message: "La réponse a été envoyée"
      }
    });
  }

};
