import React , { useState , useRef } from 'react';
import { Link } from 'react-router-dom';
import { BiMinus , BiPlus } from 'react-icons/bi';

export default function AnswerTypeRow({ answer , styles }) {

  const [ answerIsOpen , setAnswerIsOpen ] = useState(false);
  const contentRef = useRef(null);

  return (
    <div className="box">
      <div className={`${styles.row}`}>
        <div className={`${styles.col} ${styles.title}`}>
          <Link to={`/answers-types/${answer._id}`} className="bold">
            <p>{answer?.titleAnswerType || "Réponse sans titre"}</p>
          </Link>
        </div>
        <div className={`${styles.col}`}>
        </div>
        <div className={`${styles.toggle}`}>  
         <button
          onClick={() => setAnswerIsOpen(!answerIsOpen)}
          >
          {answerIsOpen ? <BiMinus size={32} /> :<BiPlus size={32} /> }
         </button>
        </div>
      </div>
      <div className={styles.panel} style={{ height: answerIsOpen && contentRef.current ? contentRef.current.clientHeight : 0 }}>
        <div ref={contentRef} className={styles.answer}>

          <div className={styles.col}>
            <div className={styles.text}>
              <p><span className="bold">Réponse</span> <span>{answer?.question?.assignTo?.profile?.firstName} {answer?.question?.assignTo?.profile?.lastName}</span></p>
              {answer?.answer &&
                <div dangerouslySetInnerHTML={{__html: answer?.answer}}></div>
              }
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
