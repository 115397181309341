import { format } from 'date-fns';
import * as _ from 'lodash';
import {
  MESSAGE_CHANGE,
  ERROR_CHANGE,
  FILTERED_CHANGES,
  SET_CHANGES_FILTERS,
  SET_CHANGES_SCROLL_Y,
} from "../actions/types";

const date = new Date();
var endDate = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
var startDate = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');

const initialState = {
  error: null,
  message: null,
  changesList: [],
  filters: {
    search:"",
    startDate: startDate,
    endDate: endDate,
    user: [],
    page:1,
    orderBy: {
      key: "date",
      order: "desc"
    },
    number: 20
  },
  scrollY: 0,
  count: 0,
};

export default function changesReducer(state = initialState, action) {
  switch (action.type) {

    case SET_CHANGES_SCROLL_Y: 
      return {...state , scrollY: action.payload}

    case SET_CHANGES_FILTERS: 
      return {...state , filters: action.payload}
    
    case FILTERED_CHANGES:
      let changes = [];
      if (state.filters.page ===  1) {
        changes = action.payload.changes
      } else {
        changes = [...state.changesList , ...action.payload.changes ]
      }
      return {...state , changesList: _.uniqBy(changes , '_id'), count: action.payload.count }
    case MESSAGE_CHANGE:
      return {...state , error: action.payload}
    case ERROR_CHANGE:
        return {...state , error: action.payload}
    default:
      return {...state};
  }
}