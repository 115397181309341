import React , { useEffect , useCallback , useState } from 'react';
import { Link } from 'react-router-dom';
import { RiQuestionnaireFill} from "react-icons/ri";
import { FaDownload } from "react-icons/fa";

//Style
import styles from './AnswersTypeList.module.scss';

//golbal context
import { useGlobalContext } from '../../../context/GlobalContext';
import { getAnswersTypeListAction } from '../../../actions/answerActions';

//Components
import SortButton from '../../../components/lib/SortButton/SortButton';
import ExportAnswersModale from './ExportAnswersModale/ExportAnswersModale';
import Modale from '../../../components/lib/Modale/Modale';
import AnswerTypeRow from '../../../components/partials/AnswerTypeRow/AnswerTypeRow';
import { GET_ANSWER } from '../../../actions/types';

export default function AnswersTypeListPage() {

  const [ context, dispatch ] = useGlobalContext();

  const { answersType , search } = context.answers

  const initSortState = {
    content: true,
  }
  const [ sortByReverse , setSortByReverse ] = useState(initSortState);
  const [ filtredList , setFiltredList ] = useState();
  const [ modaleIsOpen , setModaleIsOpen ] = useState();

  const initData = useCallback(() => {
    getAnswersTypeListAction(dispatch);
    dispatch({
      type: GET_ANSWER,
      payload: null
    });
  }, [dispatch ]);

  useEffect(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    if (answersType) {
      const list = answersType.filter(
        (a) => {
          if (search && !a?.titleAnswerType?.toLowerCase()?.includes(search?.toLowerCase())) {
            return null;
          }
          return a
        }
      );
      setFiltredList(list)
    }
  },[answersType, search ]);

  function sortList(type , isReverse ) {
    let list = filtredList;
    switch (type) {
      case 'content':
        list = list.sort((a , b ) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0
        });
        setFiltredList(isReverse ? list.reverse() : list )
        setSortByReverse({...initSortState , content: isReverse })
        break;

      default:
        break;
    }

  }

  function closeModale() {
    setModaleIsOpen()
    dispatch({
      type : 'EXPORT_ANSWERS' ,
      payload : null
    });
  }

  return (
    <>
      <div className={`page-container ${styles.container}`}>
        <div className="content">
          <header>
            <h1>Réponses types</h1>
            <div className={styles.buttons}>
              <button
                onClick={() => setModaleIsOpen("export")} 
                className={styles['btn-create']}>
                <p>Exporter</p> <FaDownload size={18} />
              </button>
              <Link to="/answers-types/create" className={styles['btn-create']}>
                <p>Créer une réponse type</p> <RiQuestionnaireFill size={24} />
              </Link>
            </div>
          </header>
          <div className={styles.row}>
            <SortButton name={"Contenu de la réponse"} action={() => sortList('content' , !sortByReverse.content )} state={sortByReverse.content} className={`${styles.col} ${styles.content}`}/>
          </div> 
        </div>
        <div className={styles.list}>
          <div className="content">
            {filtredList && filtredList.map((answer) => <AnswerTypeRow key={answer._id} answer={answer} styles={styles} />)}
          </div>
        </div>
      </div>
      <Modale
        isOpen={modaleIsOpen}
        modaleToggle={() => closeModale()}
        >
        {modaleIsOpen === "export" && <ExportAnswersModale closeModale={() => closeModale()}/> }
      </Modale>
    </>
  )
}
