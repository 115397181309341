import React from 'react';
import { RiLoader4Fill } from 'react-icons/ri';

import styles from './Loader.module.scss';

export default function Loader() {
  return (
    <div className={styles.container}>
      <div className={styles.loader}>
        <RiLoader4Fill size={50} />
      </div>
    </div>
  )
}