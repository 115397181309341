import React , {useState , useEffect , useCallback } from 'react';
import { NavLink , useLocation } from 'react-router-dom';
import { FiLogOut , FiUser } from "react-icons/fi";
import { BsBellFill , BsBell } from "react-icons/bs";
import logo from '../../../assets/images/logo.svg';

import styles from './Nav.module.scss';
import { useGlobalContext } from '../../../context/GlobalContext';
import { getNotifsAction } from '../../../actions/authActions';
import { TOOL_ID } from '../../../actions';
import Notifs from '../Notifs/Notifs';
import { FaList } from 'react-icons/fa';

export default function Nav({ logout }) {

  const [ context , dispatch ] = useGlobalContext();

  const [ isHidden , setIsHidden ] = useState(true);
  const [ notifIsOpen , setNotifsIsOpen ] = useState(false)
  const [ logoIsHidden , setLogoIsHidden ] = useState(true);

  const getNotifs = useCallback(() => { getNotifsAction(dispatch , 'Medocta') },[dispatch]);

  const location = useLocation();

  const role = context.auth.userAuth?.tools.find((t) => t.tool === TOOL_ID ).role

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/data-policy" || location.pathname === "/transparency" || location.pathname === "/" || location.pathname.split("/")[1] === 'ask' || location.pathname.split("/")[1] === 'satisfaction') {
      setIsHidden(true);
      setNotifsIsOpen(false)
      if (location.pathname.split("/")[1] === 'ask' || location.pathname.split("/")[1] === 'satisfaction') {
        setLogoIsHidden(false);
      } else {
        setLogoIsHidden(true);
      }
    } else {
      setIsHidden(false);
      setLogoIsHidden(true);
    }
  }, [location]);

  useEffect(() => {
    if(notifIsOpen) getNotifs();
  },[notifIsOpen , getNotifs ]);


  return (
    <>
      <nav className={`${styles.nav} ${isHidden ? "" : styles['nav-is-active']}`}>
        <div className={styles.content}>

          <NavLink to="/">
            <img src={logo} alt="medocta" />
          </NavLink>

          <div className={styles.links}>
            {role === "Admin" && 
              <>
                <NavLink to="/dashboard" activeClassName={styles['is-active']} >ACCUEIL</NavLink>
                <NavLink to="/question" activeClassName={styles['is-active']} >QUESTIONS/RÉPONSES</NavLink>
                {/* <NavLink to="/answers" activeClassName={styles['is-active']}>RÉPONSES</NavLink> */}
                <NavLink to="/validations" activeClassName={styles['is-active']}>VALIDATIONS</NavLink>
                <NavLink to="/answers-types" activeClassName={styles['is-active']}>RÉPONSES TYPES</NavLink>
              </>
            }
            {role === "Owner" && 
              <>
                <NavLink to="/question-assign" activeClassName={styles['is-active']} >MES QUESTIONS</NavLink>
                <NavLink to="/question" activeClassName={styles['is-active']} >QUESTIONS</NavLink>
              </>
            }
            {role === "Member" && 
              <>
                <NavLink to="/question-member" activeClassName={styles['is-active']} >MES QUESTIONS</NavLink>
              </>
            }
          </div>
          
          <div className={styles.icons}>

            {role === "Admin" && 
              <NavLink className={styles.changes} to="/changes">
                <FaList size={22} color={"#192243"}/>
              </NavLink>
            }

            <button onClick={() => setNotifsIsOpen(!notifIsOpen)}>
              {context.auth?.notifs?.find( n => n.new ) ? 
                <>
                  <BsBellFill size={22} color={"#FFA56D"}/>
                  <div className={styles.notifs}>
                    <p>{context.auth?.notifs?.filter( n => n.new ).length}</p>
                  </div>
                </>
                :
                <BsBell size={22} color={"#192243"}/>
              }
            </button>

            <NavLink to="/account">
              <FiUser size={22} color={"#192243"}/>
            </NavLink>

            <button className={styles.logout} onClick={() => logout()}>
              <FiLogOut size={22} color={"#192243"}/>
            </button>

          </div>

        </div>
      </nav>
      <Notifs
        isOpen={notifIsOpen}
        notifs={context.auth?.notifs}
        />
      <div className={`${styles.logo} ${logoIsHidden ? "" : styles['logo-is-active']}`}>
        <div className={styles.content}>
          <NavLink to="/">
            <img src={logo} alt="medocta" />
          </NavLink>
        </div>
      </div>
    </>

  )
}
