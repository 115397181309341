import React, { useState , useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { primaryStyle } from './styles';

export default function CustomCreatable({ defaultValue , options,  handleChange , isMulti , placeholder , formatCreateLabel }) {

  const [ selected , setSelected ] = useState(defaultValue);

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue])

  function onChange(val , action ) {
    setSelected(val);
    handleChange(val , action );
  }

  return (
    <>
      <CreatableSelect
        isClearable
        isMulti={isMulti ? true : false }
        styles={primaryStyle}
        options={options}
        value={selected}
        formatCreateLabel={(val) => formatCreateLabel(val)}
        placeholder={placeholder ? placeholder : "Séléctionner"}
        onChange={onChange}
        />
    </>
  )
}
