import { postData } from ".";
import { ERROR_CHANGE, FILTERED_CHANGES } from "./types";

export async function postChangesFilteredAction(dispatch , obj){
  let url = "/answer/change";
  var filtredAnswer;
  let data = {...obj}

  data.startDate = obj.startDate ? new Date(obj.startDate).toISOString() : null;
  data.endDate = obj.endDate ? new Date(obj.endDate).toISOString() : null;

  await postData(FILTERED_CHANGES, ERROR_CHANGE, url, dispatch , data, true ).then((response)=>{
    filtredAnswer = {
      changes: response.changes,
      count: response?.count,
    }
  });
  
  dispatch({
    type : FILTERED_CHANGES,
    payload : filtredAnswer
  });
  
};