import React from 'react';
import { scaleLinear, extent } from 'd3';
import styles from './Bars.module.scss';

export default function Bars({ data , index , color = '#192243' }) {
  const bars = [...data].sort((a,b) => a.value > b.value ? -1 : 1);
  const barWidth = scaleLinear().domain(extent(data?.map((d) => d.value)))
  .range([1, 100])
  const barOpacity = scaleLinear().domain(extent(data?.map((d) => d.value)))
  .range([0.2, 1])
  return (
    <div className={styles.container}>
      {bars.map((b) => <div key={b.name + index} className={styles.row}>
        <div className={styles.label}>
          <p>{b.name}</p>
        </div>
        <div className={styles['bar-container']}>
          <div
            className={styles.bar}
            style={{ 
              backgroundColor: color,
              height: 30,
              opacity: barOpacity(b.value),
              width:  `${barWidth(b.value)}%`,
             }}
          >

          </div>
          <p>{b.value}</p>
        </div>
      </div>)}
    </div>
  )
}
