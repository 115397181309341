import React , { useState , useEffect } from 'react';
import style from './CheckBox.module.scss';

export default function CheckBox({ label , name , defaultValue , isDisabled , onChange }) {
  const [checked , setChecked ] = useState(defaultValue);

  useEffect(() => {
    setChecked(defaultValue)
  }, [defaultValue])

  function HandleChange(val) {
    setChecked(val);
    onChange(val)
  }
  
  return (
    <label htmlFor={name} className={`${style.container} ${isDisabled ? style.disabled : ""}`}><p>{label}</p>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        disabled={isDisabled ? true : false}
        onChange={(e) => HandleChange(e.target.checked)}/>
      <span className={style.checkmark}></span>
    </label>
  )
}
