import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../components/lib/form/Select/CustomSelect';

import styles from './Resolution.module.scss';

export default function Resolution({ resolution }) {
  const [delay , setDelay] = useState(null);
  const [delayOptions, setDelayOptions] = useState([]);
  const filteredData = resolution.filter((r) => {
    if (delay) {
      if (r.delay >= delay.min) {
        if (delay.max && r.delay > delay.max) {
          return null
        } else {
          return r;
        }
      } else {
        return null;
      }
    }
    return r;
  });

  const resolvedRate = calcRate(filteredData.filter((r) => r.status === 'resolved'))
  const pendingRate = calcRate(filteredData.filter((r) => r.status === 'pending' || r.status === 'submitted'));
  const newsRate = calcRate(filteredData.filter((r) => r.status === 'new'))
  function calcRate(arr) {
    let val = getRateValue(arr);

    let news = getRateValue(filteredData.filter((r) => r.status === 'new'));
    let pending = getRateValue(filteredData.filter((r) => r.status === 'pending' || r.status === 'submitted'));
    let resolve = getRateValue(filteredData.filter((r) => r.status === 'resolved'));

    return (val * 100 / (news + pending + resolve )).toFixed(2);
  }

  function getRateValue(arr) {
    return arr.length;
  }

  useEffect(() => {
    if (resolution) {
      let delays = [
        { value: null, label: 'Tous les délais' },
        { value: { min: 0, max: 7 }, label: `de 0 à 7 jours` },
        { value: { min: 7, max: 30 }, label: `de 7 à 30 jours` },
        { value: { min: 30, max: 60 }, label: `de 30 à 60 jours` },
        { value: { min: 60, max: null }, label: `Supérieur à 60 jours` }
      ];
      setDelayOptions(delays);
    }
  }, [resolution])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.count}> 
            <h2>{filteredData?.length}</h2>
            <p>{' '} questions reçues</p>
          </div>
          <div className={styles.select}>
            <CustomSelect
              secondary
              handleChange={(opt) => setDelay(opt.value)}
              options={delayOptions}
              placeholder='Choisir un délais'
              />
          </div>
        </div>
        {filteredData?.length > 0 &&
          <div className={styles.row}>
            {resolvedRate > 0 &&
              <div
                className={styles.bar}
                style={{ width: resolvedRate + '%'}}>
                <div className={`${styles.rate} ${styles.resolve}`} >
                  {resolvedRate > 15 && <span>{filteredData.filter((r) => r.status === 'resolved')?.length}</span>}
                </div>
                {resolvedRate > 15 && <p>{resolvedRate}%</p>}
              </div>
            }
            {pendingRate > 0 &&
              <div
                className={styles.bar}
                style={{ width: pendingRate + '%'}}>
                <div className={`${styles.rate} ${styles.pending}`} >
                  {pendingRate > 15 && <span>{filteredData.filter((r) => r.status === 'pending' || r.status === 'submitted')?.length}</span>}
                </div>
                {pendingRate > 15 && <p>{pendingRate}%</p>}
              </div>
            }
            {newsRate > 0 &&
              <div
                className={styles.bar}
                style={{ width:  newsRate + '%'}}>
                <div className={`${styles.rate} ${styles.new}`} >
                  {newsRate > 15 && <span>{filteredData.filter((r) => r.status === 'new')?.length}</span>}
                </div>
                {newsRate > 15 && <p>{newsRate}%</p>}
              </div>
            }
          </div>
        }
        {filteredData?.length > 0 &&
          <div className={styles.labels}>
            <div className={styles.label}>
              <div className={`${styles.puce} ${styles.resolve}`}>
              </div>
              <p>Résolues <br/><span>{calcRate(filteredData.filter((r) => r.status === 'resolved'))}%</span></p>
            </div>
            <div className={styles.label}>
              <div className={`${styles.puce} ${styles.pending}`}>
              </div>
              <p>En cours <br/><span>{calcRate(filteredData.filter((r) => r.status === 'pending' || r.status === 'submitted'))}%</span></p>
            </div>
            <div className={styles.label}>
              <div className={`${styles.puce} ${styles.new}`}>
              </div>
              <p>Pas traitées <br/><span>{calcRate(filteredData.filter((r) => r.status === 'new'))}%</span></p>
            </div>
          </div>
        }
      </div>
    </>
  )
}
