import React , { useState , useCallback , useEffect  } from 'react';
import { MdClose } from 'react-icons/md'

//Components
import Button from '../../lib/Button/Button';
import Modale from '../../lib/Modale/Modale';
import Creatable from '../../lib/form/Select/Creatable';

// context & actions
import { useGlobalContext } from '../../../context/GlobalContext';
import { getQuestionListsAction } from '../../../actions/questionsActions';
//style
import styles from './TagsSelect.module.scss';

export default function TagsSelect({ onChange , name , label , defaultValue , withErrors , isReadOnly , error  }) {
  
  const [ context , dispatch ] = useGlobalContext();

  const [ selectedList , setSelectedList ] = useState(defaultValue ? defaultValue : []);
  const [ modaleIsOpen , setModaleIsOpen ] = useState(false);

  const getLists = useCallback(() => { getQuestionListsAction(dispatch) },[dispatch]);

  useEffect(() => {
    getLists();
  },[getLists])

  const tagsOptions = context.questions?.lists?.tags?.filter(t => t ? true : false ).map(opt => ({ value: opt , label: opt })) 

  function handleChange(list) {
    setSelectedList(list);
    onChange(list.map(opt => opt.value));
  }

  return (
    <>
      <div className={styles.container}>
        <label htmlFor={name}>{label}</label>
        <div className={styles['add-tag']}>
          {!isReadOnly &&
            <Button
              primary
              onClick={() => setModaleIsOpen(true)}
              className={styles.btn}
              >
              Ajouter un tag
            </Button>
          }
        </div>

        <div className={styles.list}>
          {selectedList && selectedList.map((tag, i) =>
            <div
              key={`tag ${i}`}
              className={`${styles.tag}`}
              >
              <p>{tag.value}</p>
              {!isReadOnly &&
                <button
                  type="button"
                  onClick={() => handleChange(selectedList.filter(item => item.value !== tag.value))}
                  className={styles.close}
                  >
                  <MdClose size={18}/>
                </button>
              }
            </div>
          )}
        </div>
        {withErrors && <p className={styles['error-message']}>{error && error}</p>}
      </div>
      <Modale
        isOpen={modaleIsOpen}
        modaleToggle={() => setModaleIsOpen(false)}
        >
        <div className={styles.modale}>
          <Creatable
            isMulti
            defaultValue={selectedList}
            formatCreateLabel={(val) => `Créer le tag "${val}"`}
            handleChange={(val) => handleChange(val)}
            options={tagsOptions ? tagsOptions  : []}
            />
          <Button
            primary
            onClick={() => setModaleIsOpen(false)}
            className={styles.btn}
            >
            Valider
          </Button>
        </div>
      </Modale>
    </>
  )
}
