import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { FaCheckCircle } from 'react-icons/fa';
import { MdWarning } from 'react-icons/md';

import Button from '../../lib/Button/Button';

import styles from './ValidRow.module.scss';
import { format } from 'date-fns';
import Comment from '../Comment/Comment';

export default function ValidRow({ answer , adminId , handleChangeAnswer }) {
  const validator = answer?.validators.find(admin => admin.user === adminId) || answer?.validators.find(admin => admin.user === "5eb180d43b2732236283de0b" && adminId === "61eed6cfa3bedd879bf8c66e");
  const [comment, setComment] = useState(null);
  
  function validAnswer() {
    let validators = [...answer.validators];
    validators.forEach(val => {
      if (val.user === adminId) {
        val.isValid = !val.isValid
      }
      if (val.user === "5eb180d43b2732236283de0b" && adminId === "61eed6cfa3bedd879bf8c66e") {
        val.isValid = !val.isValid
      }
    });
    handleChangeAnswer( answer._id , "validators" , validators );
  }

  function validComment(text = null) {
    let validators = [...answer.validators];
    if(!text) {
      setComment(null);   
    }
    validators.forEach(val => {
      if (val.user === adminId) {
        val.comment = text;
      }
    });
    handleChangeAnswer( answer._id , "validators" , validators );
  }

  useEffect(() => {
    setComment(validator?.comment || null);
  }, [validator])

  return (
    <div className={`${styles.container} box`}>

      <div className={styles.row}>

        <div className={styles.col}>
          <div className={styles.title}>
            <p className={styles.code}>{answer.question.code}</p>
            {answer?.question?.title &&
              <Link to={`/question/${answer.question._id}`} className="bold">
                <p>{answer.question.title}</p>
              </Link>
            }
          </div>
        </div>

        <div className={styles.col}>
          <div className={`${styles.border} ${styles.title}`}>
            <p>{answer.mode.find((m) => m === "written") && "Ecrit"} {answer.mode.length > 1 && " / "} {answer.mode.find((m) => m === "oral") && "Oral"}</p>
          </div>
        </div>

        <div className={`${styles['col-right']} ${styles.delay}`}>
          <p>{answer.question.date && format(new Date(answer.question.date), 'dd/MM/yyyy')}</p>
          <div
            className={`${styles['icons']}`}
            >
              {answer?.validators.every(admin => admin.isValid) && answer?.validators?.map(admin => <FaCheckCircle key={admin._id} size={24} color={admin.isValid ? '#4DCD98':'#D8D8D8'} /> )}
            </div>
          {answer?.delay >= 7 && <p className="text-error">{answer.delay} jours <MdWarning size={18} /></p>}
          {answer?.delay < 7 && <p className="text-success">{answer.delay} jours </p>}
        </div>

      </div>

      {!answer?.validators.every(admin => admin.isValid) &&

        <div className={styles.row}>

          <div className={styles.col}>
            <div className={styles.text}>
              <p><span className="bold">Question</span> <span>par {answer.question?.lastName && <p>{answer.question?.firstName[0]?.toUpperCase()} {answer.question?.firstName && '.'} {answer.question?.lastName?.toUpperCase()}</p>} </span></p>
              <p>{answer.question.question}</p>
            </div>
          </div>

          <div className={styles.col}>
            <div className={styles.text}>
              <p><span className="bold">Réponse</span> <span>par {answer.question?.assignTo?.profile?.firstName} {answer.question?.assignTo?.profile?.lastName}</span></p>
              {answer?.answer &&
                <div dangerouslySetInnerHTML={{__html: answer?.answer}}></div>
              }
            </div>
          </div>

          <div className={`${styles['col-right']} ${styles['validation-container']}`}>
            <div
              className={`${styles['valid-icons']}`}
              >
              <Comment validators={answer?.validators} />
              {answer?.validators?.map(admin => <FaCheckCircle key={admin._id} size={24} color={admin.isValid ? '#4DCD98':'#D8D8D8'} /> )}
            </div>
            {validator &&
              <>
                <Button
                  onClick={() => validAnswer(false)}
                  className={`${styles['valid-btn']} ${!validator.isValid ? "" : styles.not }`}
                  >
                  {!validator.isValid ? "Valider la réponse" : "Refuser la réponse"}
                </Button>

                <div className={styles.comment}>
                  {comment !== null ?
                    <>
                      <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Votre  commentaire"
                      />
                      <button
                        className={styles.submit}
                        onClick={() => validComment(comment)}
                      >
                        Valider
                      </button>
                      <button
                        onClick={() => validComment(null)}
                      >
                        Annuler
                      </button>
                    </>
                  : <button
                    onClick={() => setComment("")}
                    className={styles.toggle}>
                    Laisser un commentaire
                  </button>
                  }
                </div>
              </>
            }
            {/* {answer?._id &&
              <CheckBox
                label={<span>Qualifier cette réponse en<br/><span className="bold">réponse type</span></span>}
                defaultValue={answer?.isAnswerType && answer.isAnswerType}
                onChange={(val) => handleChangeAnswer( answer._id , "isAnswerType" , val)} />
            } */}
            
          </div>

        </div>

      }
      
    </div>
  )
}
