import React from 'react';
import * as d3 from 'd3';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

export default function CustomPie({ data }) {
  const RADIAN = Math.PI / 180;
  var color = d3.scaleLinear().domain(d3.extent(data?.map((d) => d.value)))
  .range(["#8893BB", "#192243"]);
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" fontSize="12px" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {percent * 100 > 15 && `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <ResponsiveContainer>
      <PieChart>
        <Tooltip /> 
        <Pie
          data={data?.sort((a,b) => b.value > a.value ? -1 : 1)}
          minAngle={0}
          innerRadius={0}
          outerRadius={90}
          fill="#8884d8"
          labelLine={false}
          paddingAngle={0}
          dataKey="value"
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={color(entry.value)} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}
