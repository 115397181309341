import React , { useEffect , useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useGlobalContext } from '../../context/GlobalContext';
import { getUserAuthAction } from '../../actions/authActions';
import { TOOL_ID } from '../../actions';

export default function SwitchPage() {

  const [ context , dispatch ] = useGlobalContext();
  const { userAuth } = context.auth;

  const history = useHistory();

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    getUser();
    return () => {
      dispatch({
        type: 'ERROR_AUTH',
        payload: null,
      });
    }
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    if (userAuth) {
      if (userAuth?.tools.find((t) => t.tool === TOOL_ID  && t.role === "Admin")) {
        history.push("/dashboard");
      } else if (userAuth?.tools.find((t) => t.tool === TOOL_ID  && t.role === "Owner")) {
        history.push("/question-assign");
      } else if (userAuth?.tools.find((t) => t.tool === TOOL_ID  && t.role === "Member")) {
        history.push("/question-member");
      } else {
        history.push("/login")
      }
    }
  },[userAuth , history]);

  return (
    <div className="page-container">
    </div>
  )
}