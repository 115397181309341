import React, { useState , useEffect } from 'react';
import { BsFilterLeft } from "react-icons/bs";
import { RiArrowLeftSLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";

import { useGlobalContext } from '../../../context/GlobalContext';

import styles from './filters.module.scss';
import { SET_ANSWERS_TYPES_SEARCH } from '../../../actions/types';

export default function Filters({ isDisabled , isAnswers }) {

  const dispatch = useGlobalContext()[1];

  const [ filterIsOpen , setFiltersIsOpen ] = useState(false);
  const [ search , setSearch ] = useState();

  function setFilters(value) {
    dispatch({
      type: SET_ANSWERS_TYPES_SEARCH,
      payload: value
    });
  }

  useEffect(() => {
    if (isDisabled) {
      setFiltersIsOpen(false);
    }
  }, [isDisabled]);

  function getClassNames() {
    let classNames = styles.filters
    if (isDisabled) {
      classNames += ` ${styles.disabled}`;
      return classNames
    }
    if (filterIsOpen) {
      classNames += ` ${styles.isOpen}`
    }
    if (isAnswers) {
      classNames += ` ${styles.answers}`
    }
    return classNames
  }

  return (
    <div className={getClassNames()}>
      <button className={styles["btn-filters"]} onClick={() => setFiltersIsOpen(!filterIsOpen)}>
      {filterIsOpen? <RiArrowLeftSLine size={28} color={"#FFFFFF"}/>  : <BsFilterLeft size={30} color={"#FFFFFF"}/> }
      </button>
      {filterIsOpen &&
        <>
          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ""}`}>
            <input
              value={search ? search :  "" }
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher"
              />
            <button
              className={styles["btn-search"]}
              onClick={() => setFilters(search ? search : '' )}>
              <BiSearch size={18} color={"#FFFFFF"}/>
            </button>
          </div>
          <div className={`${styles.filter} ${filterIsOpen ? styles.isOpen : ""}`}>
          </div>
        </>
      }
    </div>
  )
}
