import React, { useState } from 'react';
import comment from './comment.svg';
import styles from './Comment.module.scss';

export default function Comment({ validators }) {
  const [ isActive , setIsActive ] = useState(false); 
  return (
    <div className={styles.container}>
      {validators?.find((d) => d?.comment) &&
      <button
        onMouseOver={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
      >
        <img src={comment} alt="comment"/>
      </button>
      }
      <div className={`${styles.comment} ${isActive ? styles.active : ''}`}>
        {validators?.map((d) => (<p>{d?.comment}</p>))}
      </div>
    </div>
  )
}
