import React, { useState , useEffect } from 'react';
import { BsFilterLeft } from "react-icons/bs";
import { RiArrowLeftSLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";

import { useGlobalContext } from '../../../context/GlobalContext';
import FilterList from '../Filters/partials/FilterList';

import styles from './filters.module.scss';
import { SET_ANSWERS_FILTERS } from '../../../actions/types';

export default function AnswersFilters({ isDisabled  }) {

  const [ context, dispatch ] = useGlobalContext();
  const { lists } = context.questions;

  const { filters } = context.answers;

  const [ filterIsOpen , setFiltersIsOpen ] = useState(false);
  const [ search , setSearch ] = useState();
  const [ monthsOptions ,setMonthsOptions] = useState([]);

  function setFilters(key , value ) {
    dispatch({
      type: SET_ANSWERS_FILTERS,
      payload: {
        ...filters,
        page: 1,
        [key]: value
      }
    });
  }

  useEffect(() => {
    if (isDisabled) {
      setFiltersIsOpen(false);
    }
  }, [isDisabled]);

  useEffect(() => {
    if (lists?.oldest && lists?.newest) {
      let options = []
      let value = new Date(new Date(lists?.oldest.date).setDate(1));
      let lastMonth = new Date(new Date(lists?.newest.date).setDate(1));
      while (value < lastMonth) {
        value = new Date(new Date(value).setMonth(value.getMonth() + 1));
        const label = new Intl.DateTimeFormat('fr', { month: 'long', year: 'numeric'}).format(value);
        options.unshift({ label, value })
      }
      options.unshift({ value: "all", label :'Tous les mois'});
      setMonthsOptions(options);
    }
  }, [lists?.oldest , lists?.newest]);


  function getClassNames() {
    let classNames = styles.filters
    if (isDisabled) {
      classNames += ` ${styles.disabled}`;
      return classNames
    }
    if (filterIsOpen) {
      classNames += ` ${styles.isOpen}`
    }
    return classNames
  }

  return (
    <div className={getClassNames()}>
      <button className={styles["btn-filters"]} onClick={() => setFiltersIsOpen(!filterIsOpen)}>
      {filterIsOpen? <RiArrowLeftSLine size={28} color={"#FFFFFF"}/>  : <BsFilterLeft size={30} color={"#FFFFFF"}/> }
      </button>
      {filterIsOpen &&
        <>
          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ""}`}>
            <input
              value={search ? search :  "" }
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher"
              />
            <button
              className={styles["btn-search"]}
              onClick={() => setFilters('search' , search ? search : null )}>
              <BiSearch size={18} color={"#FFFFFF"}/>
            </button>
          </div>
          <div className={`${styles.filter} ${filterIsOpen ? `${styles.filter} ${styles.isOpen} ${styles.border}` : ""}`}>
            {lists && <FilterList
              name={"Tags"}
              styles={styles}
              list={lists?.tags ? lists?.tags.filter(t => t? true : false ).map(t => ({ value: t , label : t })) : []}
              activeList={filters && filters.tags ? filters.tags : []}
              setActiveList={(val) => setFilters( 'tags' , val )}
              isSearch={true}
              />
            }
          </div>
          <div className={`${styles.filter} ${filterIsOpen ? `${styles.filter} ${styles.isOpen} ${styles.border}` : ""}`}>
            {console.log(monthsOptions)}
            {lists && <FilterList
              name={"Mois"}
              styles={styles}
              list={monthsOptions ? monthsOptions : []}
              activeList={filters.month ? [filters.month] : []}
              setActiveList={(val) => setFilters( 'month' , val )}
              isMulti={false}
              isSearch={true}
              />
            }
          </div>
        </>
      }
    </div>
  )
}
