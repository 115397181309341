import React , { useEffect , useCallback , useState , useRef } from 'react';
import { Link } from 'react-router-dom';
import { RiQuestionnaireFill} from "react-icons/ri";
import { FaDownload } from "react-icons/fa";
//Style
import styles from './QuestionsList.module.scss';

//golbal context
import { useGlobalContext } from '../../../context/GlobalContext';
import { getQuestionListsAction, postQuestionsFilteredAction } from '../../../actions/questionsActions';
import { getNotifsAction } from '../../../actions/authActions';

//Components
import SortButton from '../../../components/lib/SortButton/SortButton';
import QuestionRow from '../../../components/partials/QuestionRow/QuestionRow';
import Modale from '../../../components/lib/Modale/Modale';
import Loader from '../../../components/lib/Loader/Loader';
import ExportAnswersModale from '../../AnswersType/AnswersTypeList/ExportAnswersModale/ExportAnswersModale';
import { GET_ANSWER, GET_QUESTION, SET_FILTERS, SET_SCROLL_Y } from '../../../actions/types';
import { TOOL_ID } from '../../../actions';

export default function QuestionsListPage({ isAssign , isMember }) {

  const [ context, dispatch ] = useGlobalContext();
  const { userAuth } = context.auth;

  const { filteredQuestions , questionsList , filters , count , scrollY } = context.questions
  let isAdmin = userAuth?.tools.find((t) => t.tool === TOOL_ID ).role === "Admin" ? true : false

  const initSortState = {
    title: true,
    code: true,
    author: true,
    date: true,
    attrib: true,
    status: true,
    delay: true,
    'answer.date': true,
    'assignTo.profile.firstName': true,
  }

  const containerRef = useRef(null);
  const scrollRef = useRef(null);
  const [ scrollTop , setScrollTop ] = useState(0);

  const [ sortByReverse , setSortByReverse ] = useState(initSortState);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ modaleIsOpen , setModaleIsOpen ] = useState();

  const initData = useCallback(() => {
    getNotifsAction(dispatch , "Medocta" );
    if (scrollY) {
      containerRef.current.scrollTo({
        top: scrollY,
        left: 0,
      });
    }
    getQuestionListsAction(dispatch);
    dispatch({
      type: GET_QUESTION,
      payload: null
    });
    dispatch({
      type:GET_ANSWER,
      payload: null
    });
  }, [dispatch , scrollY]);

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    postQuestionsFilteredAction(dispatch, filters );
    setIsLoading(true);
    // eslint-disable-next-line
  },[filters.orderBy , filters ]);

  useEffect(() => {
    setIsLoading(false);
  }, [filteredQuestions])

  function sortList(type , isReverse ) {
    setSortByReverse({...initSortState , [type]: isReverse });
    dispatch({
      type: SET_FILTERS,
      payload: {
        ...filters,
        page: 1,
        orderBy: {
          key: type,
          order: isReverse ? 'desc' : 'asc'
        }
      }
    });
  };

  function handleScroll(e) {
    dispatch({
      type: SET_SCROLL_Y,
      payload: e.target.scrollTop
    })
    if (!isLoading && count > filteredQuestions.length && e.target.scrollTop > scrollTop && scrollRef?.current.getBoundingClientRect().bottom <= containerRef?.current.getBoundingClientRect().bottom ) {
      setScrollTop(e.target.scrollTop);
      dispatch({
        type: SET_FILTERS,
        payload: {
          ...filters,
          page: filters.page + 1,
        }
      });
    }
  }

  function closeModale() {
    setModaleIsOpen()
    dispatch({
      type : 'EXPORT_ANSWERS' ,
      payload : null
    });
  }

  return (
    <>
      <div className={`page-container ${styles.container}`}>
        <div className="content">
          <header>
            {isAssign || isMember ?
              <h1>Mes questions en cours</h1>
              :
              <h1>{isAdmin ? "Questions" : "Toutes les questions" }</h1>
            }
            <div className={styles.buttons}>
              {isAdmin &&
                <button
                  onClick={() => setModaleIsOpen("export")} 
                  className={styles['btn-create']}>
                  <p>Exporter</p> <FaDownload size={18} />
                </button>
              }
              <Link to="/ask" className={styles['btn-create']}>
                <p>Créer une question</p> <RiQuestionnaireFill size={24} />
              </Link>
            </div>
          </header>
          <div className={styles.row}>
            <SortButton name={"Code"} action={() => sortList('code' , !sortByReverse.code )} state={sortByReverse.code} className={`${styles.col}`}/>
            <SortButton name={"Titre de la question"} action={() => sortList('title' , !sortByReverse.title )} state={sortByReverse.title} className={`${styles.col} ${styles.title}`}/>
            <SortButton name={"Auteur"} action={() => sortList('author' , !sortByReverse.author )} state={sortByReverse.author} className={`${styles.col} ${styles.author}` }/>
            <SortButton name={"Date"} action={() => sortList('date' , !sortByReverse.date )} state={sortByReverse.date}  className={`${styles.col}`}/>
            <SortButton name={"Réponse"} action={() => sortList('answer.date' , !sortByReverse['answer.date'] )} state={sortByReverse['answer.date']}  className={`${styles.col}`}/>
            {!isMember && <SortButton name={"Attrib"} action={() => sortList('assignTo.profile.firstName' , !sortByReverse['assignTo.profile.firstName'] )} state={sortByReverse['assingTo.profile.firstName']}  className={`${styles.col}`}/>}
            <SortButton name={"Statut"} action={() => sortList('status' , !sortByReverse.status )} state={sortByReverse.status}  className={`${styles.col}`} />
            <SortButton name={"Délais"} action={() => sortList('delay' , !sortByReverse.delay )} state={sortByReverse.delay}  className={`${styles.col}`} />
          </div> 
        </div>
        <div
          ref={containerRef}
          onScroll={(e) => handleScroll(e)}
          className={styles.list}>
          <div className="content" ref={scrollRef}>
              {filteredQuestions && filteredQuestions.filter(q => {
                if (isAssign && q.assignTo?._id !== context.auth.userAuth?._id ) return null
                return q
              }).map((question) => <QuestionRow key={question._id} question={question} isMember={isMember} isAssign={isAssign ? true : false } styles={styles} />)}
              {!questionsList && !filteredQuestions && <Loader />}
          </div>
        </div>
      </div>
      <Modale
        isOpen={modaleIsOpen}
        modaleToggle={() => closeModale()}
        >
        {modaleIsOpen === "export" && <ExportAnswersModale closeModale={() => closeModale()}/> }
      </Modale>
    </>
  )

}
