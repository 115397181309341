import React , { useState , useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";

//Components
import Button from '../../../../components/lib/Button/Button';

//Global state
import { useGlobalContext } from '../../../../context/GlobalContext';
import { getCentersListAction, putContactAction } from '../../../../actions/questionsActions';

//Style
import styles from './EditAuthorModale.module.scss';
import CustomSelect from '../../../../components/lib/form/Select/CustomSelect';
import Input from '../../../../components/lib/form/Input/Input';

export default function EditAuthorModale({ question , closeModale }) {
  const { author } = question;
  const { handleSubmit , register , control , setValue } = useForm({
    defaultValues: author,
  });

  const [ context, dispatch ] = useGlobalContext();
  const { centersList } = context.questions
  const [ selectedCenter, setSelectedCenter ] = useState();
  const services = selectedCenter?.value?.services.map((s) => ({ label: s.name , value: s.name }));
  const servicesOptions = services ? [{ value: '' , label: 'pas de service' }, ...services] : []

  function submitQuestion(values) {
    putContactAction(dispatch, author._id , {...author, ...values });
    closeModale();
  }

  function handleChangeAuthor(key , value ) {
    setValue(key, value);
  }

  useEffect(() => {
    getCentersListAction(dispatch)
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (selectedCenter?.value?.center) {
      handleChangeAuthor('center', selectedCenter?.value?.center);
      setValue('center' , selectedCenter.value?.center);
    }
    // eslint-disable-next-line
  }, [selectedCenter?.value?.center]);

  useEffect(() => {
    const center = centersList?.find((c) => c.value.center === author?.center?._id );
    if (center?.value) {
      setSelectedCenter(center);
    }
    // eslint-disable-next-line
  }, [author?.center , centersList]);

  return (
    <form onSubmit={handleSubmit(submitQuestion)} className={styles.container}>
        {author && centersList &&
        <>
          <div className={styles.card}>
            <h2>MODIFIER L'AUTEUR</h2>
            <div className={styles['info-row']}>
              <div className={styles['info-col']}>
                <Input
                  label={"Nom"}
                  name={"lastName"}
                  placehloder={"Nom"}
                  register={register}
                  defaultValue={author?.lastName}
                  onChange={(val) => handleChangeAuthor('lastName', val )}
                  withErrors={true}
                  error={''}
                />
              </div>
              <div className={styles['info-col']}>
                <Input
                  label={"Prénom"}
                  name={"firstName"}
                  placehloder={"Prénom"}
                  register={register}
                  defaultValue={author?.firstName}
                  onChange={(val) => handleChangeAuthor('firstName', val )}
                  withErrors={false}
                  error={''}
                />
              </div>
            </div>

            <div className={styles['info-row']}>
              <div className={styles['info-col']}>
                <Input
                  label={"Qualification"}
                  placehloder={"Médecin, pharmacien..."}
                  name={"qualification"}
                  register={register}
                  defaultValue={author?.qualification}
                  onChange={(val) => handleChangeAuthor('qualification', val )}
                  withErrors={true}
                  error={''}
                />
              </div>
            </div>

              <div className={styles['info-row']}>
                <div className={styles['info-col']}>
                  <Input
                    label={"Téléphone"}
                    name={"tel"}
                    placehloder={"00 00 00 00 00"}
                    register={register}
                    defaultValue={author?.tel}
                    onChange={(val) => handleChangeAuthor('tel', val )}
                    withErrors={true}
                    error={''}
                  />
                </div>
                <div className={styles['info-col']}>
                  <Input
                    label={"Mail*"}
                    name={"email"}
                    placehloder={"adresse@gmail.com"}
                    register={register}
                    defaultValue={author?.email}
                    onChange={(val) => handleChangeAuthor('email', val )}
                    withErrors={true}
                    error={''}
                  />
                </div>
              </div>
              <div className={styles['info-row']}>
                <div className={styles['info-col']}>
                <Controller
                  name="center"
                  control={control}
                  defaultValue={author?.center?._id}
                  render={() =>
                  <CustomSelect
                    label="Centre"
                    options={centersList ? centersList : []}
                    isSearchable
                    withErrors={true}
                    defaultValue={centersList.find((c) => c.value.center === author?.center?._id )}
                    handleChange={(val) => setSelectedCenter(val)}
                    error={''}
                  />}
                />
                </div>
                {servicesOptions && services &&
                  <div className={styles['info-col']}>
                    <Controller
                      name="service"
                      control={control}
                      defaultValue={author?.service}
                      render={() => <CustomSelect
                        label="Service"
                        defaultValue={servicesOptions.find((c) => c.value === author?.service )}
                        options={servicesOptions}
                        isSearchable
                        withErrors={true}
                        handleChange={(opt) => handleChangeAuthor('service', opt.value)}
                        error={null}
                      />}
                    />
                  </div>
                }
              </div>
          </div>
          <Button
            type="submit"
            primary
          >
            Valider
          </Button>
      </>
      }
    </form>
  )
}
